import React from 'react'
import Card from "../../Card"
import classNames from 'classnames'
import NewsroomPostMeta from "../NewsroomPostMeta"
import NewsroomTopics from "../NewsroomTopics"
import RichText from "../../RichText";

const ReportCard = (post) => {
    const {title, thumbnail, thumbnailFocus, topics, excerpt, pdfCover, slug, link, linkTopics = false} = post

    let image = pdfCover ? pdfCover : thumbnail

    return <Card image={image}
                 imageFocus={thumbnailFocus}
                 imageWidth={900}
                 imageHeight={1165}
                 hrefPlacement={"image"}
                 href={link}
                 className={classNames('post__card post__card--report bg-transparent')}
    >

        <NewsroomPostMeta {...post} />

        <a href={link} title={"View " + title} className={"post__link"}>
            <h3 className={"post-card__heading"}>
                {title}
            </h3>
        </a>


        <div>
            <RichText content={excerpt} />
        </div>


        <a href={link} title={"Read" + title} className={"button--link display-block margin-top-3 margin-bottom-2"}>
            Read full report
        </a>

        <NewsroomTopics topics={topics} link={linkTopics}/>

    </Card>
}

export default ReportCard