/**
 * Tab Component
 * -------------
 *
 * Generic tabs component.
 *
 * title: required. Used in the tab navigation.
 * ref: Required or tabs won't show up.
 *
 * <Tabs>
 *   <Tab title='tab title 1' ref={React.createRef()}>
 *       Tab content
 *   </Tab>
 *   <Tab title='tab title 2' ref={React.createRef()}>
 *       Tab 2 content
 *   </Tab>
 * </Tabs>
 */

import React from 'react';
import TabNavigation from "./TabNavigation";
import NavItem from './NavItem'

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        const {children} = props;

        //Sort the tabs by order, and then by abc
        this.tabs = children.filter(child => child.ref).sort((a, b) => {
            if(a.props.order !== b.props.order){
                if(a.props.order < b.props.order) return -1;
                if(a.props.order > b.props.order) return 1;
            }

            if(a.props.title < b.props.title) return -1;
            if(a.props.title > b.props.title) return 1;
            return 0;
        })
        this.nav = this.tabs.map(tab => React.createRef())
        this.state = {
            active: ''
        }
    }

    /**
     * Is there an open tab?
     * @returns bool
     */
    hasOpenTab() {
        return (this.findOpenTab())
    }

    /**
     * Return the open tab
     * @returns {*}
     */
    findOpenTab() {
        return this.tabs.find(tab => tab.ref.current.state.open)
    }

    /**
     * Open the first tab on load.
     */
    componentDidMount() {
        if (!this.hasOpenTab()) {
            this.openTab(this.tabs[0])
        } else {
            this.setState({active: this.findOpenTab()})
        }
    }

    /**
     * Open a tab
     * @param tabToOpen
     */
    openTab(tabToOpen) {
        this.tabs.forEach(function (tab, i) {
            if (tab === tabToOpen) {
                tab.ref.current.open()
            } else {
                tab.ref.current.close()
            }
        })
        this.setState({active: tabToOpen})
    }

    /**
     * Render the nav items
     * @returns {*}
     */
    renderNavItems() {
        return this.tabs.map(function (tab, index) {
            return (
                <NavItem key={index} onClick={() => this.openTab(tab)} ref={this.nav[index]} active={this.state.active === tab}>
                    {tab.props.title}
                </NavItem>
            )
        }.bind(this))
    }

    /**
     * Render
     * @returns {*}
     */
    render() {
        const {children} = this.props;

        return (
            <div className="tabs">
                <TabNavigation className='margin-bottom-7'>
                    {this.renderNavItems()}
                </TabNavigation>
                {children}
            </div>
        );
    }
}
