import React, {useEffect} from 'react'
import NewsroomSearch from "../../NewsroomSearch";

const InitNewsroomSearch = ({results, search, endpoint = "relative", url, order, exclude, cacheKey}) => {
    NewsroomSearch.init(
        results,
        search,
        endpoint,
        url,
        exclude,
        cacheKey
    )
    return ''
}

export default InitNewsroomSearch