import React from 'react'
import StaggerGrid from "./StaggerGrid";
import Video from './Video'
import VideoCard from "./VideoCard";

const VideoGrid = ({videos}) => {
    return (<StaggerGrid>
        {
            videos.map((video) => <div  key={'video-grid-' + video.id}>
                <VideoCard video={video} />
            </div>)
        }
    </StaggerGrid>)
}

export default VideoGrid