import React from 'react';
import Card from './Card'
import {Swiper, SwiperSlide} from 'swiper/react';
import classNames from 'classnames'

/**
 * Class representing a SwiperCards component.
 * @extends React.Component
 */
export default class SwiperCards extends React.Component {

    /**
     * The swiper variable represents a library for creating touch-enabled sliders. It is used for
     * creating and controlling swipeable content slides.
     *
     * @namespace
     */
    swiper

    /**
     * Refresh swiper after a given delay to account for dynamic content.
     *
     * @memberof Component
     * @method componentDidMount
     *
     * @return {void}
     */
    componentDidMount() {
        setTimeout(this.refresh.bind(this), 1000)
        setTimeout(this.refresh.bind(this), 2000)
        setTimeout(this.refresh.bind(this), 5000)
    }

    /**
     * Renders a swiper cards component.
     *
     * @returns {ReactElement} The rendered swiper component.
     */
    render() {
        let {
            cards,
            navigation = true,
            pagination = false,
            autoHeight = false,
            breakpoints,
            spaceBetween = 24,
            ...props
        } = this.props;

        if (breakpoints !== false && !breakpoints) {
            breakpoints = {
                659: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1561: {
                    slidesPerView: 4,
                },
            }
        }

        return <div className={classNames("swiper-cards", {
            'swiper-cards--autoheight': autoHeight,
            'swiper-cards--pagination': pagination
        })}>
            <div className={"container"}>
                <Swiper {...props}
                        autoHeight={autoHeight}
                        spaceBetween={spaceBetween}
                        slidesPerView={1.25}
                        slidesPerGroup={1}
                        loop={false}
                        watchSlidesVisibility
                        watchSlidesProgress
                        navigation={navigation}
                        pagination={pagination ? {
                            clickable: true
                        } : false}
                        observer="true"
                        observeSlideChildren={true}
                        updateOnWindowResize={true}
                        simulateTouch={true}
                        longSwipes={true}
                        onSwiper={swiper => this.swiper = swiper}
                        breakpoints={breakpoints}>
                    {this.renderSlides()}
                </Swiper>
            </div>
        </div>
    }

    /**
     * Render the slides using the provided cards.
     *
     * @returns {Array} - An array of card items.
     */
    renderSlides() {
        const {cards} = this.props;

        let cardItems = [];
        for (const [index, card] of cards.entries()) {
            if (!card) return;

            cardItems.push(
                <SwiperSlide
                    key={index}
                >
                    <Card
                        {...card}
                    />

                </SwiperSlide>
            );
        }
        return cardItems;
    }

    /**
     * Refreshes the swiper instance if it exists.
     *
     * @return {void} - Returns nothing.
     */
    refresh() {
        if (this.swiper) {
            this.swiper.update()
        }
    }

    /**
     * Refreshes the size of the swiper container if it exists.
     *
     * @returns {void}
     */
    refreshSize() {
        if (this.swiper) {
            this.swiper.updateSize()
        }
    }
}