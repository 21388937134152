import React from 'react'
import classNames from 'classnames'
import {useSearch, useSubmissionParam} from '../../newsroomHooks'

const NewsroomFilterTags = ({className, params = ['type', 'country', 'topic']}) => {
    const [type, setType] = useSubmissionParam('type')
    const [country, setCountry] = useSubmissionParam('country')
    const [topic, setTopic] = useSubmissionParam('topic')
    const [, setPage] = useSubmissionParam('page')
    const {lastSubmission} = useSearch()

    const {fetch} = useSearch()

    const removeFilter = (setFilter, label, value) => {
        const withoutValue = value.filter(item => item !== label)
        setPage(0, false)
        setFilter(withoutValue)
        fetch({
            scrollIntoView: true
        })
    }

    return (
        <div className={classNames("newsroom__filter-tags", className)}>
            { lastSubmission.type ? lastSubmission.type.map(label => <div className={"pill"} key={`type-tag-${label}`} onClick={() => removeFilter(setType, label, type)}>
                    {label} <span>&nbsp;X</span>
                </div>) : ''
            }
            { lastSubmission.country ? lastSubmission.country.map(label => <div className={"pill"} key={`country-tag-${label}`} onClick={() => removeFilter(setCountry, label, country)}>
                    {label} <span>&nbsp;X</span>
                </div> ) : ''
            }
            { lastSubmission.topic ? lastSubmission.topic.map(label => <div className={"pill"} key={`topic-tag-${label}`} onClick={() => removeFilter(setTopic, label, topic)}>
                    {label} <span >&nbsp;X</span>
                </div> ) : ''
            }
        </div>
    )
}

export default NewsroomFilterTags