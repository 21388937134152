import React from 'react';

/**
 * @deprecated
 */
export default class SectionHeader extends React.Component {
  renderContent() {
    return { __html: this.props.content };
  }

  getClassName()
  {
    const { className } = this.props;
    return `section-header padding-bottom-3 text-center ${className ? className : ''}`
  }

  render() {
    return (
      <div className="flex flex-justify-center">
        <div
          className={ this.getClassName() }
          dangerouslySetInnerHTML={this.renderContent()}
        />
      </div>
    );
  }
}
