import React from 'react'
import classNames from "classnames"
import NewsroomSearchResults from "./NewsroomSearchResults";
import {isFiltering} from "../../newsroomHooks";
import CardTypes from "./PostCards";

const NewsroomPosts = (
    {
        cardTypes= Object.keys(CardTypes)
    }
) => {
    const filtering = isFiltering()

    if (filtering) {
        return ''
    }

    return (<div className={classNames('newsroom__posts')}>
        <h2 className={"display-none display-lg-block"}>All News</h2>
        <h2 className={"display-lg-none"} aria-hidden>Latest News</h2>
        <NewsroomSearchResults cardTypes={cardTypes} />
    </div>)
}

export default NewsroomPosts