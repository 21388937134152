import React from 'react'
import ReactDOM from 'react-dom'
import ExecutionEnvironment from 'exenv'
let basicScroll
import classNames from 'classnames'

export default class Parallax extends React.Component {

    initBasicScroll() {
        const { config } = this.props;

        if (!ExecutionEnvironment.canUseDOM || !config) {
            return
        }

        config['elem'] = ReactDOM.findDOMNode(this)

        if (!basicScroll) {
            basicScroll = require('basicscroll');
        }

        this.basicScroll = basicScroll.create(config)
        this.basicScroll.start()
    }


    componentDidMount() {
        this.initBasicScroll();

    }

    componentDidUpdate() {
        this.initBasicScroll()
    }

    render() {
        const { children, className } = this.props;

        return (
            <div className={ classNames('parallax', className) }>
                {children}
            </div>
        )
    }
}