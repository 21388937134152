import React from 'react'
import PropTypes from "prop-types";
import Html from './Html'
import RichText from './RichText'

export default class Children extends React.Component {
    render() {
        const {content} = this.props

        if (!content) {
            return ""
        }

        // Is it HTML?
        if (typeof content === 'string') {
            return <Html content={content} />
        }


        // Is it a Contentful Rich Text object?
        if (typeof content === 'object') {
            if (typeof content.nodeType !== 'undefined') {
                return <RichText content={content} />
            }
        }

       // Is it a render function?
        if (typeof content === 'function') {
            return content()
        }

        // It's probably React children or falsy
        return content
    }
}

React.propTypes = {
    content: PropTypes.any //HTML, Children, Rich Text, undefined, null
}