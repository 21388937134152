import React from 'react'
import Card from "./Card";
import Video from "./Video";
import {imagePath} from "../helpers";

const VideoCard = ({video}) => {
    const {date, title} = video
    return <div className={"video-card"}>
        <div className={"margin-bottom-4"}>
            <Video {...video} />
        </div>
        <div className={"label"}>
            {date}
        </div>
        <h3 className={"margin-top-0 margin-bottom-2"}>
            {title}
        </h3>
        <a href={video.url} title={"View " + title} className={"button--link"} target={"_BLANK"}>
            View <img className={"pdf-carousel__link-icon margin-left-1"}
                      src={imagePath('ui/ui-external-link.svg')}
                      alt={""}
                      width={12}/>
        </a>
    </div>
}

export default VideoCard