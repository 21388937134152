import React from 'react'
import PropTypes from "prop-types";
import {Swiper, SwiperSlide} from 'swiper/react';
import GallerySlide from './GallerySlide'
import {imagePath} from '../helpers'
import classnames from 'classnames'

export default class Gallery extends React.Component {

    constructor(props) {
        super(props)

        const prev = React.createRef()
        const next = React.createRef()

        this.state = {}

        this.state = {
            prev,
            next,
            mounted: false,
            current: 1,
            total: props.items.length,
            hasAccents: props.items.some(({accent}) => !!accent)
        }
    }

    componentDidMount() {
        const {mounted} = this.state

        this.setState({
            mounted: true
        })
    }


    render() {
        const {prev, next, hasAccents} = this.state

        return <div className={classnames("gallery__wrapper", {'gallery--has-accents': hasAccents})}>
            {this.renderNavigation()}
            {(prev.current && next.current) ? <Swiper
                slidesPerView={1}
                onSlideChange={swiper => this.handleSlideChange(swiper)}
                slidesPerGroup={1}
                spaceBetween={25}
                pagination={{clickable: true}}
                watchSlidesVisibility
                watchSlidesProgress
                navigation={{
                    nextEl: next.current,
                    prevEl: prev.current
                }} className={classnames('gallery', {'gallery--has-accents': hasAccents})}>
                {this.renderSlides()}
            </Swiper> : null}
        </div>
    }

    renderSlides() {
        const {items, accentColor} = this.props
        return items.map((item, idx) => <SwiperSlide key={`gallery-slide-${idx}`}>
                <GallerySlide  {...item} accentColor={accentColor}/>
            </SwiperSlide>
        )
    }

    renderNavigation() {
        const {prev, next, current, total} = this.state

        return <div className={"gallery__navigation"}>
            <div className={"swiper-button-prev"} ref={prev} aria-label={"Previous"}>
                <img src={imagePath('ui/ui-circle-thin-arrow-left.svg')} width={50} height={50} alt={"Left arrow"}/>
            </div>
            <div className={'swiper-count'}>
                {current}/{total}
            </div>
            <div className={"swiper-button-next"} ref={next} aria-label={"Next"}>
                <img src={imagePath('ui/ui-circle-thin-arrow-right.svg')} width={50} height={50} alt={"Right arrow"}/>
            </div>
        </div>
    }

    handleSlideChange(swiper) {
        this.setState(
            {
                current: swiper.activeIndex + 1
            }
        )
    }
}

Gallery.propTypes = {
    items: PropTypes.array, //array of item objects
    accentColor: PropTypes.string //blue, teal, orange, navy, gold
}

Gallery.defaultProps = {
    accentColor: 'blue'
}
