import React from 'react';

/**
 * Handle weglot translations for a piece of text manually. Useful in case Wiglot has trouble tracking an element.
 */
export default class Translate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translatedText: props.text
        }

        this.handleLanguageChanged = (newLang, prevLang) => {
            this.translateTo(newLang)
        };
    }

    componentDidMount() {
        if (!Weglot) {
            return
        }

        if(Weglot.initialized) {
            setTimeout(() => this.init(), 1)
        } else {
           Weglot.on('initialized', () => {
               this.init()
           })
        }
    }


    componentWillUnmount() {
        Weglot.off("languageChanged", this.handleLanguageChanged)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text) {
            this.translateTo(Weglot.getCurrentLang());
        }
    }

    init() {
        this.translateTo(Weglot.getCurrentLang())
        Weglot.on("languageChanged", this.handleLanguageChanged)
    }

   async translateTo(language) {
        const {text} = this.props

       const response = await Weglot.translate(
           {
               'words':[
                   { "t":1,"w": text }
               ],
               'languageTo': language
           }
       )
       const [translatedText] = response
       this.setState({translatedText})
    }

    render() {
        const {translatedText} = this.state

        return <span data-wg-notranslate>
            {translatedText}
        </span>
    }
}