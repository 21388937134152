import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

let lazySizes = null;

if (canUseDOM) {
    lazySizes = require('lazysizes');
    lazySizes.expand = 100
    lazySizes.cfg.customMedia = {
        '--mobile': '(max-width: 658px)',
        '--tablet': '(max-width: 991px)'
    };
}

class LazySizes extends React.Component {
    static propTypes = {
        src: PropTypes.string,
        mobileSrcSet: PropTypes.string,
        dataSizes: PropTypes.string,
        dataSrc: PropTypes.string,
        dataSrcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
        className: PropTypes.string,
        iframe: PropTypes.bool
    };

    static defaultProps = {
        src: 'data:image/gif;base64,R0lGODdhEAAJAIAAAMLCwsLCwiwAAAAAEAAJAAACCoSPqcvtD6OclBUAOw==',
        dataSizes: 'auto',
        iframe: false
    };

    componentDidUpdate = (nextProps) => {
        let propsChanged = false;
        for (let propName of ['src', 'dataSizes', 'dataSrc', 'dataSrcSet', 'className', 'iframe']) {
            let prop = this.props[propName];
            let nextProp = nextProps[propName];
            if (prop !== nextProp) {
                propsChanged = true;
                break;
            }
        }
        if (propsChanged && lazySizes) {
            let lazyElement = ReactDOM.findDOMNode(this);
            if (lazySizes.hC(lazyElement, 'lazyloaded')) {
                lazySizes.rC(lazyElement, 'lazyloaded');
            }
        }

        if (!lazySizes) {
            return;
        }
        let lazyElement = ReactDOM.findDOMNode(this);
        if (!lazySizes.hC(lazyElement, 'lazyloaded') && !lazySizes.hC(lazyElement, 'lazyload')) {
            lazySizes.aC(lazyElement, 'lazyload');
        }
    };

    render() {
        let {
            children,
            src,
            dataSizes,
            dataSrc,
            className,
            iframe,
            imageRef,
            style,
            height,
            width,
            onLoad,
            alt = "",
            title,
            ...other
        } = this.props;
        className = classnames(['lazyload', className]).trim();
        if (iframe) {
            return (
                <iframe {...other}
                        src={dataSrc ? '' : src}
                        data-src={dataSrc}
                        className={className}></iframe>
            );
        }
        return (
            <picture>
                {children}
                <img ref={imageRef}
                     src={src}
                     data-src={dataSrc}
                     data-sizes={dataSizes}
                     className={className}
                     width={height}
                     height={width}
                     style={style}
                     onLoad={onLoad}
                     alt={alt ?? ""}
                     title={title}
                />
            </picture>
        );
    }
}

export default LazySizes;