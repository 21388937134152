import React from 'react';
import linkState from 'linkstate';
import {isInFrame} from "../helpers";

export default class Search extends React.Component {
    state = {
        query: '',
        focus: false
    };

    render() {
        const {query} = this.state

        return (
            <form className='search form' onSubmit={this.search.bind(this)} role={"search"}>
                <label onClick={this.search.bind(this)} htmlFor="query"><span
                    className="display-none display-lg-inline">Search</span> </label>
                <input type="text" name="query" placeholder="Search" value={query} onChange={linkState(this, 'query')}
                       onInput={linkState(this, 'query')} onFocus={this.handleFocus.bind(this)}
                       onBlur={this.handleBlur.bind(this)}/>
                <button type={"submit"} className={"sr-only"}/>
            </form>
        )
    }

    getUrl() {
        return window.location.origin + `/search?q=${this.state.query}`
    }

    handleFocus() {
        const {onFocus} = this.props
        this.setState({focus: true})
        onFocus()
    }

    handleBlur() {
        const {onBlur} = this.props
        this.setState({focus: false})
        onBlur()
    }

    search(e) {
        e.preventDefault()
        if (!this.state.query) {
            return;
        }
        if (isInFrame()) {
            window.top.location.href = this.getUrl()
        } else {
            window.location.href = this.getUrl()
        }
    }
}

Search.defaultProps = {
    onFocus: function () {
    },
    onBlur: function () {
    }
}