import NewsroomPostMeta from "./NewsroomPostMeta";
import React from "react";
import {documentToPlainTextString} from '@contentful/rich-text-plain-text-renderer';
import {words} from "../../helpers";
import NewsroomTopics from "./NewsroomTopics";
import ContentfulImage from "../ContentfulImage";
import RichText from "../RichText";
import {isFiltering} from "../../newsroomHooks";

const NewsroomFeaturedPost = ({post}) => {
    let {title, excerpt, content, author, thumbnail, slug} = post
    excerpt = excerpt ? excerpt : words(documentToPlainTextString(content), 45)
    const filtering = isFiltering()

    if (filtering) {
        return ''
    }

    return <div className={"newsroom__featured-post display-none display-lg-block"}>
        <div className={"row"}>
            <div className={"featured-post__content column-md-half column-xl-third padding-right-xl-6"}>
                <NewsroomPostMeta {...post} />
                <a href={"/newsroom/" + slug}
                   title={"View " + title}
                   className={"post__link"}>
                    <h2>
                        {title}
                    </h2>
                </a>

                <RichText content={excerpt}/>
                {author
                    ? <div className={"post__author-name"}>
                        {author.name}
                    </div>
                    : ''
                }

                <NewsroomTopics {...post} link={true}/>
            </div>
            <div className={"featured-post__image column-md-half column-xl-two-thirds"}>
                <ContentfulImage image={thumbnail}
                                 className={"img-fluid"}
                                 width={1580}
                                 height={1064}/>
            </div>
        </div>
    </div>
}

export default NewsroomFeaturedPost