import React from 'react';
import classnames from 'classnames'
import Flipbook from "./Flipbook";
import PropTypes from "prop-types";
import Children from "./Children";
import Gallery from "./Gallery";
import Buttons from "./Buttons";

export default class GalleryFlipbook extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasAccents: props.items.some(({accent}) => !!accent)
        }
    }
    render() {
        const {reverse, mobileReverse, column, className, accentColor} = this.props
        const {hasAccents} = this.state

        return <Flipbook
            className={classnames(
                "gallery-flipbook",
                className,
                {'gallery-flipbook--has-accents': hasAccents}
            )}
            columnA={() => this.renderGallery()}
            columnAClassName={
                classnames(
                    {
                        "padding-right-xl-4": !reverse,
                        "padding-left-xl-4": reverse
                    },
                    "gallery-flipbook__gallery",
                )
            }
            columnAWidth='custom'
            columnAConstrained={true}
            columnB={() => this.renderContent()}
            columnBClassName={
                classnames(
                    {
                        "padding-left-xl-4": !reverse,
                        "padding-right-xl-4": reverse,
                    },
                    "gallery-flipbook__content",
                    'flex',
                    'flex-align-center',
                    'padding-left-gutter',
                    'margin-bottom-4',
                    'margin-bottom-xl-0'
                )
            }
            columnBWidth='custom'
            columnBConstrained={true}
            reverse={reverse}
            mobileReverse={mobileReverse}
            column={column}
            breakpoint={"lg"}
        />
    }

    renderGallery() {
        const {items, accentColor} = this.props;
        return <Gallery items={items} accentColor={accentColor}/>
    }

    renderContent() {
        const {content, buttons} = this.props;

        return <div className={"gallery-flipbook__content__inner"}>
            <Children content={content}/>
            <Buttons buttons={buttons}/>
        </div>
    }

}

GalleryFlipbook.propTypes = {
    reverse: PropTypes.bool, //Reverse the column order
    mobileReverse: PropTypes.bool, //Reverse the column order on mobile
    column: PropTypes.bool, //Should it be a column instead of a row?
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),//CSS Class
    content: PropTypes.any, //Content for the content column
    items: PropTypes.array, //Gallery item objects.
    accentColor: PropTypes.string, //blue, teal, orange, navy, gold,
    buttons: PropTypes.array //Array of contentful button objects
};

GalleryFlipbook.defaultProps = {
    reverse: false,
    column: false,
    accentColor: 'blue'
}
