import React, {useState, useEffect} from 'react';
import WeglotSelect from "./WeglotSelect";
import AudimaIcon from './AudimaIcon'
import uniqid from 'uniqid'

const TranslationWidget = function() {
    const [audimaKey, setAudimaKey] = useState(uniqid())
    const [weglotReady, setWeglotReady] = useState(false)

    useEffect(() => {
        if (weglotReady) {
            return;
        }

        const watchWeglot = () => {
            if(Weglot.initialized) {
                setWeglotReady(true)
            } else {
                Weglot.on('initialized', () => {
                    setWeglotReady(true)
                })
            }
        }
        watchWeglot()
    }, [weglotReady])


    return (<div className="flex flex-justify-space-around">
            <div className={"translation-widget"}>
            <label className={"tag"}>Read or hear text in:</label>
            <div className={"translation-widget__inner"}>
                <div className={"translation-widget__wiglot"}>
                    <WeglotSelect id={'translate'} name={"translate"} onChange={() => {
                        setAudimaKey(uniqid())
                    }}/>
                </div>
                <div className={"translation-widget__audima"}>
                    {weglotReady ? <AudimaIcon key={audimaKey} /> : ''}
                </div>
            </div>
        </div>
    </div>)
}

export default TranslationWidget