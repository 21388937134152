import React from 'react';
import Plyr from 'plyr';
import PropTypes from "prop-types";

export default class Video extends React.Component {
    plyr

    constructor() {
        super();
        this.video = React.createRef();
    }

    componentDidMount() {
        this.plyr = new Plyr(this.video.current, {
            youtube: {noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1}
        });
    }

    get type() {
        const {url} = this.props

        if (url.includes('vimeo')) {
            return 'vimeo'
        }

        if (url.includes('youtube') || url.includes('youtu.be')) {
            return 'youtube'
        }

        if (url.includes('ted.com')) {
            return 'ted'
        }

        if (url.includes('facebook.com/plugins/video.php')) {
            return 'facebook-embed'
        }

        if (url.includes('facebook.com')) {
            return 'facebook'
        }

        //Assume it's not youtube or vimeo
        return 'iframe'
    }

    get embedUrl() {
        const {url} = this.props

        switch (this.type) {
            case 'ted':
                return url.replace('www.ted', 'embed.ted')
            case 'facebook':
                return "https://www.facebook.com/plugins/video.php?href=" + encodeURIComponent(this.url)
            default:
                return url
        }
    }

    renderIframe() {
        return (<div style={{maxWidth: '854px'}}>
            <div style={{position: 'relative', height: 0, paddingBottom: '56.25%'}}>
                <iframe src={this.embedUrl}
                        width="854"
                        height="480"
                        style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}
                        frameBorder="0"
                        scrolling="no"
                        allowFullScreen />
            </div>
        </div>)
    }


    renderPlyr() {
        return <div
            data-plyr-embed-id={this.embedUrl}
            data-plyr-provider={this.type}
            ref={this.video}
        />
    }

    render() {
        switch (this.type) {
            case 'vimeo':
            case 'youtube':
                return this.renderPlyr()
            default:
                return this.renderIframe()
        }
    }
}

Video.propTypes = {
    url: PropTypes.string.isRequired, //Vimeo or Youtube URL
};