import React, {useState, useEffect, Fragment} from 'react'
import classNames from "classnames"
import Loader from "../Loader";
import ErrorBoundary from "../ErrorBoundary";
import {useLastParam, useSearch, useSubmissionParam} from "../../newsroomHooks";
import NewsroomPostGrid from "./NewsroomPostGrid";
import NewsroomStaggerGrid from "./NewsroomStaggerGrid";
import CardTypes from './PostCards/index'

const NewsroomSearchResults = (
    {
        layout = 'stagger-grid',
        cardTypes = Object.keys(CardTypes)
    }
) => {
    const {results, search, inProgress, fetch} = useSearch()

    const [, setPage] = useLastParam('page')

    const nextPage = () => {
        setPage(parseInt(search.page) + 1)
        fetch()
    }

    if (!inProgress && !results.length) {
        return <p>
            No matching results found.
        </p>
    }

    const ListingComponent = {
        'grid': NewsroomPostGrid,
        'stagger-grid': NewsroomStaggerGrid
    }[layout]


    const canPage = search.nbPages > search.page + 1

    return (<ErrorBoundary>
        <div className={classNames('newsroom__search-results')}>
            <ListingComponent posts={results} cardTypes={cardTypes}/>
            <div className={"flex flex-justify-center"}>
                <Loader active={canPage && inProgress}/>

                {!inProgress && canPage
                    ? <div className={"button--link"}
                           onClick={nextPage}>
                        Load More
                    </div>
                    : ''
                }
            </div>
        </div>
    </ErrorBoundary>)
}

export default NewsroomSearchResults