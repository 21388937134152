import React from 'react'
import PropTypes from "prop-types";
import classnames from 'classnames'
import Children from "./Children";

/**
 * A component for taking a content pair and placing reversing or stacking it.
 */
export default class Flipbook extends React.Component {
    get classNames()
    {
        const {className, breakpoint, column, reverse, mobileReverse, columnASize} = this.props


        let classNames = [className, 'flipbook', `display-flex`]

        if (column) {
            classNames.push('container')
        }

        if (reverse && column) {
            classNames.push(`flex-${breakpoint}-column-reverse`)
        }

        if (reverse && !column) {
            classNames.push(`flex-${breakpoint}-row-reverse`)
        }

        if (!reverse && !column) {
            classNames.push(`flex-${breakpoint}-row`)
        }

        if (!reverse && column) {
            classNames.push(`flex-${breakpoint}-column`)
        }

        if (mobileReverse) {
            classNames.push(`flex-column-reverse`)
        } else {
            classNames.push(`flex-column`)
        }

        return classnames(classNames)
    }

    get columnAClassNames()
    {
        const {columnAClassName, column, reverse, columnAWidth, columnAConstrained, columnASize, breakpoint, backgroundColor} = this.props

        let classNames = [columnAClassName, `flipbook__column--${columnASize}`, "flipbook__column", "flipbook__column--a", "border-box", `bg-${backgroundColor}`]

        if (!column) {
            classNames.push(`width-${breakpoint}-${columnAWidth}`)
        }

        if (column && !reverse) {
            classNames.push(`margin-bottom-gutter`)
        }

        if (columnAConstrained && !reverse && !column) {
            classNames.push(`padding-left-page-gutter`)
        }

        if (columnAConstrained && reverse && !column) {
            classNames.push(`padding-right-page-gutter`)
        }

        return classnames(classNames)
    }

    get columnBClassNames()
    {
        const {columnBClassName, column, reverse, columnBWidth, columnBConstrained, columnASize, breakpoint, backgroundColor} = this.props

        let columnBSize = '50';

        switch(columnASize) {
            case '33':
                columnBSize = '66'
                break;
            case '40':
                columnBSize = '60'
                break;
            case '60':
                columnBSize = '40'
                break;
            case '66':
                columnBSize = '33'
                break;
        }

        let classNames = [columnBClassName, `flipbook__column--${columnBSize}`, "flipbook__column", "flipbook__column--b", "border-box", `bg-${backgroundColor}`]

        if (!column) {
            classNames.push(`width-${breakpoint}-${columnBWidth}`)
        }

        if (column && reverse) {
            classNames.push(`margin-bottom-gutter`)
        }

        if (columnBConstrained && !reverse && !column) {
            classNames.push(`padding-right-page-gutter`)
        }

        if (columnBConstrained && reverse && !column) {
            classNames.push(`padding-left-page-gutter`)
        }

        return classnames(classNames)
    }


    render() {
        let {columnA, columnB, style} = this.props

        return <div className={this.classNames} style={style}>
            {(columnA) ? <div className={this.columnAClassNames}>
                <Children content={columnA}/>
            </div> : ''}
            {(columnB) ? <div className={this.columnBClassNames} style={style}>
                <Children content={columnB}/>
            </div> : ''}
        </div>
    }

}

Flipbook.propTypes = {
    reverse: PropTypes.bool, //Reverse the column order,
    mobileReverse: PropTypes.bool, //Reverse the column order on mobile
    column: PropTypes.bool, //Should it be a column instead of a row?
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),//CSS Class
    columnA: PropTypes.any, //Content or render function for column A
    columnB: PropTypes.any, //Content or render function for column B
    columnAConstrained: PropTypes.bool, //Should column A content be constrained to the container edge or screen edge?
    columnBConstrained: PropTypes.bool, //Should column A content be constrained to the container edge or screen edge?
    columnAClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),//CSS Class for column A
    columnBClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),//CSS Class for column B,
    columnAWidth: PropTypes.string, //See widths.scss
    columnBWidth: PropTypes.string, //See widths.scss
    showA: PropTypes.bool, //Should we show the left side,
    showB: PropTypes.bool, //Should we show the left side
    backgroundColor: PropTypes.string
};

Flipbook.defaultProps = {
    reverse: false,
    column: false,
    columnAConstrained: false,
    columnBConstrained: false,
    showA: true,
    showB: true,
    columnAWidth: '50',
    columnBWidth: '50',
    breakpoint: 'lg'
}
