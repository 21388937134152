import React from 'react';
import classnames from 'classnames'
import Link from "./Link";

/**
 * Button
 * ------
 *
 * Props:
 * link: (string) url
 * label: (string)
 * color (string) malala, transparent, white,
 * icon: (string) chevron, arrow
 * icon-visibility: (string) hover
 */
export default class Button extends React.Component {
    iconMap = {
        'chevron': 'chevron-right',
        'arrow': 'long-arrow-alt-right'
    }

    renderIcon() {
        let {icon} = this.props;

        if (!icon) {
            return
        }

        icon = this.iconMap[icon] ? this.iconMap[icon] : icon

        return <span className={classnames('fa', `fa-${icon}`, 'padding-left-1')}/>
    }

    render() {
        const {link, label, color, size, iconVisibility} = this.props;

        const className = classnames({
                "button": color !== "link"
            },
            `button--${color}`,
            `button--${size}`,
            {'button--icon-hover': iconVisibility === 'hover'}
        )

        return (
            link ? <Link href={link} className={className} icon={false}>
                {label}
                {this.renderIcon()}
            </Link> : <span className={className}>
        {label}&nbsp;{this.renderIcon()}
      </span>
        )
    }

}