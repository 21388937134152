import {useSsr} from "../hooks"
import React from "react";

/**
 * A component that conditionally renders its children depending on whether the application is being rendered
 * on the server side (SSR) or the client side.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children to be rendered.
 *
 * @returns {ReactNode} - The rendered component or null if being rendered on SSR.
 */
const NoSSR = ({children}) => {
    const isSSR = useSsr();

    if (isSSR) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
};

export default NoSSR;