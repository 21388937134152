import React from 'react'
import NewsroomPostCard from "./NewsroomPostCard"
import NewsroomPostCarousel from "./NewsroomPostCarousel"
import classNames from "classnames"

const NewsroomFeaturedPosts = ({posts, mobileCarousel = true, columns = 3}) => {
    const Columns = <div className={
        classNames({
            "row": true,
            'display-none display-lg-flex': mobileCarousel,
        })
    }>
        {
            posts.map(post => (
                <div className={
                    classNames({
                        "column": true,
                        "column-lg-quarter": columns === 4,
                        "column-lg-third": columns === 3,
                        "column-lg-half": columns === 2
                    })
                }
                     key={`featured-post-${post.id}`}>
                    <NewsroomPostCard {...post} />
                </div>
            ))
        }
    </div>

    const Carousel = <div className="row display-lg-none">
        <div className={"column"}>
            <NewsroomPostCarousel posts={posts}/>
        </div>
    </div>

    return (<div className={"featured-posts"}>
        <div className={"container"}>
            {Columns}
            {mobileCarousel ? Carousel : ''}
        </div>
    </div>)
}

export default NewsroomFeaturedPosts