import React from 'react';
import Link from "./Link";

export default class Footer extends React.Component {
    render() {
        const {
            legal,
            legalMenu,
        } = this.props;

        const date = new Date();
        const year = date.getFullYear();

        return (
            <footer data-wg-notranslate>
                <div className="footer__terms-and-conditions">
                    <div className="container flex flex-row flex-justify-space-between flex-align-center">
                        <div className="footer__terms-and-conditions__nav">
                            {
                                legalMenu.items.map((item, idx) => {
                                    return <span key={`footer-legal-menu-${idx}`}>{idx ? '|' : ''}<Link {...item} className={'margin-left-1 margin-right-1'}/></span>
                                }
                            )
                            }
                        </div>
                        <p>{legal.replace("{year}", year)}</p>
                    </div>
                </div>
            </footer>
        );
    }
}
