import ContentfulImage from "./ContentfulImage";
import ReactResizeDetector from "react-resize-detector";
import Card from "./Card";
import Html from "./Html";
import React from 'react'
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from 'classnames'

export default class GallerySlide extends React.Component {
    constructor(props) {
        super(props);
        this.card = React.createRef();
        this.image = React.createRef();

        this.state = {
            cardWidth: "0",
            cardHeight: "0",
            accentHeight: props.accent ? props.accent.height : "0",
            accentWidth: props.accent ? props.accent.width : "0",
            mounted: false
        }
    }

    refreshSwiper()
    {
        window.dispatchEvent(new Event('resize'));
    }

    componentDidMount() {
        this.refreshSwiper()
        setTimeout(() => {
            this.setState({'mounted': true}, () => this.handleCardResize())
        }, 500)
    }

    render() {
        const {image, title, description, accent, accentColor} = this.props
        const {cardWidth, cardHeight, accentHeight, accentWidth, mounted} = this.state

        return <div className={classnames([`gallery__slide gallery__slide--accent-color-${accentColor}`, {'gallery__slide--has-accent': !!accent}])}>
            <ReactResizeDetector refreshMode={'debounce'} refreshRate={500} handleHeight handleWidth onResize={this.handleCardResize.bind(this)}>
                <div className={"gallery__slide__inner display-flex"} style={{
                    "--cardWidth": cardWidth + "px",
                    "--cardHeight": cardHeight + "px",
                    "--accentHeight": accentHeight + "px",
                    "--accentWidth": accentWidth + "px"
                }}>
                    <div className={"gallery__accent__container position-relative"}>
                        <ContentfulImage image={accent} className={"gallery__accent__image"} lazy={false}/>
                    </div>
                    <div className={"gallery__content__container"}>
                        <div className={"gallery__card__backdrop"}/>
                        <div className={"gallery__image__container flex-grow-1"}>
                            {mounted ? <ContentfulImage image={image} className={"gallery__image"} width={640} height={486} widthMobile={390} heightMobile={400} ref={this.image} lazy={false}/> : '' }
                        </div>
                        <Card className={"gallery__card"} cardRef={this.card} mobileFontSize={'medium'}>
                            <h3>{title}</h3>
                            <Html content={description}/>
                        </Card>
                    </div>

                </div>
            </ReactResizeDetector>
        </div>
    }


    handleCardResize() {
        const {mounted} = this.state

        if (!mounted) {
            return
        }

        const cardEl = ReactDOM.findDOMNode(this.card.current)
        const imageEl = ReactDOM.findDOMNode(this.image.current).querySelector('img')

        const imageHeight = imageEl.offsetHeight
        const galleryWrapper = imageEl.closest('.gallery__wrapper')

        galleryWrapper.style.setProperty('--galleryImageHeight', imageHeight + "px");
        galleryWrapper.style.setProperty('--galleryHeight', galleryWrapper.offsetHeight + "px");
        this.refreshSwiper()

        this.setState(
            {
                cardWidth: cardEl.offsetWidth,
                cardHeight: cardEl.offsetHeight
            }
        )
    }
}

GallerySlide.propTypes = {
    item: PropTypes.object, //item object from Contentful
    accentColor: PropTypes.string //blue, teal, orange, navy, gold
}

GallerySlide.defaultProps = {
    accentColor: 'blue'
}