import React from 'react';
import Html from './Html'
import ReactDOMServer from 'react-dom/server';
import {imagePath} from "../helpers";
import Link from "./Link";
import TrilogyForm from "./TrilogyForm";

export default class Footer extends React.Component {
    renderSocialMediaIcons() {
        const {socialMedia} = this.props;

        return socialMedia.items.map((icon) =>
            <a className="footer__social-media-icon"
               href={icon.link}
               key={icon.link}
               title={icon.label}
               aria-label={icon.label}>
                <i className={`fab fa-${icon.label}`}></i>
            </a>
        )
    }

    renderForm() {
        return <div className={'email-subscribe mb-4'}>
            <h4>Join our email list</h4>
            <TrilogyForm>
                <form action="https://malala.trilogyforms.com/save" method="post">
                    <input type="email" name="EmailAddress" placeholder="Enter email" aria-label={"Email"}
                           className="space-right-2"/>
                    <input name="commit" type="submit" value="Join"/>
                    <input type="hidden" name="ActivityCode" value="join"/>
                    <input type="hidden" name="SuccessUrl" value="https://malala.org/donate/signup?utm_source=em_ft"/>
                    <input type="hidden" name="SessionEntryUrl" value="https://www.malala.org/"/>
                </form>
            </TrilogyForm>
        </div>
    }

    renderMenu({items}) {
        return <ul className={"footer-nav-menu padding-left-0"}>
            {items.map((item, idx) =>
                <li key={`footer-menu-${idx}`} className={"footer-nav-menu__item"}>
                    <Link {...item} />
                </li>
            )}
        </ul>
    }


    render() {
        const {
            logoURL,
            missionText,
            menu1Heading,
            menu1,
            menu2Heading,
            menu2,
            menu3Heading,
            menu3,
            legalMenu,
            legal
        } = this.props;


        const date = new Date();
        const year = date.getFullYear();

        return (
            <footer className="footer position-relative" data-wg-notranslate>
                <div className="container position-relative">
                    <img
                        src={imagePath('accents/accent-9.svg')}
                        width="39px"
                        height="39px"
                        className="footer__accent display-none display-xxl-flex"
                        alt={""}
                    />
                    <img
                        src={imagePath('accents/accent-3.svg')}
                        width="44px"
                        height="44px"
                        className="footer__accent display-lg-none"
                        alt={""}
                    />
                    <div className="footer__main flex flex-column flex-lg-row flex-justify-space-between">
                        <div className="flex-1 margin-bottom-5 margin-bottom-lg-3">
                            <img className="footer__logo margin-bottom-4" src={logoURL} alt={"Malala Fund"}/>
                            <p className="footer__mission">{missionText}</p>
                            <h3>malala.org</h3>
                        </div>

                        <h4 className="display-lg-none">Quick Links</h4>

                        <div className="flex-1">
                            <div
                                className="footer__columns flex flex-row display-lg-none flex-no-\wrap margin-bottom-4">
                                <div className={"flex-grow"}>
                                    <h4>{menu1Heading}</h4>
                                    {this.renderMenu(menu1)}
                                </div>
                                <div className={"flex-grow"}>
                                    <h4>{menu2Heading}</h4>
                                    {this.renderMenu(menu2)}
                                </div>
                            </div>

                            <div className="footer__columns flex-row display-none display-lg-flex margin-bottom-4">
                                <div className="flex-auto margin-bottom-3 flex-lg-1">
                                    <h4>{menu1Heading}</h4>
                                    {this.renderMenu(menu1)}
                                </div>
                                <div className="flex-auto margin-bottom-3 flex-lg-1">
                                    <h4>{menu2Heading}</h4>
                                    {this.renderMenu(menu2)}
                                </div>
                                <div className="flex-auto margin-bottom-3 flex-lg-1">
                                    <h4>{menu3Heading}</h4>
                                    {this.renderMenu(menu3)}
                                </div>
                            </div>

                            <div className="flex flex-column flex-lg-row">
                                <div className="flex-auto flex-lg-2 margin-bottom-3 has-form--horizontal">
                                    {this.renderForm()}
                                </div>

                                <div className="flex-auto flex-lg-1">
                                    <h4>Follow Us</h4>
                                    {this.renderSocialMediaIcons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer__terms-and-conditions">
                    <div className="container flex flex-row flex-justify-space-between flex-align-center">
                        <div className="footer__terms-and-conditions__nav">
                            {
                                legalMenu.items.map((item, idx) => {
                                        return <span key={`footer-legal-menu-${idx}`}>{idx ? '|' : ''}<Link {...item}
                                                                                                            className={'margin-left-1 margin-right-1'}/></span>
                                    }
                                )
                            }
                        </div>
                        <p>{legal.replace("{year}", year)}</p>
                    </div>
                </div>
            </footer>
        );
    }
}
