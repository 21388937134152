import PropTypes from 'prop-types';
import React from 'react';
import http from 'axios'
import classnames from 'classnames'
import Loader from './Loader'
import Card from "./Card";
import {chunk, imagePath, documentPosition} from '../helpers'
import Select from './Select'

export default class ChampionsSearch extends React.Component {

    filters
    footer
    shouldScrollTo
    shouldScrollIntoView
    showFilters
    state = {
        loading: false,
        champions: [],
        approaches: [],
        countries: [],
        total: 0,
        count: 0,
        country: '',
        approach: '',
        limit: 12,
        page: 1,
        error: null,
        gridSize: 3,
        order: null
    }

    /**
     * Kick things off
     * @param props
     */
    constructor(props) {
        super(props);
        const {country, approach} = this.props
        this.filters = React.createRef()
        this.footer = React.createRef()
        this.state.country = country
        this.state.approach = approach
    }

    get countryOptions() {
        const {countries} = this.state
        return countries.map(country => {
            return {value: country.slug, label: country.title}
        })
    }

    get approachOptions() {
        const {approaches} = this.state
        return approaches.map(approach => {
            return {value: approach, label: approach}
        })
    }

    get currentApproachOption() {
        const {approach} = this.state

        const current = this.approachOptions.find((option) => {
            return option.value === approach
        })

        return current ? current : null
    }

    get currentCountryOption() {
        const {country} = this.state

        const current = this.countryOptions.find((option) => {
            return option.value === country
        })

        return current ? current : null
    }

    get hasFilters() {
        const {country, approach} = this.state

        return country || approach
    }

    componentDidMount() {
        this.search()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollTo()
        this.scrollIntoView()
    }

    /**
     * Fetch champions from the server using the current search values.
     * @returns {Promise<void>}
     */
    async search(params = {}) {

        //Grab the vars we need from the params, merging in defaults from the state
        let {country, approach, limit, page, skip, order} = Object.assign(Object.assign({}, this.state), params)

        this.setState({
            loading: true,
            error: false
        })

        //Are we paging normally or skipping manually
        params = {}
        if (skip) {
            params = {
                country,
                approach,
                limit: limit,
                skip: skip,
                order
            }
        } else {
            params = {
                country,
                approach,
                limit,
                page,
                order
            }
        }

        try {
            const {data} = await http.get('/champions/search', {params})

            const {champions} = this.state
            const {approaches, countries, total} = data

            champions.push(...data.champions)

            let gridSize = 3
            if (champions.length === 1) {
                gridSize = 1
            } else if (champions.length < 6) {
                gridSize = 2
            }

            //Are we skipping manually, we need to manually calculate the new page
            if (skip) {
                page = Math.ceil(total / this.state.limit)
            }

            this.setState({
                champions,
                approaches,
                countries,
                loading: false,
                country,
                approach,
                page,
                gridSize,
                total,
                count: champions.length,
                order: data.order
            })
        } catch (e) {
            this.setState({
                error: 'Error fetching champions.',
                loading: false
            })
        }
    }

    /**
     * Load a new page
     */
    addPage() {
        let {page} = this.state
        page++
        this.search({page})
    }

    /**
     * Remove a page
     */
    removePage() {
        let {champions, page, limit} = this.state
        page--

        if (!page) {
            return
        }

        champions = champions.slice(0, limit * page)

        this.scrollIntoView(this.footer)

        this.setState({
            champions,
            page,
            count: champions.length
        })
    }

    /**
     * Show all champions
     */
    addAll() {
        const {count} = this.state
        this.search({
            skip: count,
            limit: 999
        })
    }

    /**
     * Handle a user changing the country
     */
    handleCountryChange(option) {
        this.clear()

        if (!option) {
            this.search({country: null, page: 1, limit: 12, order: null})
            return
        }

        const {value: country} = option

        this.search({country, page: 1, limit: 12, order: null})
    }

    /**
     * Handle a user changing the country
     */
    handleApproachChange(option) {
        this.clear()

        if (!option) {
            this.search({approach: null, page: 1, limit: 12, order: null})
            return
        }

        const {value: approach} = option

        this.search({approach, page: 1, limit: 12, order: null})
    }

    /**
     * Scroll to the top on filter focus
     * @param event
     */
    handleFilterFocus() {
        this.scrollTo(this.filters)
    }

    handleReset(event) {
        this.reset()
        this.search({
            country: '',
            approach: '',
            limit: 12,
            page: 1,
        })
    }

    /**
     * Clear the results.
     */
    clear() {
        this.setState({
            champions: [],
            approaches: [],
            countries: []
        })
    }


    /**
     * Reset the search to the default state.
     */
    reset() {
        this.setState({
            loading: false,
            champions: [],
            approaches: [],
            countries: [],
            country: '',
            approach: '',
            limit: 12,
            page: 1,
            error: null,
            gridSize: 3
        })
    }

    /**
     * Scroll the last item into view
     */
    scrollIntoView(ref) {
        if (ref) {
            this.shouldScrollIntoView = ref.current
            this.forceUpdate()
            return
        }

        if (!this.shouldScrollIntoView) {
            return
        }

        let el = this.shouldScrollIntoView
        this.shouldScrollIntoView = null

        el.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
        });

    }

    /**
     * Scroll to the last item
     * @param ref
     */
    scrollTo(ref) {
        if (ref) {
            this.shouldScrollTo = ref.current
            this.forceUpdate()
            return
        }

        if (!this.shouldScrollTo) {
            return
        }

        let el = this.shouldScrollTo
        this.shouldScrollTo = null

        scrollTo({
            top: documentPosition(el).top - 125,
            behavior: 'smooth'
        })
    }

    /**
     * Render the component
     */
    render() {
        const {loading} = this.state

        return <div className={classnames("champions__search", {"champions__search--loading": loading})}>
            {this.renderFilters()}
            {this.renderChampions()}
            {this.renderLoader()}
            {this.renderFooter()}
        </div>
    }

    /**
     * Render the search filters.
     */
    renderFilters() {
        const {showFilters} = this.props

        if (!showFilters) {
            return
        }

        return <form
            className={"champions__search__filters"}
            ref={this.filters}>

            <div className={"champions__search__label display-flex flex-align-items-center"}>
                <label className={"label text-small"}>Filter by: </label>
            </div>

            <Select options={this.countryOptions}
                    placeholder={"Select Country"}
                    onFocus={this.handleFilterFocus.bind(this)}
                    onChange={this.handleCountryChange.bind(this)}
                    isSearchable={true}
                    isClearable={true}
                    value={this.currentCountryOption}
            />
            <Select options={this.approachOptions}
                    placeholder={"Select Approach"}
                    onFocus={this.handleFilterFocus.bind(this)}
                    onChange={this.handleApproachChange.bind(this)}
                    isSearchable={true}
                    isClearable={true}
                    value={this.currentApproachOption}
            />

            {this.renderClearButton()}

        </form>
    }

    /**
     * Render the clear filters button if we have filters.
     */
    renderClearButton() {
        if (!this.hasFilters) {
            return
        }

        return <div
            className={"champions__search__clear cursor-pointer text-cobalt button-text text-small display-flex flex-align-items-center"}
            onClick={this.handleReset.bind(this)}>
            Clear Filters <img src={imagePath('ui/ui-close-cobalt.svg')} width={12} height={12}
                               className={"margin-left-1"} alt={"Close"}/>
        </div>
    }

    /**
     * Render the grid
     * @returns {*}
     */
    renderChampions() {
        const {champions, gridSize} = this.state
        let champion_idx = 0;

        return <div className={"champions__search__champions__wrapper"}>
            <div
                className={classnames("display-none", "display-lg-block", "champions__search__champions", `champions__search__champions--grid-size-${gridSize}`)}>
                {chunk(champions, gridSize).map((row, idx) => {
                    return <div className={classnames("row margin-bottom-8 champion__search__champions__row")}
                                key={`champion-row-${idx}`}>
                        {row.map(champion => {
                            champion_idx++
                            return this.renderChampion(Object.assign(champion, {odd: champion_idx % 2 !== 0}))
                        })}
                    </div>
                })}
            </div>
            <div className={"row flex-justify-center display-lg-none champions__search__champions"}>
                {champions.map((champion, idx) => {
                    //const condensed = champions.length > 3
                    const condensed = true
                    return this.renderMobileChampion(Object.assign(champion, {
                        gridSize: condensed ? 'half' : 'full',
                        showQuote: !condensed
                    }))
                })}
            </div>
        </div>
    }

    /**
     * Render and an individual champion.
     * @param champion
     * @returns {*}
     */
    renderChampion(champion) {
        const {name, country, slug, tagline, shortTagline, thumbnail, odd, approachTags} = champion
        const {gridSize} = this.state

        const desktopFontSize = gridSize === 3 && !odd ? "medium" : 'extra-large'
        const fontSize = gridSize === 3 && !odd ? "base" : 'medium'

        return <div key={`champion-${slug}`}
                    className={
                        classnames(
                            "champions__search__champion",
                            "column-half",
                            {'column-lg-third': gridSize === 3 && odd},
                            {'column-lg-quarter': gridSize === 3 && !odd}
                        )
                    }
        >
            <Card
                title={name}
                heading={() => {
                    return <div className={"champions__search__champion__heading"}>
                        <h3>{champion.name}</h3>
                        <div className={"tag"}>
                            {champion.country.title}
                        </div>
                    </div>
                }}
                headingPlacement={'footer-both'}
                footerContent={() => {
                    return <div className={"text-tiny"}>
                        {approachTags ? approachTags.join(", ") : ""}
                    </div>
                }}
                footerContentPlacement={'hover-footer'}
                footerPlacement={'both'}
                fontSize={fontSize}
                desktopFontSize={desktopFontSize}
                content={shortTagline ? shortTagline : tagline}
                contentPlacement={'hover'}
                image={thumbnail ? thumbnail : `${country.pattern.src}?fm=jpg&w=384&h=384&h=256&fit=fill`}
                pattern={country.pattern}
                patternPlacement={'hover'}
                hoverStyle={"popout"}
                iconPlacement={'hover-footer'}
                href={`/champions/${slug}`}
                hrefPlacement={"hover-footer"}
                imageWidth={1152}
                imageHeight={768}
                size={'small'}
            />
        </div>
    }

    /**
     * Render and an individual champion.
     * @param champion
     * @returns {*}
     */
    renderMobileChampion(champion) {
        const {name, country, slug, quote, thumbnail, showQuote, gridSize} = champion

        return <div key={`champion-${slug}-mobile`}
                    className={
                        classnames(
                            `column-${gridSize}`,
                            'margin-bottom-4'
                        )
                    }
        >
            <Card
                title={name}
                heading={() => {
                    return <div className={"champions__search__champion__heading"}>
                        <h3>{champion.name}</h3>
                        <div className={"tag"}>
                            {champion.country.title}
                        </div>
                    </div>
                }}
                footerContent={showQuote ? quote : null}
                headingPlacement={'footer'}
                image={thumbnail ? thumbnail : `${country.pattern.src}?fm=jpg&w=384&h=384&h=256&fit=fill`}
                icon={false}
                href={`/champions/${slug}`}
                hrefPlacement={"footer"}
                imageWidth={1152}
                imageHeight={768}
                size={"small"}
            />
        </div>
    }

    /**
     * Render the the loader icon
     */
    renderLoader() {
        const {loading} = this.state

        if (!loading) {
            return
        }

        return <div className={"padding-6 display-flex flex-justify-center flex-align-center"}>
            <Loader active={loading}/>
        </div>
    }

    /**
     * Render the buttons
     */
    renderFooter() {
        return <div className={'champions__search__footer buttons text-center margin-top-5'} ref={this.footer}>
            {this.renderPagination()}
        </div>
    }

    /**
     * Render the more/less buttons.
     */
    renderPagination() {
        const {showAll, showMore, showLess} = this.props
        const {count, total, limit: pageBy} = this.state

        const buttons = []

        if (showLess && count > pageBy) {
            buttons.push(<div className={"button"} key={"show-less"} onClick={this.removePage.bind(this)}>Show
                Less</div>)
        }

        if (showMore && count < total) {
            buttons.push(<div className={"button"} key={"show-more"} onClick={this.addPage.bind(this)}>Show More</div>)
        }

        if (showAll && count < total) {
            buttons.push(<div className={"button"} key={"show-all"} onClick={this.addAll.bind(this)}>Show All</div>)
        }

        return buttons;
    }
}

ChampionsSearch.defaultProps = {
    showAll: true,
    showLess: true,
    showMore: true,
    showFilters: true,
    country: '',
    approach: ''
}

ChampionsSearch.propTypes = {
    showAll: PropTypes.bool,
    showLess: PropTypes.bool,
    showMore: PropTypes.bool,
    showFilters: PropTypes.bool,
    country: PropTypes.string,
    approach: PropTypes.string
}