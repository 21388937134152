import React, { createRef } from "react";
import { imagePath } from "../helpers";
import uniqid from "uniqid";
import ExecutionEnvironment from "exenv";

export default class AudimaIcon extends React.Component {
  iconRef;

  constructor(props) {
    super(props);
    this.iconRef = createRef();
  }

  componentDidMount() {
    this.injectAudima();
  }

  injectAudima() {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }
    const container = document.createElement("div");
    container.setAttribute("id", "audimaWidget");
    this.iconRef.current.appendChild(container);

    const script = document.createElement("script");
    script.setAttribute(
      "src",
      `https://audio4.audima.co/audima-widget.js?bust=${uniqid()}`
    );

    this.iconRef.current.appendChild(script);
  }

  render() {
    return (
      <div className={"audima-icon__wrapper"}>
        <div className={"audima-icon"} ref={this.iconRef} />
      </div>
    );
  }

  componentWillUnmount() {
    this.iconRef.current.innerHTML = "";
  }
}
