import React, {Fragment} from "react";
import {imagePath} from "../helpers";
import {useIntersectionObserver} from "@asyarb/use-intersection-observer";
import classNames from "classnames";
import ExecutionEnvironment from "exenv";

const ScrollButton = ({label, target}) => {
    if (!ExecutionEnvironment.canUseDOM) {
        return Fragment;
    }
    const element = document.querySelector(target);
    const targetInView = useIntersectionObserver({
        element,
        options: {
            triggerOnce: false,
        },
    });

    const scrollToTarget = () => {
        element.scrollIntoView();
    };

    return (
        <div className={"scroll-button__wrap"}>
            <div
                className={classNames(
                    "scroll-button",
                    {"scroll-button--visible": !targetInView},
                    "transition bg-cobalt padding-x-3 padding-x-md-5 padding-y-1 padding-y-md-3 rounded flex flex-justify-center flex-align-items-center position-fixed z-1000 flex-column shadow cursor-pointer"
                )}
                onClick={scrollToTarget}>
                <img
                    className={"alert__image"}
                    src={imagePath("ui/ui-arrow-up-white.svg")}
                    width={15}
                    alt={"Scroll to top"}
                />
                <div
                    className={
                        "scroll-button__label text-tiny font-weight-thin text-uppercase text-white margin-top-1"
                    }>
                    {label}
                </div>
            </div>
        </div>
    );
};

export default ScrollButton;
