import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select'
import allLanguages from "../languages";

export default class LanguageSelect extends React.Component {
    constructor(props) {
        super(props);
        const {language} = this.props

        this.state = {
            language,
            initialized: false
        };
    }


    get options() {
        const {languages} = this.props
        return languages.map(this.expandCode.bind(this))
    }

    expandCode(code) {
        if (!code) {
            return null
        }

        return {
            label: allLanguages[code].nativeName,
            value: code
        }
    }

    render() {
        const {onChange, placeholder, code} = this.props

        return <div className={"weglot-select"}
                    data-wg-notranslate>
            {code ? <Select options={this.options}
                    placeholder={placeholder}
                    onChange={onChange}
                    isSearchable={false}
                    isClearable={false}
                    value={this.expandCode(code)}
            /> : <Select options={this.options}
                         placeholder={placeholder}
                         onChange={onChange}
                         isSearchable={false}
                         isClearable={false}
            />}
        </div>
    }
}

LanguageSelect.defaultProps = {
    languages: ['am', 'ar', 'en', 'es', 'fr', 'hi', 'br', 'tr', 'ur'],
    placeholder: 'Choose Language'
};

LanguageSelect.propTypes = {
    languages: PropTypes.array,
    language: PropTypes.string
}