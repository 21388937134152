import plyr from "./domHooks/plyr";
import rotateWords from "./domHooks/rotateWords";
import cssVars from "./domHooks/cssVars"
import trilogyForm from "./domHooks/trilogyForm";

export const domHooks = (root) => {
    root.querySelectorAll('body').forEach(el => {
        cssVars()
        el.classList.add('ready')
    })

    root.querySelectorAll(".plyr--dom").forEach(plyr)

    root.querySelectorAll('.rotate-words').forEach(rotateWords)

    root.querySelectorAll('form[action*="trilogyforms"]').forEach(trilogyForm)
}

export default domHooks