import React, {useCallback} from 'react'
import {useSubmissionParam} from "../../newsroomHooks";
import classNames from "classnames";

const NewsroomFilterSelect = ({options, name, label, multiple = true}) => {
    const isOptionActive = (value, option) => value.indexOf(option) !== -1
    let [value, setValue] = useSubmissionParam(name);
    const [, setPage] = useSubmissionParam('page');

    const handleClick = useCallback((option) => {
        const idx = value.indexOf(option)
        let newValue = [...value]

        if (isOptionActive(value, option)) {
            newValue.splice(idx, 1);
        } else {
            if (!multiple) {
                newValue.length = 0
            }
            newValue.push(option)
        }
        setPage(false)
        setValue(newValue)
    }, [value])

    return <div className={"filters__group filters__group--" + name}>
        <div className={"group__label"}>
            {label}
        </div>
        <div className={"group__filters"}>
            {
                options.map(option => <div key={`filter-${name}-${option}`}
                                           className={classNames("filters__filter", {"filters__filter--active": isOptionActive(value, option)})}
                                           onClick={() => handleClick(option)}>
                    <div className={"filter__label"}>
                        {option}
                    </div>
                </div>)
            }
        </div>
    </div>
}

export default NewsroomFilterSelect