const languages = {
    "am": {
        "name": "Amharic",
        "nativeName": "አማርኛ",
    },
    "ar": {
        "name": "Arabic",
        "nativeName": "العربية"
    },
    "bn": {
       "name": "Bengali",
       "nativeName": "বাংলা"
    },
    "en": {
        "name": "English",
        "nativeName": "English"
    },
    "es": {
        "name": "Spanish",
        "nativeName": "español"
    },
    "fr": {
        "name": "French",
        "nativeName": "français"
    },
    "br": {
        "name": "Portuguese",
        "nativeName": "Português"
    },
    "ur": {
        "name": "Urdu",
        "nativeName": "اردو"
    },
    "hi":{
        "name":"Hindi",
        "nativeName":"हिन्दी, हिंदी"
    },
    "ps":{
        "name":"Pashto",
        "nativeName":"پښتو"
    },
    "tr":{
        "name":"Turkish",
        "nativeName":"Türkçe"
    },
}

export default languages

const asArray = () => {
    const values = Object.values(languages)
    const keys = Object.keys(languages)

    return values.map((data, idx) => {
        data['code'] = keys[idx]
        return data
    })
}

export const findByName = (name) => {
    return asArray().find((data) => {
        return data["name"] === name
    })
}

export const filterByNames = (names) => {
    return asArray().filter((data) => {
        return names.includes(data['name'])
    })
}