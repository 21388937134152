import React from 'react';
import ContentfulImage from './ContentfulImage'
import {imagePath} from "../helpers";
import classnames from 'classnames'
import uniqid from "uniqid"
import ReactResizeDetector from "react-resize-detector";
import ReactDOM from "react-dom";
import Translate from "./Translate";

export default class PhotoStack extends React.Component {
    positions = [
        'bottom-left',
        'top-right',
        'top-left',
        'center'
    ]
    imageRefs = {}
    captionRefs = {}
    stackRef

    constructor(props) {
        super(props);

        let positionI = 0
        let photos = props.photos.map((photo) => {
            photo.position = this.positions[positionI]
            photo.key = uniqid()
            photo.captionOffset = 0

            positionI++
            if (positionI >= this.positions.length) {
                positionI = 0
            }
            return photo
        })
        this.imageRefs = photos.reduce((photoRefs, photo) => {
            photoRefs[photo.key] = React.createRef()
            return photoRefs
        }, {})
        this.stackRef = React.createRef()
        this.state = {
            photos: photos,
            current: photos[0],
            captionOffset: 0
        }

    }

    componentDidMount() {
        this.handleResize()
        setTimeout(this.handleResize.bind(this), 50)
    }

    render() {
        const {photos, current, height} = this.state
        return  <ReactResizeDetector handleWidth onResize={this.handleResize.bind(this)}>
            <div className={`photo-stack translate photo-stack--position-${current.position}`} style={{
                '--photo-stack-caption-offset': `${current.captionOffset}px`,
                '--photo-stack-height': `${height}px`,
            }}>
                <div className={"photo-stack__stack"} ref={this.stackRef}>
                    {
                        photos.slice().reverse().map((photo, idx) => {
                            let positionClass = `photo-stack__photo--position-${photo.position}`
                            let classNames = {
                                "photo-stack__photo": true,
                                'photo-stack__photo--active': idx + 1 === photos.length
                            }
                            classNames[positionClass] = true
                            return <div className={classnames(classNames)} key={photo.key} style={{
                                '--photo-stack-z': idx + 1,
                            }}>
                                <div className={"photo-stack__image"} onClick={() => this.next()}>
                                    <ContentfulImage image={photo.image} ref={this.imageRefs[photo.key]} onLoad={() => this.updateCaptionOffset(photo.key)}/>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className={"photo-stack__caption"}>
                    <div className={"photo-stack__caption-inner display-inline translate"}>
                        <Translate text={current.title} />
                        <br />
                        <a className={"photo-stack__link translate"} title={current.linkLabel} href={current.url}>
                            {current.linkLabel}
                            <img src={imagePath('ui/ui-long-arrow.svg')} width={"18"} height={"7"} alt={"Arrow"}
                                 className={"margin-left-1"} />

                        </a></div>
                </div>
        </div>
        </ReactResizeDetector>
    }

    next() {
        const {photos} = this.state
        const photo = photos.shift()
        photos.push(photo)
        this.setState({photos, current: photos[0]})
    }

    handleResize() {
        this.updateHeight()
        this.updateCaptionPositions()
    }

    updateCaptionPositions() {
        const {photos} = this.state
        photos.forEach(photo => {
            photo.captionOffset = this.getCaptionOffset(photo.key)
        })
        this.setState({photos})
    }

    updateCaptionOffset(key) {
        const {photos} = this.state
        const photo = photos.find(photo => photo.key === key)
        photo.captionOffset = this.getCaptionOffset(key)
        this.setState({photos})
    }

    getCaptionOffset(key) {
        const image = ReactDOM.findDOMNode(this.imageRefs[key].current).querySelector('img')
        const photoStack = ReactDOM.findDOMNode(this.stackRef.current)
        const imageBottomY = image.getBoundingClientRect().y + image.getBoundingClientRect().height
        const photoStackBottomY = photoStack.getBoundingClientRect().y + photoStack.getBoundingClientRect().height
        return ((photoStackBottomY - imageBottomY) * -1) - 50;
    }

    updateHeight() {
        this.setState({
            height: this.stackRef.current.clientHeight
        })
    }
}

PhotoStack.defaultProps = {
    photos: []
}