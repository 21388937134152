import PropTypes from "prop-types";
import React from "react";
import ContentfulImage from "./ContentfulImage";
import classnames from "classnames";
import Html from "./Html";
import SSRHtml from "./Html";
import Buttons from "./Buttons";
import Accents from "./Accents";
import ReactResizeDetector from "react-resize-detector";
import PatternBar from "./PatternBar";
import NoSSR from "./NoSSR";
import MalalaSignature from "./MalalaSignature";
import kebabCase from "just-kebab-case";
import BackgroundVideo from "./BackgroundVideo";
import Alert from "./Alert";
import TranslationWidget from "./TranslationWidget";
import PubSub from "pubsub-js";
import ExecutionEnvironment from "exenv";
import AudimaIcon from "./AudimaIcon";

/**
 * Hero Component
 *
 * Props:
 * heading: (string) A line of text to use for the hero heading. Accepts line breaks,
 * headingStyle: (text, ribbon) Should the heading be plain text or have a blue ribbon background?,
 * headingPlacement: (bottom, overlay) Where should the heading be placed?,
 * content: (HTML string) Text content to appear below the heading.,
 * contentWidth: (half, full) How wide should the content area be?
 * textPlacement: (middle, bottom) Where should the text be placed?
 * textAlignment: (left, right, center) Align the content and heading to the left or the right.,
 * textColor: (white, malala) Make the hero text any theme color,
 * mobileTextColor, (white, malala) Make the hero text any the color on mobile.
 * buttons: (array) button objects to display below the content
 * leadContent:  (HTML string) Text content to appear in the first column of the lead.
 * leadSecondaryContent:  (HTML string) Text content to appear in the first column of the lead.
 * contentWidth: (narrow, full) How wide should the lead content area be?
 * leadButtons: (array) button objects to display under the lead content, or under the lead secondary content if it exists.
 * leadTextAlignment: (left, center, right) The text alignment for the lead.
 * spaceBeforeContent: (css margin value string) example: 150px
 * spaceBeforeMobileContent: (css margin value string) example: 150px
 * image: The background image object
 * mobileImage: The mobile background image object
 * accents: (string) The component key to use for the image accent component. Example: white-accents-1
 * className: (string) CSS class to apply to the hero)
 * leadClassName: (string) CSS class to apply to the lead
 * lazy: (bool:false) Should the images lazy load?
 * size: (full-screen, normal) Should the hero take up the entire screen?
 * nav: array of ({url: string, name: string},...)
 * pattern: (string) URL to pattern image
 * videoUrl: (string) URL to a background video
 * alert: (Alert object) show an alert at the top of the hero
 */

class Hero extends React.Component {
    /**
     * Getters
     */

    constructor(props) {
        super(props);
        this.overlay = React.createRef();
        this.state = {
            mounted: false,
        };
    }

    /**
     * The classnames to add to the body class
     * @returns {string}
     */
    get contentBodyClassName() {
        let {contentWidth} = this.props;
        const {mounted} = this.state;

        contentWidth = contentWidth.toLowerCase();
        const classNames = {
            hero__content__body: true,
            "display-none": !mounted,
            "width-100 margin-x-auto padding-x-sm-16": contentWidth === "full",
            "border-box": true,
        };

        return classnames(classNames);
    }

    /**
     * The classnames to add to the entire hero
     * @returns {string}
     */
    get classNames() {
        const {
            className,
            textAlignment,
            textColor,
            mobileTextColor,
            textPlacement,
            textShadow,
            size,
        } = this.props;
        const classNames = {
            hero: true,
            "has-overlay": this.hasOverlay,
            "full-screen": this.isFullScreen,
            "text-alignment-left": textAlignment.toLowerCase() === "left",
            "text-alignment-center": textAlignment.toLowerCase() === "center",
            "text-alignment-right": textAlignment.toLowerCase() === "right",
            "text-color-sm-malala": textColor.toLowerCase() === "malala",
            "text-color-sm-white": textColor.toLowerCase() === "white",
            "text-color-malala": mobileTextColor.toLowerCase() === "malala",
            "text-color-white": mobileTextColor.toLowerCase() === "white",
            "text-placement-middle":
                textPlacement.toLowerCase() === "middle" || !textPlacement,
            "text-placement-bottom": textPlacement.toLowerCase() === "bottom",
            "text-placement-right": textPlacement.toLowerCase() === "right",
            "text-shadow": textShadow,
        };

        if (className) {
            classNames[className] = true;
        }
        return classnames(classNames);
    }

    /**
     * Does the lead have two columns?
     * @returns {boolean}
     */
    get leadHasTwoColumns() {
        const {leadSecondaryContent} = this.props;
        return !!leadSecondaryContent;
    }

    /**
     * Does the hero have an overlay
     * @returns {boolean}
     */
    get hasOverlay() {
        const {leadContent, leadPlacement} = this.props;
        return leadContent && kebabCase(leadPlacement) === "overlay";
    }

    /**
     * Does the hero have content below it?
     * @returns {boolean}
     */
    get hasBelow() {
        const {leadContent, leadPlacement, heading, headingPlacement, content} = this.props;
        return (
            (leadContent && kebabCase(leadPlacement) === "below-hero") ||
            (!leadContent && (heading && headingPlacement === 'below') && content)
        );
    }

    /**
     * Is the hero full screen?
     * @returns {boolean}
     */
    get isFullScreen() {
        const {size} = this.props;

        return kebabCase(size) === "full-screen";
    }

    /**
     * Will change depending on hero size
     * @returns {number}
     */
    get imageWidth() {
        if (this.isFullScreen) {
            return 4493;
        }

        return 4000;
    }

    /**
     * Will change depending on hero size
     * @returns {number}
     */
    get imageHeight() {
        if (this.isFullScreen) {
            return 2658;
        }

        return 1600;
    }

    /**\
     * Set some CSS variables to let other components know how tall things are
     */
    componentDidMount() {
        if (!ExecutionEnvironment.canUseDOM) {
            return;
        }

        this.reportOverlayHeight();
        this.reportContentSpace();

        if (this.isFullScreen) {
            document.body.classList.add("hero-is-full-screen");
        }

        this.setState({
            mounted: true,
        });

        PubSub.subscribe("RESIZE", () => this.reportOverlayHeight());
    }

    /**
     * Set some CSS variables to let other components know how tall things are
     */
    componentDidUpdate() {
        this.reportOverlayHeight();
        this.reportContentSpace();
        setTimeout(this.reportOverlayHeight.bind(this), 500);
        setTimeout(this.reportOverlayHeight.bind(this), 1000);
        setTimeout(this.reportOverlayHeight.bind(this), 2000);
        setTimeout(this.reportOverlayHeight.bind(this), 5000);
    }

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        const {backgroundColor, className} = this.props;

        return (
            <div
                className={classnames(
                    "hero__outer",
                    className ? className + "__outer" : null,
                    `bg-${backgroundColor.toLowerCase()}`,
                    {"full-screen": this.isFullScreen}
                )}>
                <section className={this.classNames}>
                    <div className="hero__wrapper">
                        {this.renderAlert()}
                        {this.renderNav()}
                        <div className={"hero__body__container"}>
                            <div
                                className={classnames({
                                    hero__body: true,
                                    "padding-bottom-overlay": this.hasOverlay,
                                })}>
                                {this.renderAccents()}
                                {this.renderImage()}
                                {this.renderContent()}
                            </div>
                        </div>
                        {this.renderPatternBar()}
                    </div>
                    {this.renderOverlay()}
                </section>

                {this.renderBelow()}
            </div>
        );
    }

    /**
     * Render the hero nav.
     * @returns {*}
     */
    renderNav() {
        const {nav} = this.props;

        if (!nav) return;

        return (
            <ul className="hero__nav text-center">
                {nav.map((item, index) => (
                    <li key={index} className={classnames({current: item.current})}>
                        <a href={item.url}>
                            <h5 className="text-white">{item.name}</h5>
                        </a>
                    </li>
                ))}
            </ul>
        );
    }

    /**
     * Render the alert
     */
    renderAlert() {
        const {alert} = this.props;

        if (!alert) return;

        return <Alert {...alert} />;
    }

    /**
     * Render the content.
     * @returns {*}
     */
    renderContent() {
        const {heading, content, buttons, headingPlacement} = this.props;
        if (!heading && !content && !buttons) return;

        if (headingPlacement === 'left') {
            return (
                <div className={classnames({container: true})}>
                    <div className="row">
                        <div className={"column-md-half margin-bottom-2"}>
                            {this.renderHeading()}
                        </div>
                        <div className="hero__content column-md-half">
                            {headingPlacement === 'above' ? this.renderHeading() : ""}
                            {this.renderBody()}
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classnames({container: true})}>
                <div className="hero__content">
                    {headingPlacement === 'above' ? this.renderHeading() : ""}
                    {this.renderBody()}
                    {this.renderButtons()}
                </div>
            </div>
        );
    }

    /**
     * Render the heading.
     * @returns {*}
     */
    renderHeading() {
        const {heading} = this.props;

        if (!heading) return;

        return (
            <div className="hero__heading translate">
                <h1>
                    <Html content={heading.replace(/(?:\r\n|\r|\n)/g, "<br>")}/>
                </h1>
            </div>
        );
    }

    /**
     * Render the body fo the hero.
     * @returns {*}
     */
    renderBody() {
        const {content, signature} = this.props;
        if (!content) return;
        return (
            <div className={classnames(this.contentBodyClassName)}>
                <SSRHtml content={content}/>
                {signature ? <MalalaSignature/> : ""}
            </div>
        );
    }

    /**
     * Render the content just below the hero.
     * @returns {*}
     */
    renderBelow() {
        if (!this.hasBelow) {
            return;
        }

        return (
            <section className={"hero__below-outer padding-top-5 padding-bottom-5"}>
                <div className={"container"}>
                    <div className="hero__below padding-left-xxxl-10 padding-right-xxxl-10">
                        {this.renderLead()}
                    </div>
                </div>
            </section>
        );
    }

    /**
     * Render the hero buttons.
     * @returns {*}
     */
    renderButtons() {
        const {buttons} = this.props;
        if (!buttons) return;
        return (
            <div className="margin-top-7">
                <Buttons buttons={buttons}/>
            </div>
        );
    }

    /**
     * Render the overlay if we have one.
     * @returns {*}
     */
    renderOverlay() {
        if (!this.hasOverlay) {
            return;
        }

        return (
            <div className={classnames("hero__overlay")}>
                <ReactResizeDetector
                    refreshMode={"debounce"}
                    refreshRate={500}
                    handleHeight
                    handleWidth
                    onResize={this.reportOverlayHeight.bind(this)}>
                    <div className="hero__overlay__wrapper" ref={this.overlay}>
                        <div className={"container"}>{this.renderLead()}</div>
                    </div>
                </ReactResizeDetector>
            </div>
        );
    }

    /**
     * Render the lead. Can go in the overlay or just below the hero.
     * @returns {*}
     */
    renderLead() {
        let {leadClassName, leadTextAlignment, leadContentWidth, headingPlacement} = this.props;

        return (
            <div
                className={classnames(
                    "hero__lead",
                    {"hero__lead--has-columns": this.leadSecondaryContent},
                    {"text-left": leadTextAlignment.toLowerCase() === "left"},
                    {"text-center": leadTextAlignment.toLowerCase() === "center"},
                    {"text-right": leadTextAlignment.toLowerCase() === "right"},
                    {"hero__lead--narrow": leadContentWidth.toLowerCase() === "narrow"},
                    "translate",
                    leadClassName
                )}>
                {this.renderAudimaButton()}
                {this.renderTranslationWidget()}

                {headingPlacement === 'lead' ? this.renderHeading() : ''}

                <div className={"row gutter-wide flex-justify-center"}>
                    {this.renderLeadContent()}
                    {this.renderLeadSecondaryContent()}
                </div>
            </div>
        );
    }

    renderAudimaButton() {
        const {audima} = this.props;
        if (!audima) return;
        return (
            <div className={"hero__audima"}>
                <AudimaIcon/>
            </div>
        );
    }

    /**
     * Render the translation widget
     */
    renderTranslationWidget() {
        let {translationWidget} = this.props;

        if (!translationWidget) {
            return;
        }

        return (
            <div className={"hero__translation-widget"}>
                <TranslationWidget/>
            </div>
        );
    }

    /**
     * Render the lead buttons.
     * @returns {*}
     */
    renderLeadButtons() {
        const {leadButtons} = this.props;
        if (!leadButtons) return;

        return (
            <div className={"margin-top-6"}>
                <Buttons buttons={leadButtons}/>
            </div>
        );
    }

    /**
     * Render the lead content.
     * @returns {*}
     */
    renderLeadContent() {
        const {leadContent} = this.props;
        return (
            <div
                className={classnames({
                    hero__lead__content: true,
                    column: true,
                    "width-100": true,
                    "column-lg-half": this.leadHasTwoColumns,
                })}>
                <SSRHtml content={leadContent}/>
                {!this.leadHasTwoColumns && this.renderLeadButtons()}
            </div>
        );
    }

    /**
     * Render the second lead column.
     * @returns {*}
     */
    renderLeadSecondaryContent() {
        const {leadSecondaryContent} = this.props;
        if (!this.leadHasTwoColumns) return;

        return (
            <div
                className={classnames({
                    hero__lead__secondary__content: true,
                    column: true,
                    "column-lg-half": true,
                    "width-100": true,
                })}>
                <div className="hero__lead__secondary__content__body">
                    <SSRHtml content={leadSecondaryContent}/>
                </div>
                {this.renderLeadButtons()}
            </div>
        );
    }

    /**
     * Render the image.
     * @returns {*}
     */
    renderImage() {
        const {
            rawImage,
            image,
            mobileImage,
            tabletImage,
            imageFocalPoint,
            lazy,
            videoUrl,
            crop,
        } = this.props;

        if (rawImage) {
            return <Html content={rawImage}/>;
        }

        if (!image) return;

        const Image = (
            <ContentfulImage
                className={"hero__image"}
                image={image}
                mobileImage={mobileImage}
                tabletImage={tabletImage}
                lazy={lazy}
                focalPoint={imageFocalPoint}
                position={imageFocalPoint === "top" ? "top" : "center"}
                width={crop ? this.imageWidth : null}
                widthMobile={crop ? 1242 : null}
                widthTablet={crop ? 2304 : null}
                height={crop ? this.imageHeight : null}
                heightMobile={crop ? 1563 : null}
                heightTablet={crop ? 1299 : null}
            />
        );

        if (!videoUrl) {
            return Image;
        }

        return (
            <NoSSR>
                <BackgroundVideo url={videoUrl}>{Image}</BackgroundVideo>
            </NoSSR>
        );
    }

    /**
     * Render the accents.
     * @returns {*}
     */
    renderAccents() {
        const {accents} = this.props;

        if (!accents) return;

        return (
            <div className="hero__accents">
                {typeof accents === "string" ? (
                    <Accents slug={accents}/>
                ) : (
                    <Accents accents={accents}/>
                )}
            </div>
        );
    }

    /**
     * Render the pattern bar.
     * @returns {*}
     */
    renderPatternBar() {
        const {pattern} = this.props;

        if (!pattern) return;

        return <PatternBar image={pattern}></PatternBar>;
    }

    /**
     * Set a CSS variable for the overlay height.
     */
    reportOverlayHeight() {
        if (!ExecutionEnvironment.canUseDOM) {
            return;
        }
        if (!this.hasOverlay) {
            return;
        }

        let html = document.getElementsByTagName("html")[0];
        let overlayHeight = this.overlay.current.clientHeight + "px";
        html.style.setProperty("--overlay-height", overlayHeight);
    }

    /**
     * Set a CSS variable for the space before the content in the hero.
     */
    reportContentSpace() {
        const {spaceBeforeContent, spaceBeforeMobileContent} = this.props;
        let html = document.getElementsByTagName("html")[0];

        if (spaceBeforeContent) {
            html.style.setProperty("--hero-content-space", spaceBeforeContent);
        }

        if (spaceBeforeMobileContent) {
            html.style.setProperty(
                "--hero-mobile-content-space",
                spaceBeforeMobileContent
            );
        }
    }
}

Hero.defaultProps = {
    lazy: false,
    audima: false,
    size: "normal",
    textAlignment: "left",
    textColor: "white",
    mobileTextColor: "white",
    leadTextAlignment: "center",
    contentWidth: "half",
    backgroundColor: "white",
    imageFocalPoint: "faces",
    textPlacement: "middle",
    leadPlacement: "overlay",
    headingPlacement: "above",
    leadContentWidth: "full",
    textShadow: false,
    crop: true,
    translationWidget: false,
};

Hero.propTypes = {
    audima: PropTypes.bool,
    size: PropTypes.string,
    heading: PropTypes.string,
    headingPlacement: PropTypes.string,
    content: PropTypes.string,
    contentWidth: PropTypes.string,
    textAlignment: PropTypes.string,
    textColor: PropTypes.string,
    mobileTextColor: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.object),
    leadContent: PropTypes.string,
    leadSecondaryContent: PropTypes.string,
    leadContentWidth: PropTypes.string,
    leadButtons: PropTypes.arrayOf(PropTypes.object),
    leadTextAlignment: PropTypes.string,
    spaceBeforeContent: PropTypes.string,
    spaceBeforeMobileContent: PropTypes.string,
    image: PropTypes.object,
    mobileImage: PropTypes.object,
    className: PropTypes.string,
    leadClassName: PropTypes.string,
    lazy: PropTypes.bool.isRequired,
    nav: PropTypes.arrayOf(PropTypes.object),
    backgroundColor: PropTypes.string,
    imageFocalPoint: PropTypes.string,
    videoUrl: PropTypes.string,
    textShadow: PropTypes.bool,
    crop: PropTypes.bool,
    translationWidget: PropTypes.bool,
};

export default Hero;
