import React from "react";
import HeaderSearch from "./HeaderSearch";
import {imagePath} from "../helpers";
import classnames from "classnames";
import Submenu from "./Submenu";
import URI from "urijs";
import Link from "./Link";
import ContentfulImage from "./ContentfulImage";
import PubSub from "pubsub-js";
import RichText from "./RichText";
import ExecutionEnvironment from "exenv";
import {isExternalUrl} from "../helpers";
import {MENU_OPENED, MENU_CLOSED} from "../channels";
import ReactDOM from "react-dom";

/**
 * The main menu component
 */
export default class Menu extends React.Component {
    /**
     * Creates a new instance of the constructor.
     *
     * @param {Object} props - The props object to be passed to the constructor.
     * @param {boolean} props.expanded - The initial value for the `expanded` state.
     *
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.search = React.createRef();
        this.state = {expanded: props.expanded};
    }

    /**
     * Returns the DOM node associated with the component instance.
     *
     * @returns {Element} The DOM node representing the component instance.
     */
    get el() {
        return ReactDOM.findDOMNode(this);
    }

    /**
     * Method to toggle between two different icons based on the current state.
     * @returns {string} - The path to the icon file.
     */
    get toggleIcon() {
        const {expanded} = this.state;
        return expanded ? "ui/ui-close.svg" : "ui/ui-hamburger.svg";
    }

    /**
     * Adds event listeners for mouseenter and mouseleave to the component element.
     * When mouse enters the element, sends a message to the parent window to open the menu.
     * When mouse leaves the element, sends a message to the parent window to close the menu,
     * if the menu is not already open.
     *
     * @method componentDidMount
     * @return {void}
     */
    componentDidMount() {
        this.el.addEventListener("mouseenter", () => {
            window.parent.postMessage({open: true}, "*");
        });
        this.el.addEventListener("mouseleave", () => {
            if (!document.body.classList.contains("menu--open")) {
                window.parent.postMessage({open: false}, "*");
            }
        });
    }

    /**
     * Toggles the state of the component between expanded and closed.
     *
     * @return {void}
     */
    toggle() {
        const {expanded} = this.state;

        if (expanded) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Expands the menu, adds "menu--open" class to the body element,
     * posts a message to the parent window to inform it that the menu is open,
     * and publishes a menu opened event using PubSub.
     *
     * @return {void}
     */
    open() {
        if (!ExecutionEnvironment.canUseDOM) {
            return;
        }
        this.setState({expanded: true});
        document.body.classList.add("menu--open");

        //The header can be embedded in an iframe
        window.parent.postMessage({open: true}, "*");

        PubSub.publish(MENU_OPENED);
    }

    /**
     * Closes the menu by setting the state to expanded = false,
     * removing the "menu--open" class from the body element,
     * sending a postMessage to the parent window to signal the menu closed,
     * and publishing a MENU_CLOSED event using PubSub.
     *
     * @function close
     * @memberof SomeClass
     * @returns {undefined}
     */
    close() {
        this.setState({expanded: false});
        document.body.classList.remove("menu--open");

        //The header can be embedded in an iframe
        window.parent.postMessage({open: false}, "*");

        PubSub.publish(MENU_CLOSED);
    }

    /**
     * Checks if the given item is the currently selected item.
     *
     * @param {Object} item - The item to check.
     * @param {string} item.link - The URL link of the item.
     * @param {Object} item.submenu - The submenu of the item.
     * @param {boolean} item.showSubmenu - Indicates whether to show the submenu.
     * @param {string} currentUrl - The current URL to compare with the item's link.
     * @returns {boolean} - Returns true if the item is the currently selected item, otherwise false.
     */
    isCurrentItem(item) {
        const {link, submenu, showSubmenu} = item;
        const {currentUrl} = this.props;

        let result = currentUrl === new URI(link).pathname();

        if (!submenu) {
            return result;
        }

        if (!result && showSubmenu) {
            result = [submenu.menu1, submenu.menu2].some((menu) => {
                if (!menu) {
                    return false;
                }
                return menu.items.some((item) => {
                    if (isExternalUrl(item.link)) {
                        return false;
                    }
                    return currentUrl === new URI(item.link).pathname();
                });
            });
        }

        return result;
    }

    /**
     * Render the items with a search header.
     *
     * @return {Array} An array of elements representing the items with a search header.
     */
    renderItems() {
        const {items} = this.props;

        let els = items.map(this.renderItem.bind(this));

        els.push(
            <li
                key="search"
                className="header__search__container no-hover flex-stretch flex">
                <HeaderSearch ref={this.search}/>
            </li>
        );

        return els;
    }

    /**
     * Renders a navigation menu item with optional submenu.
     * @param {Object} item - The item to be rendered.
     * @param {string} item.link - The link URL.
     * @param {boolean} item.showSubmenu - Whether to show the submenu or not.
     * @param {Object} item.submenu - The submenu options.
     * @param {string} item.label - The label text.
     * @param {boolean} item.blank - Whether to open the link in a new tab or not.
     * @return {JSX.Element} The rendered navigation menu item.
     */
    renderItem(item) {
        const {link, showSubmenu, submenu, label, blank} = item;
        return (
            <li
                key={label}
                aria-haspopup={showSubmenu}
                className={classnames(
                    {current: this.isCurrentItem(item)},
                    "header__nav-menu__item",
                    {"header__nav-menu__item--has-submenu": showSubmenu},
                    "flex",
                    "flex-stretch"
                )}>
                <Link
                    href={link}
                    target={blank ? "_blank" : "_self"}
                    title={label}
                    icon={false}
                    className={classnames(
                        "flex",
                        "header__nav-menu__link",
                        "flex-align-items-center"
                    )}>
                    {label}
                </Link>
                {showSubmenu ? <Submenu {...submenu} /> : ""}
            </li>
        );
    }

    /**
     * Renders a toggle button with an icon.
     *
     * @returns {React.Component} - A React component representing the toggle button.
     */
    renderToggle() {
        return (
            <div
                className="header__nav-toggle cursor-pointer flex flex-align-items-center display-lg-none"
                onClick={this.toggle.bind(this)}>
                <img
                    src={imagePath(this.toggleIcon)}
                    alt="Menu"
                    width={25}
                    height={25}
                />
            </div>
        );
    }

    /**
     * Renders the mobile menu content.
     * Returns a JSX element containing the mobile menu content.
     * @returns {JSX.Element} The rendered mobile menu content.
     */
    renderMobileContent() {
        const {mobileMenuContent, mobileMenuImage, mobileMenuLink} = this.props;

        return (
            <div className={"menu__mobile-content display-lg-none"}>
                {mobileMenuImage ? <ContentfulImage image={mobileMenuImage}/> : ""}
                <div className={"menu__mobile-content__content"}>
                    {mobileMenuLink ? (
                        <Link
                            {...mobileMenuLink.items[0]}
                            className={classnames("menu__mobile-content__link")}
                        />
                    ) : (
                        ""
                    )}
                    {mobileMenuContent ? <RichText content={mobileMenuContent}/> : ""}
                </div>
            </div>
        );
    }

    /**
     * Renders the menu component.
     *
     * @return {JSX.Element} The rendered menu component.
     */
    render() {
        const {expanded} = this.state;
        const {children} = this.props;

        return (
            <nav className="menu flex-grow-0 flex-grow-lg-1 flex flex-row">
                <div
                    className={classnames(
                        "header__nav display-lg-flex flex-align-right flex-justify-end flex-grow-1",
                        {"header__nav--expanded": expanded}
                    )}>
                    <div className="header__nav-menu flex position-relative">
                        <ul
                            className={classnames(
                                "flex",
                                "flex-column",
                                "flex-no-wrap",
                                "flex-align-start",
                                "flex-lg-row",
                                "flex-stretch"
                            )}>
                            {this.renderItems()}
                        </ul>
                        {this.renderMobileContent()}
                    </div>
                </div>

                {children}

                {this.renderToggle()}
            </nav>
        );
    }
}

Menu.defaultProps = {
    expanded: false,
};
