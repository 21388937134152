import React from 'react';

/**
 * SSR Dummy
 * ---------
 *
 * An empty component for use when another component can't render in SSR. Example:
 *
 * import SSRDummy from './SsrDummy'
 * const ReactSelect = ExecutionEnvironment.canUseDOM ? require('react-select').default : Dummy
 */
export default class SsrDummy extends React.Component {

}