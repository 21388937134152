const rotateWords = (el) => {
    const cycleTimeout = 1000 //The length of time before fading out the current word
    const removeTimeout = 1000 //The length of time after fading out before adding the next word

    const text = el.innerText
    if (!text) {
        return
    }
    el.innerHTML = ""
    const strings = text.split(',').map(string => string.trim())

    el.parentElement.style.setProperty('--word-num', 1)
    el.style.display = 'inline'

    let i = 0;

    /**
     * Cycle through the strings
     */
    const cycleText = () => {
        el.style.transition = `opacity 0ms ease-in-out`
        el.parentElement.style.setProperty('--word-num', i)
        el.style.opacity = 1
        el.innerHTML = strings[i]
        i = ++i % strings.length
        setTimeout(removeText, cycleTimeout)
    };

    /**
     * Fade out a string
     */
    const removeText = () => {
        el.style.transition = `opacity ${removeTimeout}ms ease-in-out`
        el.style.opacity = 0
        setTimeout(cycleText, removeTimeout)
    }

    cycleText()
}

export default rotateWords