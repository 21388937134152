import React from 'react';
import Html from './Html'
import classnames from 'classnames'
import ContentfulImage from "./ContentfulImage";
import {imagePath} from "../helpers";
import Image from "./Image";
import Link from './Link'
import PubSub from 'pubsub-js'
import {SUBMENU_OPENED, SUBMENU_CLOSED, MENU_CLOSED} from "../channels";
import uniqid from 'uniqid'
import ReactDOM from 'react-dom';

/**
 * Submenu
 * -------
 *
 * accentSmall: (Contentful Image Object)
 * accentMedium: (Contentful Image Object)
 * accentLarge: (Contentful Image Object)
 * accentExtraLarge: (Contentful Image Object)
 * leftContent: (HTML)
 * leftLink: (Array of Contentful Link Objects)
 * menu1: (Array of contentful links). The first column of links.
 * menu1Heading: (string). The heading for the first menu.
 * menu2: Array of contentful links. The second column of links.
 * menu2Heading: (string). The heading for the second menu.
 * rightContent: (HTML)
 * rightLink: (Array of Contentful Link Objects)
 * rightMobileHeading: (string) Heading for the right section. Only appears on mobile.
 */
export default class Submenu extends React.Component {

    /**
     * A random string used to differentiate between submenus
     */
    key

    constructor(props) {
        super(props);

        //Initial state
        this.state = {expanded: !!props.expanded};

        //Add a unique key
        this.key = uniqid()

        //Listen for opens/closes so we can automatically close when the menu opens/closes
        PubSub.subscribe(MENU_CLOSED, this.close.bind(this))
        PubSub.subscribe(SUBMENU_OPENED, (channel, submenu) => {

            //Check and make sure this component isn't the one that was opened. If it was, we don't want to close it.
            if (submenu.key === this.key) {
                return
            }

            this.close()
        })
    }

    /**
     * Plus or minus icon svg path
     * @returns {string}
     */
    get toggleIcon() {
        const {expanded} = this.state
        return expanded ? 'ui/ui-minus.svg' : 'ui/ui-plus.svg'
    }

    get el() {
        return ReactDOM.findDOMNode(this)
    }

    /**
     * Main render function.
     * @returns {*}
     */
    render() {
        const {expanded} = this.state

        return <div className="submenu__wrapper">
            <div className={classnames("submenu", {'submenu--expanded': expanded})}>
                <div className={"container submenu__container"}>
                    <div className={"display-lg-flex"}>
                        {this.renderLeft()}
                        {this.renderMenus()}
                        {this.renderRight()}
                    </div>
                    {this.renderAccents()}
                </div>
            </div>
            {this.renderToggle()}
        </div>
    }

    /**
     * Render the left image and content.
     * @returns {*}
     */
    renderLeft() {
        let {leftLink, leftContent} = this.props

        //The link is the only one in an array, so we should just grab the first one.
        if (leftLink) {
            leftLink = leftLink.items[0]
        }

        //If no link or content, don't render.
        if (!leftLink && !leftContent) {
            return
        }

        return <div className={"submenu__left"}>
            {leftLink ? <Link className={"submenu__header"} {...leftLink} /> : ''}
            {leftContent ? <Html content={leftContent}/> : ''}
        </div>
    }

    /**
     * Render the menu columns.
     * @returns {*}
     */
    renderMenus() {
        const {menu1, menu1Heading, menu2, menu2Heading} = this.props

        return <div className="submenu__menus flex-grow-1">
            <div className={"display-flex flex-column flex-md-row"}>
                {this.renderMenu(menu1Heading, menu1)}
                {this.renderMenu(menu2Heading, menu2)}
            </div>
        </div>
    }

    /**
     * Render a menu
     *
     * @param heading
     * @param menu
     * @returns {*}
     */
    renderMenu(heading, menu) {
        if (!menu) {
            return
        }
        return <div className={"submenu__menu__container"}>
            <div className={"submenu__menu__label"}>
                {heading}
            </div>
            <ul className={"submenu__menu"}>
                {this.renderItems(menu.items)}
            </ul>
        </div>
    }

    /**
     * Render a menu item.
     *
     * @param link
     * @returns {*}
     */
    renderItem(link) {
        return <li key={link.label}
                   className={classnames({'current': this.isCurrentItem(link)}, "submenu__item")}>
            <Link {...link}
                  className={classnames('submenu__item__link')}/>
        </li>
    }

    /**
     * Render an array of menu items.
     * @param items
     * @returns {*}
     */
    renderItems(items) {
        return items.map(this.renderItem.bind(this));
    }

    /**
     * Render the right image and content.
     * @returns {*}
     */
    renderRight() {
        let {rightLink, rightContent, showMobileRightContent} = this.props

        //The link is the only one in an array, so we should just grab the first one.
        if (rightLink) {
            rightLink = rightLink.items[0]
        }

        //If no link or content, don't render.
        if (!rightLink && !rightContent) {
            return
        }

        return <div
            className={classnames("submenu__right", {'submenu__right--has-mobile-content': showMobileRightContent})}>
            {!showMobileRightContent && rightLink ? <Link
                className={classnames('submenu__item__link display-lg-none margin-bottom-2 display-block')} {...rightLink} /> : ''}
            {this.renderRightImage()}
            {rightLink ? <Link
                className={classnames("submenu__header__lg", 'display-lg-block', {'display-none': !showMobileRightContent})} {...rightLink} /> : ''}
            {rightContent ? <div className={classnames("display-lg-block", {'display-none': !showMobileRightContent})}>
                <Html content={rightContent}/>
            </div> : ''}
        </div>
    }

    /**
     * Render the right image.
     * @returns {*}
     */
    renderRightImage() {
        let {rightLink, rightImage, rightImageMobile} = this.props

        if (!rightImageMobile) {
            rightImageMobile = rightImage
        }

        if (rightLink) {

            //The link is the only one in an array, so we should just grab the first one.
            rightLink = rightLink.items[0]

            return <Link {...rightLink} icon={false}>
                <ContentfulImage image={rightImage} mobileImage={rightImageMobile}
                                 className={"submenu__image__image width-full"}/>
            </Link>
        }

        return <ContentfulImage image={rightImage} mobileImage={rightImageMobile}
                                className={"submenu__image__image width-full"}/>
    }

    /**
     * Render the accents.
     * @returns {[]}
     */
    renderAccents() {
        const {accentSmall, accentMedium, accentLarge, accentExtraLarge, accentJumbo} = this.props

        let accents = []

        if (accentSmall) {
            accents.push((
                <img
                    src={accentSmall.src}
                    className={'accent accent-small'}
                    key={'accent-small'}
                    width={47}
                    height={47}
                    alt=""
                />
            ))
        }

        if (accentMedium) {
            accents.push((
                <img
                    src={accentMedium.src}
                    className={'accent accent-medium'}
                    key={'accent-medium'}
                    width={47}
                    height={47}
                    alt=""
                />
            ))
        }

        if (accentLarge) {
            accents.push((
                <img
                    src={accentLarge.src}
                    className={'accent accent-large'}
                    key={'accent-large'}
                    width={73}
                    height={73}
                    alt=""
                />
            ))
        }

        if (accentExtraLarge) {
            accents.push((
                <Image
                    src={accentExtraLarge.src}
                    className={'accent accent-extra-large'}
                    key={'accent-extra-large'}
                    width={145}
                    height={145}
                    alt=""
                />
            ))
        }

        if (accentJumbo) {
            accents.push((
                <Image
                    src={accentJumbo.src}
                    className={'accent accent-jumbo'}
                    key={'accent-jumbo'}
                    width={250}
                    height={250}
                    alt=""
                />
            ))
        }

        return accents
    }

    /**
     * Render the toggle icon.
     * @returns {*}
     */
    renderToggle() {
        return <div className="submenu__item__toggle cursor-pointer flex align-items-center display-lg-none"
                    onClick={this.toggle.bind(this)}
        >
            <img
                src={imagePath(this.toggleIcon)}
                alt="Toggle"
                width={14}
            />
        </div>
    }

    /**
     * Toggle the menu opened or closed.
     */
    toggle() {
        const {expanded} = this.state
        if (expanded) {
            this.close()
        } else {
            this.open()
        }
    }

    /**
     * Open the menu
     */
    open() {
        this.setState({expanded: true});

        //Fire an event to let the other items know
        PubSub.publish(SUBMENU_OPENED, this)
    }

    /**
     * Close the menu
     */
    close() {
        this.setState({expanded: false});

        //Fire an event to let the other items know
        PubSub.publish(SUBMENU_CLOSED, this)
    }

    /**
     * Is the the current menu item?
     * @param link
     * @returns {boolean}
     */
    isCurrentItem({link}) {
        const {currentUrl} = this.props;

        let result = (currentUrl === link);

        return result
    }
}