import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames'

/**
 * Color Bar
 * /////////
 *
 * size: (string) short|tall Default: short
 * color: (object) Contentful Image Object
 */
export default class PatternBar extends React.Component {

    render() {
        const {color, size, className} = this.props;

        if (!color) {
            return ''
        }

        return (
            <div className={classnames('color-bar', `color-bar--${color}`, `pattern-bar--${size}`, className)} />
        );
    }
}

PatternBar.defaultProps = {
    size: 'short'
};

PatternBar.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string
};

