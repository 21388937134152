import React from 'react';
import ContentfulImage from './ContentfulImage'
import Image from './Image'
import Children from './Children'
import classnames from 'classnames'
import Flipbook from "./Flipbook";
import PropTypes from "prop-types";
import Buttons from "./Buttons";

export default class ImageFlipbook extends React.Component {

    get hasContent() {
        const {content} = this.props;

        return !!content
    }

    get hasImage() {
        const {image, imgURL} = this.props;

        return image || imgURL
    }

    get hasImages() {
        const {images} = this.props;

        return images && images.length
    }

    render() {
        let {
            reverse,
            mobileReverse,
            column,
            backgroundClass,
            className,
            imageConstrained,
            textConstrained,
            imageSize,
            imageY
        } = this.props;

        if (!this.hasContent || (!this.hasImage && !this.hasImages)) {
            reverse = false
        }

        if (mobileReverse === null) {
            mobileReverse = reverse
        }

        let columnAWidth = "50"
        let columnBWidth = imageSize

        switch (imageSize) {
            case '33':
                columnAWidth = '66'
                break;
            case '40':
                columnAWidth = '60'
                break;
            case '60':
                columnAWidth = '40'
                break;
            case '66':
                columnAWidth = '33'
                break;
        }

        return <Flipbook
            className={classnames(
                "image-flipbook",
                {"image-flipbook--no-image": !this.hasImage && !this.hasImages},
                {"image-flipbook--has-images": this.hasImages},
                {"image-flipbook--no-content": !this.hasContent},
                {'container': column},
                className
            )}
            columnA={this.hasContent ? () => this.renderContent() : false}
            columnAConstrained={textConstrained}
            columnAClassName={classnames(
                'image-flipbook__content',
                'image-flipbook__text-container',
                'image-flipbook__text_container',
                "translate",
                backgroundClass
            )}
            columnAWidth={columnAWidth}
            columnB={this.renderColumnB()}
            columnBConstrained={imageConstrained}
            columnBClassName={classnames(
                "image-flipbook__image_container image-flipbook__image-container position-relative",
                {'padding-right-page-gutter': !reverse && this.hasImages},
                {'padding-left-page-gutter': reverse && this.hasImages},
            )}
            columnBWidth={columnBWidth}
            reverse={reverse}
            mobileReverse={mobileReverse}
            column={column}
            style={{
                "--flipbook-image-y": `${imageY}px`
            }}
        />
    }

    renderColumnB() {
        if (!this.hasImage && !this.hasImages) {
            return false
        }

        if (this.hasImage) {
            return this.renderImage()
        } else {
            return this.renderImages()
        }
    }

    renderContent() {
        const {content, buttons, textPadding, afterContent, textAlignment, backgroundColor} = this.props;

        if (!this.hasContent) {
            return ""
        }

        return <div
            className={classnames("image-flipbook__text flex flex-justify-center ", `flex-align-${textAlignment}`, `image-flipbook__text--padding-${textPadding}`, `bg-${backgroundColor}`)}>
            <div>
                <Children content={content}/>
                <div className={"flex-grow-1"}>
                    <Buttons buttons={buttons}/>
                </div>
                <Children content={afterContent}/>
            </div>
        </div>
    }

    renderImage() {
        const {image, mobileImage, imgURL, reveal, afterImage, imageFit, backgroundColor, imagePadding} = this.props;

        if (!this.hasImage) {
            return ""
        }

        return <div
            className={classnames("image--flipbook__image-wrapper", `bg-${backgroundColor}`, `image-flipbook__image--padding-${imagePadding}`)}>
            {imgURL ? <Image
                src={imgURL}
                className={"image-flipbook__image"}
                reveal={reveal}
            /> : <ContentfulImage
                image={image}
                mobileImage={mobileImage}
                className={classnames("image-flipbook__image", imageFit === 'contain' ? "image-flipbook__image--contain" : null)}
                reveal={reveal}
                sizeByFilename={true}
            />}
            <Children content={afterImage}/>
        </div>
    }

    renderImages() {
        const {images, reveal, afterImage} = this.props;

        if (!this.hasImages) {
            return ""
        }

        return <div className={classnames("image--flipbook__images-wrapper")}>
            {
                images.map((image, idx) => <ContentfulImage
                    key={`flipbook-images-${idx}`}
                    image={image}
                    className={classnames("image-flipbook__image")}
                    reveal={reveal}
                    sizeByFilename={true}
                />)
            }

            <Children content={afterImage}/>
        </div>
    }
}

ImageFlipbook.propTypes = {
    column: PropTypes.bool, //Should the flipbook use flex-column to stack instead of be in a row
    reverse: PropTypes.bool, //Should the flipbook be row-reverse so the image is on teh left side?
    imageConstrained: PropTypes.bool, //Should the image align with the edge of the container instead of the edge of the viewport?
    textConstrained: PropTypes.bool, //Should the content align with the edge of the container instead of the edge of the viewport?
    textPadding: PropTypes.string, //small, medium, large
    buttons: PropTypes.array, //Contentful button object. Adds buttons below the content.,
    imageSize: PropTypes.string, //See widths.scss. The remaining width will be applied to the container.
    afterContent: PropTypes.any, //HTML or children for after the content. Normally used for accents.
    afterImage: PropTypes.any, //HTML or children for after the content. Normally used for accents.
    textAlignment: PropTypes.string, //Content flex justification
    imageFit: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageY: PropTypes.number
}

ImageFlipbook.defaultProps = {
    column: false,
    reverse: false,
    imageConstrained: false,
    textConstrained: true,
    textPadding: 'large',
    buttons: null,
    imageSize: '50',
    afterContent: '',
    afterImage: '',
    textAlignment: 'center',
    mobileReverse: null,
    imageFit: 'cover'
}