import ExecutionEnvironment from "exenv";
import {decode} from "html-entities";

export const ready = (callback) => {
    if (!ExecutionEnvironment.canUseDOM) {
        return;
    }
    if (document.readyState === "complete") {
        callback();
    } else {
        document.onreadystatechange = function () {
            if (document.readyState === "complete") {
                callback();
            }
        };
    }
};

export const html = function (html) {
    return {__html: decode(html)};
};

export const chunk = function (array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
};

export const ssrSafe = function (html) {
    if (typeof html !== "string") {
        return true;
    }
    return !(
        html.includes("<iframe") ||
        html.includes("<form") ||
        html.includes("data-widget-src") ||
        html.includes("give-lively-widget")
    );
};

export const imagePath = function (filename) {
    return require(`images/${filename}`);
};

export const accentPath = function (name) {
    return imagePath(`accents/${name}.svg`);
};

export const getStyle = function (oElm, strCssRule) {
    if (!ExecutionEnvironment.canUseDOM) {
        return;
    }
    var strValue = "";
    if (document.defaultView && document.defaultView.getComputedStyle) {
        strValue = document.defaultView
            .getComputedStyle(oElm, "")
            .getPropertyValue(strCssRule);
    } else if (oElm.currentStyle) {
        strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1) {
            return p1.toUpperCase();
        });
        strValue = oElm.currentStyle[strCssRule];
    }
    return strValue;
};

export const documentPosition = function (el) {
    if (!ExecutionEnvironment.canUseDOM) {
        return;
    }
    let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    let top = rect.top + scrollTop,
        left = rect.left + scrollLeft;

    let bottom = top + el.offsetHeight,
        right = left + el.offsetWidth;

    return {top, right, bottom, left};
};

export const calculateResize = function (
    srcWidth,
    srcHeight,
    maxWidth,
    maxHeight
) {
    let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return {width: srcWidth * ratio, height: srcHeight * ratio};
};

export const isInFrame = function () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return false;
    }
};

export const initWeglot = function () {
    if (!Weglot || Weglot.initialized) {
        return;
    }

    Weglot.initialize({
        api_key: _WG_KEY,
        cache: true,
        hide_switcher: true,
        dynamic: [".translate", "body"],
    });
};

export const updateQuery = (path) => {
    if (!ExecutionEnvironment.canUseDOM) {
        return;
    }
    window.history.pushState(null, document.title, path);
};

export const delay = (fn, ms) => {
    let timer = 0;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(fn.bind(this, ...args), ms || 0);
    };
};

export const truncate = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + "...";
};

export const words = (str, no_words) => {
    return str.split(" ").splice(0, no_words).join(" ") + "...";
};

export const filterObject = (object, callback) => {
    const entries = Object.entries(object);
    const keys = Object.keys(object);
    const filtered = entries.filter((value, key) => callback(value, keys[key]));
    return Object.fromEntries(filtered);
};

export const isOverflow = (element) => {
    if (!element) {
        return false;
    }
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
};

export const offsetToDocumentTop = (element) => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

export const isExternalUrl = (href) => {
    return !href.includes('www.malala.org')
        && !href.includes('https://malala.org')
        && !href.includes('https:/staging.malala.org')
        && !href.includes('https:/beta.malala.org')
        && (
            href.includes('http://')
            || href.includes('https://')
        )
}
