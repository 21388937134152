import React from "react";
import RichText from "./RichText";
import { Fragment } from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import classNames from "classnames";

const Blockquote = function ({ quote, cite }) {
  const quoteHtml = documentToHtmlString(quote);
  const needsQuotes = !quoteHtml.includes("“") || !quoteHtml.includes("”");

  return (
    <blockquote
      className={classNames("blockquote", {
        "blockquote--with-quotes": needsQuotes,
      })}>
      <div className={"blockquote__quote"}>
        <RichText content={quote} fragment={true} />
      </div>

      <RichText content={cite} Tag={"cite"} className={"blockquote__cite"} />
    </blockquote>
  );
};

export default Blockquote;
