import PropTypes from 'prop-types';
import React from 'react';

/**
 * For use in the tabs component
 */
export default class Tab extends React.Component {
    order
    constructor(props) {
        super(props);
        const { open } = props;

        this.state = {
            open : !!(open)
        }
    }

    open() {
        this.setState({
            open: true
        });
    }

    close() {
        this.setState({
            open: false
        });
    }

    openClass() {
        return (this.state.open) ? 'display-block' : 'display-none'
    }

    render() {
        const { children } = this.props;
        const className = 'tab ' + this.openClass()
        return (
            <article className={ className }>
                {children}
            </article>
        );
    }
}


Tab.defaultProps = {
    order: 1,
    open: false
}

Tab.propTypes = {
    order: PropTypes.number,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool
};

