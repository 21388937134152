import React from 'react'
import NewsroomPostCard from "./NewsroomPostCard";
import StaggerGrid from "../StaggerGrid";
import CardTypes from "./PostCards";

const NewsroomStaggerGrid = ({posts,  cardTypes= Object.keys(CardTypes)}) => {
    return (<StaggerGrid className={"width-full"}>
        {
            posts.map((post) => <NewsroomPostCard key={'post-card-' + post.id}
                                                  cover={false}
                                                  cardTypes={cardTypes}
                                                  {...post}
            />)
        }
    </StaggerGrid>)
}

export default NewsroomStaggerGrid