import classnames from "classnames";
import React from "react";
import {SwiperSlide} from "swiper/react";
import Swiper from '../Swiper'
import NoSsr from '../NoSSR'
import NewsroomPostCard from "./NewsroomPostCard";

const NewsroomPostCarousel = ({posts}) => {
    return (<NoSsr>
        <Swiper className={classnames(
            'post-carousel margin-lg-y-4',
        )}
                autoHeight={true}
                spaceBetween={50}
                slidesPerView={1.25}
                slidesPerGroup={1}
                loop={false}
                watchSlidesVisibility
                watchSlidesProgress
                navigation={false}
                pagination={{
                    clickable: true
                }}
                observer="true"
                observeSlideChildren={true}
                updateOnWindowResize={true}
                simulateTouch={true}
                longSwipes={true}
                breakpoints={{
                    992: {
                        navigation: true,
                        pagination: false,
                        slidesPerView: 3,
                    },
                    1561: {
                        slidesPerView: 4,
                    },
                }}
        >
            {posts.map(post => <SwiperSlide key={`post-carousel-${post.id}`}>
                <NewsroomPostCard {...post} />
            </SwiperSlide>)}
        </Swiper>
    </NoSsr>)
}

export default NewsroomPostCarousel