import React from 'react';
import Drawer from './Drawer'

export default class FAQ extends React.Component {

    render() {
        const {question, answer, anchor, expanded} = this.props;

        return (
            <Drawer className={"faq"}
                    expanded={expanded}
                    headerClassName={"faq__question"}
                    headerContentClassName={"faq__question-label"}
                    contentClassName={"faq__answer"}
                    contentInnerClassName={"faq__answer-inner"}
                    header={question}
                    content={answer}
                    anchor={anchor}
            />
        );
    }
}
