import React, {Fragment, useEffect, useState} from 'react'
import {filterByNames as filterLanguages} from "../languages"
import ExecutionEnvironment from "exenv";

const LanguageLinks = (props) => {
    const defaultUrl = ExecutionEnvironment.canUseDOM ? window.location : '/'
    let {languages: names = [], url = defaultUrl, current} = props

    const [languages, setLanguages] = useState([])

    useEffect(() => {
        setLanguages(filterLanguages(names).filter((language) => language.code !== current).map((language) => {
            const urlObject = new URL(url);
            urlObject.searchParams.set('lang', language.code);
            language.url = urlObject.toString()
            return language
        }))
    }, [names, url, current])

    if (!languages.length) {
        return ""
    }

    return <div className={"language-links margin-bottom-2"}>
        <span className={"label"}>Read in</span>  {languages.map(({code, url, name, nativeName}, idx) => <Fragment key={`lang-link-${code}`}>
        <a key={`language-link-${code}`}
           className={"button--link"}
           title={`Read in ${name}`}
           href={url}>
            {nativeName}
        </a>
        {
            idx + 1 < languages.length ? ", " : ''
        }
    </Fragment>)}
    </div>
}

export default LanguageLinks