/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//Import CSS
import "../stylesheets/application.scss";

//Import JS
import "@babel/polyfill";
import ReactOnRails from "react-on-rails";
import ExecutionEnvironment from "exenv";
import SwiperCore, { Navigation, Pagination } from "swiper";
import Plyr from "plyr";
import { ready } from "../helpers";

import Accents from "../components/Accents";
import BackgroundVideo from "../components/BackgroundVideo";
import BioHero from "../components/BioHero";
import Blockquote from "../components/Blockquote";
import Button from "../components/Button";
import Buttons from "../components/Buttons";
import HeaderSimple from "../components/HeaderSimple";
import FooterSimple from "../components/FooterSimple";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Card from "../components/Card";
import Cards from "../components/Cards";
import CardProfileTabs from "../components/CardProfileTabs";
import ChampionsSearch from "../components/ChampionsSearch";
import Chart from "../components/Chart";
import ColorBar from "../components/ColorBar";
import GalleryFlipbook from "../components/GalleryFlipbook";
import RichText from "../components/RichText";
import Hero from "../components/Hero";
import HeroImage from "../components/HeroImage";
import Html from "../components/Html";
import Image from "../components/Image";
import ImageCarousel from "../components/ImageCarousel";
import ContentfulImage from "../components/ContentfulImage";
import ImageFlipbook from "../components/ImageFlipbook";
import PatternHero from "../components/PatternHero";
import PatternBanner from "../components/PatternBanner";
import PatternBar from "../components/PatternBar";
import FAQ from "../components/FAQ";
import SearchResult from "../components/SearchResult";
import SectionHeader from "../components/SectionHeader";
import SwiperCards from "../components/SwiperCards";
import LoadReporter from "../components/LoadReporter";
import UpdatesCard from "../components/UpdatesCard";
import Video from "../components/Video";
import VideoFlipbook from "../components/VideoFlipbook";
import CountUp from "../components/CountUp";
import Popup from "../components/Popup";
import CountryPartners from "../components/CountryPartners";
import PhotoStack from "../components/PhotoStack";
import ShareIcons from "../components/ShareIcons";
import StickyQuotes from "../components/StickyQuotes";
import NewsroomInitSearch from "../components/newsroom/NewsroomInitSearch";
import NewsroomSearchBar from "../components/newsroom/NewsroomSearchBar";
import NewsroomOrderBar from "../components/newsroom/NewsroomOrderBar";
import NewsroomFeaturedPost from "../components/newsroom/NewsroomFeaturedPost";
import NewsroomPostCard from "../components/newsroom/NewsroomPostCard";
import NewsroomReportCard from "../components/newsroom/PostCards/ReportCard";
import NewsroomFeaturedPosts from "../components/newsroom/NewsroomFeaturedPosts";
import ScrollButton from "../components/ScrollButton";
import LinkCarousel from "../components/LinkCarousel";
import LanguageSelectLink from "../components/LanguageSelectLink";
import LanguageLinks from "../components/LanguageLinks";
import TranslationWidget from "../components/TranslationWidget";
import Link from "../components/Link";
import VideoGrid from "../components/VideoGrid";
import VideoCard from "../components/VideoCard";
import GiveLively from "../components/GiveLively";

import { FacebookIcon, TwitterIcon } from "react-share";
import NewsroomResults from "../components/newsroom/NewsroomResults";
import NewsroomReportResults from "../components/newsroom/NewsroomReportResults";
import NewsroomPosts from "../components/newsroom/NewsroomPosts";
import NewsroomStaggerGrid from "../components/newsroom/NewsroomStaggerGrid";
import NewsroomPostCarousel from "../components/newsroom/NewsroomPostCarousel";
import PdfCarousel from "../components/PdfCarousel";
import domHooks from "../domHooks";

if (ExecutionEnvironment.canUseDOM) {
  window.Plyr = Plyr;
  ready(() => {
    domHooks(document);
  });
}

SwiperCore.use([Navigation, Pagination]);

ReactOnRails.register({
  Accents,
  BackgroundVideo,
  Blockquote,
  BioHero,
  Button,
  Buttons,
  Header,
  HeaderSimple,
  Image,
  ContentfulImage,
  Footer,
  FooterSimple,
  Card,
  Cards,
  CardProfileTabs,
  ChampionsSearch,
  Chart,
  ColorBar,
  CountUp,
  GalleryFlipbook,
  GiveLively,
  Hero,
  HeroImage,
  Html,
  ImageCarousel,
  ImageFlipbook,
  LanguageLinks,
  LinkCarousel,
  PatternHero,
  PatternBanner,
  PatternBar,
  Popup,
  RichText,
  FAQ,
  ScrollButton,
  UpdatesCard,
  SearchResult,
  SectionHeader,
  SwiperCards,
  Video,
  LoadReporter,
  VideoFlipbook,
  CountryPartners,
  PhotoStack,
  FacebookIcon,
  TwitterIcon,
  ShareIcons,
  NewsroomInitSearch,
  NewsroomSearchBar,
  NewsroomOrderBar,
  NewsroomResults,
  NewsroomReportResults,
  NewsroomPosts,
  NewsroomFeaturedPost,
  NewsroomReportCard,
  NewsroomPostCard,
  NewsroomFeaturedPosts,
  NewsroomStaggerGrid,
  NewsroomPostCarousel,
  LanguageSelectLink,
  PdfCarousel,
  StickyQuotes,
  TranslationWidget,
  VideoGrid,
  VideoCard,
});
