import Axios from "axios";
import PubSub from "pubsub-js";
import {
    NEWSROOM_SEARCH,
    NEWSROOM_SEARCH_FAILURE,
    NEWSROOM_SEARCH_STARTED,
    NEWSROOM_SEARCH_SUCCESS,
    NEWSROOM_SEARCH_UPDATED,
    NEWSROOM_SEARCH_SUBMISSION_UPDATED,
} from "./constants";
import ExecutionEnvironment from "exenv";
import URI from "urijs";

/**
 * Event base state manager. We're using event-based state because the version of React on Rails in use doesn't support Redux (https://github.com/shakacode/react_on_rails/blob/master/docs/api/redux-store-api.md)
 */
export default {
    initialized: false,
    submission: {
        order: "desc",
        query: "",
        type: [],
        country: [],
        topic: [],
        page: 0,
        exclude: [],
        cacheKey: "",
    },
    lastSubmission: {
        order: "desc",
        query: "",
        type: [],
        country: [],
        topic: [],
        page: 0,
        exclude: [],
        cacheKey: "",
    },
    lastUrl: "",
    search: {},
    fetched: [],
    results: [],
    inProgress: false,
    endpoint: "relative",
    pageUrl: null,
    fetchedQuery: null,
    abortController: null,

    /**
     * The data to pass to components
     * @returns {{search: default.search, inProgress: default.inProgress, query: default.query, submission: default.submission, results: default.results, fetched: default.fetched, fetchedQuery: default.fetchedQuery}}
     */
    get data() {
        return Object.assign(
            {},
            {
                search: this.search,
                fetched: this.fetched,
                results: this.results,
                inProgress: this.inProgress,
                submission: this.submission,
                lastUrl: this.lastUrl,
            }
        );
    },

    /**
     * The current submission URL
     * @returns {string}
     */
    get url() {
        if (!ExecutionEnvironment.canUseDOM) {
            return this.endpoint;
        }

        if (this.endpoint === "relative") {
            const uri = new URI(this.initialUrl);
            uri.segment([this.path, "search"]);
            this.endpoint = uri.origin() + uri.path();
        }
        return this.endpoint;
    },

    /**
     * Get the query params for the search
     * @returns {{}}
     */
    get params() {
        const params = {};
        let filtering = false;

        if (this.submission.order && this.submission.order !== "desc") {
            params["o"] = this.submission.order;
        }
        if (this.submission.query) {
            filtering = true;
            params["q"] = this.submission.query;
        }
        if (this.submission.type.length > 0) {
            filtering = true;
            params["type"] = this.submission.type;
        }
        if (this.submission.country.length > 0) {
            filtering = true;
            params["country"] = this.submission.country;
        }
        if (this.submission.topic.length > 0) {
            filtering = true;
            params["topic"] = this.submission.topic;
        }
        if (this.submission.page > 0) {
            params["p"] = this.submission.page;
        }
        if (!filtering && this.submission.exclude.length > 0) {
            params["e"] = this.submission.exclude;
        }
        if (this.submission.cacheKey) {
            params["k"] = this.submission.cacheKey;
        }
        return params;
    },

    /**
     * Get the query params as URLSearchParams
     * @returns {URLSearchParams}
     */
    get urlSearchParams() {
        return new URLSearchParams(this.params);
    },

    /**
     * Get the form submit URL
     * @returns {string}
     */
    get action() {
        const url = new URL(this.url);
        url.search = this.urlSearchParams;
        return url.toString();
    },

    get container() {
        if (!ExecutionEnvironment.canUseDOM) {
            return;
        }
        return document.querySelector(".newsroom__search");
    },

    /**
     * New it up
     * @param results
     * @param search
     * @param endpoint
     * @param url
     * @param exclude
     * @param cacheKey
     */
    init(
        results = false,
        search = false,
        endpoint,
        url,
        exclude = [],
        cacheKey = ""
    ) {
        if (ExecutionEnvironment.canUseDOM && !window._NEWSROOM_SEARCH) {
            window._NEWSROOM_SEARCH = this;
        }

        const uri = URI(url);
        this.initialUrl = url;
        this.lastUrl = this.initialUrl;
        this.path = uri.path().endsWith("/") ? uri.path().slice(0, -1) : uri.path();
        this.initialized = true;
        this.search = search;
        this.results = results;
        this.endpoint = endpoint;
        this.submission.exclude = exclude;
        this.submission.cacheKey = cacheKey;

        this.queryToSubmission(uri.query());
        this.storeLastSubmission();

        if (this.search && this.lastSubmission.page > 0) {
            this.search.page = parseInt(this.lastSubmission.page);
        }

        PubSub.subscribe(NEWSROOM_SEARCH, () => {
            this.fetch();
        });
    },

    /**
     * Make the search request
     * @returns {Promise<void>}
     */
    async fetch(options = {}) {
        options = Object.assign(
            {
                scrollIntoView: false,
            },
            options
        );

        const url = this.action;
        const urlSearchParams = this.urlSearchParams;

        try {
            this.inProgress = true;
            this.publish(NEWSROOM_SEARCH_STARTED);
            this.updateDom();

            if (this.abortController) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();

            const response = await fetch(url, {
                signal: this.abortController.signal,
                cache: 'force-cache'
            });

            if (response.status !== 200) {
                this.inProgress = false;
                this.updateDom();
                this.publish(NEWSROOM_SEARCH_FAILURE);
                return;
            }

            const data = await response.json();

            this.inProgress = false;
            this.updateDom();

            if (urlSearchParams.get("p")) {
                //We're loading results for a new page
                data.results.forEach((result) => {
                    this.results.push(result);
                });
            } else {
                this.results = this.results = data.results;
            }

            this.storeLastSubmission();
            this.lastUrl = url;

            this.updateUrlFromSearchParams(urlSearchParams);

            this.fetchedQuery = urlSearchParams;
            this.search = data.search;
            this.abortController = null;
            this.publish(NEWSROOM_SEARCH_SUCCESS);
            this.updateDom();
            if (options.scrollIntoView) {
                this.scrollIntoView();
            }
        } catch (ex) {
            if (ex.message === "canceled") {
                this.inProgress = false;
                return;
            }

            this.abortController = null;
            this.lastUrl = "";
            console.log(ex);
            this.inProgress = false;
            this.publish(NEWSROOM_SEARCH_FAILURE);
            this.updateDom();
        }
    },

    updateUrlFromSearchParams(urlSearchParams) {
        const newUrl = new URL(window.location);
        urlSearchParams.delete("e");
        urlSearchParams.delete("k");
        newUrl.search = urlSearchParams.toString();
        history.pushState({}, window.location.title, newUrl.toString());
    },

    /**
     * Do whatever we have to do to the DOM
     */
    updateDom() {
        if (!this.container) {
            return;
        }

        if (this.inProgress) {
            this.container.classList.add("newsroom__search--searching");
        } else {
            this.container.classList.remove("newsroom__search--searching");
        }
    },

    scrollIntoView() {
        if (!this.container) {
            return;
        }

        this.container.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    },

    /**
     * Set a submission param by name
     * @param name
     * @param value
     * @param suppressEvent
     */
    setParam(name, value, suppressEvent = false) {
        switch (name) {
            case "type":
            case "country":
            case "topic":
                this.setArrayParam(name, value);
                break;
            default:
                this.setStringParam(name, value);
        }

        if (!suppressEvent) {
            this.publish(NEWSROOM_SEARCH_SUBMISSION_UPDATED);
        }
    },

    /**
     * Set a submission string param by name
     * @param name
     * @param value
     */
    setStringParam(name, value) {
        this.submission[name] = value;
    },

    /**
     * Set a submission array param by name
     * @param name
     * @param value
     */
    setArrayParam(name, value) {
        if (!value) {
            value = [];
        }
        if (typeof value === "string") {
            value = value.split(",");
        }
        this.submission[name] = value;
    },

    /**
     * Load submission data fromm a query string
     * @param query
     */
    queryToSubmission(query) {
        const params = URI.parseQuery(query);
        this.setParam("query", params.q ?? "", false);
        this.setParam("type", params.type ?? [], false);
        this.setParam("country", params.country ?? [], false);
        this.setParam("topic", params.topic ?? []);
        this.setParam("order", params.o ?? "desc");
        this.setParam("page", params.p ?? 0);
    },

    /**
     * Move the current submission to the last submission
     */
    storeLastSubmission: function () {
        this.lastSubmission = Object.assign({}, this.submission);
    },

    /**
     * Publish an event
     * @param EVENT
     */
    publish(EVENT) {
        PubSub.publish(EVENT, this);
        PubSub.publish(NEWSROOM_SEARCH_UPDATED, this);
    },
};
