import React from 'react';

/**
 * @deprecated
 */
export default class PatternBanner extends React.Component {
  render() {
    const { backgroundClass } = this.props;
    const sectionClass = `pattern-banner ${backgroundClass}`;

    return (
      <div className={sectionClass}>
      </div>
    );
  }
}
