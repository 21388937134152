import React from "react";
import PropTypes from "prop-types";
import { initWeglot } from "../helpers";
import LanguageSelect from "./LanguageSelect";
import ExecutionEnvironment from "exenv";
export default class WeglotSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    initWeglot();

    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");

    if (langParam) {
      this.setState({
        language: langParam,
      });
    }

    if (Weglot.initialized) {
      setTimeout(() => this.init(), 1);
    } else {
      Weglot.on("initialized", () => {
        this.init();
      });
    }
  }

  init() {
    const { language } = this.state;
    const currentLanguage = Weglot.getCurrentLang();
    Weglot.on("languageChanged", this.onWeglotChange.bind(this));
    if (!currentLanguage || currentLanguage !== language) {
      this.setState({ language: Weglot.getCurrentLang() });
    }

    this.setState({
      initialized: true,
    });
  }

  render() {
    const { language, initialized } = this.state;

    if (!initialized) {
      return "";
    }

    return (
      <LanguageSelect
        className={"weglot-select"}
        onChange={this.onChange.bind(this)}
        code={language}
      />
    );
  }

  switchTo(code) {
    Weglot.switchTo(code);
  }

  onChange(option) {
    this.switchTo(option.value);
  }

  onWeglotChange() {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }

    const { onChange } = this.props;
    const url = new URL(window.location);
    const lang = Weglot.getCurrentLang();
    url.searchParams.set("lang", lang);
    window.history.pushState({}, document.title, url);
    this.setState({ language: lang });
    onChange(lang);
  }
}

WeglotSelect.defaultProps = {
  language: "en",
};

WeglotSelect.propTypes = {
  language: PropTypes.string,
};
