import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { imagePath } from "../helpers";
import ExecutionEnvironment from "exenv";
/**
 * Modal
 * /////
 *
 * Props
 * -----
 * close: Should the close button be shown
 * active: Should the modal be active on render?
 * className: The css class(es) to apply to the container.
 * content: The HTML content to go in the modal.
 *
 */
export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: !!props.active, dirty: false };
  }

  componentDidMount() {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }

    document.addEventListener("keydown", this.handleKeyDown.bind(this), false);
    this.listenForClick();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handleKeyDown.bind(this),
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    const { active } = nextProps;
    const { dirty } = this.state;

    if (dirty) return;

    if (active) {
      this.open();
    } else {
      this.close();
    }
  }

  listenForClick() {
    if (!ExecutionEnvironment.canUseDOM) {
      return;
    }

    const { id } = this.props;

    window.setTimeout(() => {
      const trigger = document.querySelector("[href='#popup-" + id + "']");
      if (!trigger) {
        return;
      }

      trigger.removeAttribute("href");

      trigger.addEventListener("click", (e) => {
        this.open();
      });
    }, 0);
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.close();
    }
  }

  renderContent() {
    const { content } = this.props;

    return { __html: content };
  }

  getClassName() {
    let { className, position, size } = this.props;
    let { active } = this.state;

    let classes = {};
    if (className) {
      classes[className] = true;
    }
    classes["modal"] = true;
    classes["active"] = active;

    position = position ? position : "center";
    size = size ? size : "medium";

    classes[position] = true;
    classes[size] = true;

    return classnames(classes);
  }

  open() {
    this.setState({
      active: true,
      dirty: true,
    });
  }

  close() {
    this.setState({
      active: false,
    });
  }

  render() {
    const { content, children, close, closeColor, id } = this.props;

    return (
      <article className={this.getClassName()} id={`popup-${id}`}>
        <div className="modal__overlay" onClick={this.close.bind(this)} />
        <div className="modal__container">
          {close ? (
            <div
              className={classnames("modal__close")}
              onClick={this.close.bind(this)}>
              <img
                src={imagePath(
                  closeColor === "white"
                    ? "ui/ui-close-white.svg"
                    : "ui/ui-close.svg"
                )}
                alt="Close"
                width={14}
                height={14}
              />
            </div>
          ) : (
            ""
          )}

          {content ? (
            <div
              className="modal__body"
              dangerouslySetInnerHTML={this.renderContent()}
            />
          ) : (
            <div className="modal__body">{children}</div>
          )}
        </div>
      </article>
    );
  }
}

Modal.defaultProps = {
  close: true,
};

Modal.propTypes = {
  close: PropTypes.bool,
};
