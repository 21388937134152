import React, {useEffect, useRef} from 'react'
import {Swiper} from 'swiper'
import {format} from 'date-fns'
import {DATE_FORMAT_DOT} from "../constants";
import {imagePath} from "../helpers";

const LinkCarousel = ({links: unsortedLinks = []}) => {
    const swiperRef = useRef()

    const [links] = React.useState(
        (unsortedLinks ?? []).sort((a, b) => {
            if (!a.date) {
                return 1
            }

            if (!b.date) {
                return -1
            }
            return new Date(b.date) - new Date(a.date)
        })
    )

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            loop: false,
            simulateTouch: true,
            shortSwipes: false,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            autoHeight: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            observer: true,
            observeParents: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        })

        return () => {
            swiper.destroy()
        }
    }, [swiperRef])

    return (
        <div className={"link-carousel swiper-outer"}>
            <div className={"container"}>
                <div className="swiper" ref={swiperRef}>
                    <div className="swiper-wrapper">
                        {
                            links.map(({url, title, date, source}, idx) => <div className="swiper-slide"
                                                                                key={'link-carousel-' + idx}>
                                <a className={"link-carousel__link"} href={url} target={"_blank"}>
                                    <div className={"link__content"}>
                                        <div className={"link__content-inner"}>
                                            <b className={"link__title"}>
                                                {title}
                                            </b>
                                            <div className={"link__date"}>
                                                {format(new Date(date), DATE_FORMAT_DOT)}
                                            </div>
                                        </div>

                                        <div className={"link__source"}>
                                            {source}
                                        </div>
                                    </div>
                                    <img className={"link-carousel__icon"}
                                         alt={""}
                                         src={imagePath('ui/ui-external-link.svg')}
                                         width={24}/>
                                </a>
                            </div>)
                        }
                    </div>
                    <div className="swiper-pagination"/>
                </div>
            </div>
        </div>
    )

}

export default LinkCarousel