import React from 'react'
import Image from "./Image";
import Parallax from "./Parallax";
import {accentPath} from '../helpers'

export default class ParallaxWhiteAccents2 extends React.Component {
    css = `
            .parallax__accents__2__white .accent {
                position: absolute;
                z-index: 1;
                top: initial;
                left: initial;
                transition: transform 30ms 0s linear;
                will-change: transform;
            }
            .parallax__accents__2__white .accent-1 {
                left: 6%;
                top: 85%;
                transform: translateY(var(--accent-2-white));
                width: 1.5vw;
                height: 1.5vw;
            }
            .parallax__accents__2__white .accent-2 {
                top: 50%;            
                left: 25%;
                transform: translateY(calc(var(--accent-2-white)*1.5));
                width: 2vw;
                height: 2vw;
            }
            .parallax__accents__2__white .accent-3 {
                top: 85%;            
                left: 74%;
                transform: translateY(calc(var(--accent-2-white)*1.5));
                width: 5vw;
                height: 5vw;
            }
            
             @media (max-width: 658px) {
                .parallax__accents__2__white .accent-1 {
                    width: 5vw;
                    height: 5vw;
                }
                
                .parallax__accents__2__white .accent-2 {
                    width: 7vw;
                    height: 7vw;
                }
                
                .parallax__accents__2__white .accent-3 {
                    width: 10vw;
                    height: 10vw;
                }
             }
        `

    render() {
        return (
            <Parallax config={
                {
                    "from": "top-bottom",
                    "to": "bottom-top",
                    "props": {
                        "--accent-2-white": {
                            "from": "0vh",
                            "to": "-20vh",
                        }
                    }
                }
            } className={`parallax__accents__2__white position-fit`}>
                <Image
                    src={accentPath('accent-10-white')}
                    className={'accent accent-1'}
                    key={'accent-1'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-5-white')}
                    className={'accent accent-2'}
                    key={'accent-2'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-6-white')}
                    className={'accent accent-3'}
                    key={'accent-3'}
                    alt={""}
                />
                <style type="text/css" dangerouslySetInnerHTML={{__html: this.css}} key={'style'}/>
            </Parallax>
        )
    }
}