import React from 'react'
import allCardTypes from './PostCards/index'
import UpdateCard from "./PostCards/UpdateCard";
import {filterObject} from "../../helpers";

const NewsroomPostCard = ({ cardTypes = Object.keys(allCardTypes), ...post}) => {
    const types = filterObject(allCardTypes, (type, key) => cardTypes.includes(key))
    let Type = types[post.type[0]]
    if (!Type) {
       Type = types['Update'] ?? types[Object.keys(types)[0]]
    }
    return <Type {...post} />
}

export default NewsroomPostCard