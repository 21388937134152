import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulImage from "./ContentfulImage";

export default class RichText extends React.Component {
  render() {
    let { content, classNames, Tag = "div", fragment = false } = this.props;

    if (!content) {
      return "";
    }

    if (typeof content == "string") {
      try {
        content = JSON.parse(content);
      } catch (ex) {
        return "";
      }
    }

    return fragment ? (
      <Fragment>{documentToReactComponents(content, this.options)}</Fragment>
    ) : (
      <Tag className={classNames}>
        {documentToReactComponents(content, this.options)}
      </Tag>
    );
  }

  get options() {
    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          return (
            <ContentfulImage
              image={{
                title: node.data?.target?.fields?.title,
                src: node.data?.target?.fields?.file?.url,
                size: node.data?.target?.fields?.file?.details?.size,
                width: node.data?.target?.fields?.file?.details?.image?.width,
                height: node.data?.target?.fields?.file?.details?.image?.height,
                type: node.data?.target?.fields?.file?.contentType,
              }}
              className={"rich-text-image img-fluid"}
              sizeByFilename={true}
            />
          );
        },
      },
    };
  }
}

RichText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
