import React from 'react';
import Card from "./Card";
import ReactResizeDetector from "react-resize-detector";
import ExecutionEnvironment from 'exenv'
import ReactDOM from "react-dom";

export default class UpdatesCard extends React.Component {
    state = {
        lowerBy: 0
    }

    constructor(props) {
        super(props);

        this.card = React.createRef()
    }

    componentDidMount() {
        this.calculateLowerBy()
        window.setTimeout(this.calculateLowerBy.bind(this), 500)
    }

    render() {
        const {pattern, updates} = this.props
        const {lowerBy} = this.state

        if (!updates || !updates.length) {
            return <div style={{minHeight: '350px'}} className={"updates-card__wrapper display-none display-lg-block"}></div>
        }

        return <ReactResizeDetector refreshMode={'debounce'} refreshRate={500} handleHeight handleWidth onResize={this.calculateLowerBy.bind(this)}>
            <div className={"updates-card__wrapper"} style={{"--lower-by": lowerBy}} ref={this.card}>
                    <Card
                        className={'updates-card'}
                        pattern={pattern}
                        heading={"<h3>UPDATES</h3>"}
                        size={'large'}
                        patternSize={'tall'}
                    >
                        {this.renderUpdates()}
                    </Card>
            </div>
        </ReactResizeDetector>
    }

    renderUpdates() {
        const {updates} = this.props

        return updates.map(({date, link, source, title}, idx) => {
            return <div className={"display-flex updates-card__update"} key={`updates-card-${idx}`}>
                <div className={"display-md-flex"}>
                    <div className={"flex-grow-0 padding-right-4 padding-bottom-2"}>
                        { date }
                    </div>
                    <div className={"updates-card__update__content"}>
                        <div className={"flex-grow-1 updates-card__update__title margin-bottom-2"}>
                            { title }
                        </div>
                        <div className={"flex-grow-1 updates-card__update__footer"}>
                            <a href={link} className={"button-link"} title={`Go to ${title}`}>
                                Read More > { source }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    calculateLowerBy()
    {
        if (!ExecutionEnvironment.canUseDOM) {
            return
        }

        let card = ReactDOM.findDOMNode(this.card.current)

        if (!card) {
            return
        }

        let pattern = card.querySelector('.pattern-bar')
        let section = card.closest('section')
        let sectionPadding = parseInt(window.getComputedStyle(section).getPropertyValue('padding-bottom'))
        let patternHeight = pattern ? pattern.offsetHeight : 0
        let lowerBy = (sectionPadding + patternHeight) * -1

        this.setState({lowerBy: lowerBy + 'px'})
    }
}