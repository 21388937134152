import {throttle} from "throttle-debounce";

const setVars = () => {
    const vh = window.innerHeight / 100
    const container = window.document.querySelector('.container')

    if(container) {
        document.documentElement.style.setProperty('--page-gutter-width', (window.innerWidth - container.clientWidth)/2 + 'px')
    }

    document.documentElement.style.setProperty("--vh", `${vh}px`);
}


const cssVars = () => {
    window.addEventListener('resize', throttle(5, setVars))
    setVars();
}

export default cssVars