import React from 'react';
import PropTypes from "prop-types";
import ContentfulImage from "./ContentfulImage";
import PatternBar from "./PatternBar";
import Html from "./Html";

/**
 * Bio Hero
 * --------
 *
 * Props:
 * image (Contentful Image Object)
 * topContent: (HTML)
 * heading: (string)
 * tagline: (HTML)
 * content: (HTML)
 * pattern: (Contentful Image Object)
 * footer: (HTML)
 * accentSmall: (Contentful Image Object)
 * accentLarge: (Contentful Image Object)
 * accentColor: (HEX Color)
 * backgroundColor: (HEX Color)
 */

export default class BioHero extends React.Component {
    render() {
        const {backgroundColor, topContent} = this.props

        return <div className={"bio-hero__wrapper"}>
            <section className={'bio-hero display-md-flex flex-stretch width-full'}
                     style={{backgroundColor}}>
                <div className={"bio-hero__top display-md-none width-full"}>
                    <div className={"container position-md-relative padding-top-3 padding-bottom-1 bg-white"}>
                        <Html content={topContent}/>
                    </div>
                </div>
                <div className={"container display-flex flex-stretch width-full"}>
                    <div className={"bio-hero__body display-md-flex flex-stretch width-full"}>

                        <div className={"display-md-flex width-full"}>
                            {this.renderImage()}
                            {this.renderContent()}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }

    renderImage() {
        const {image} = this.props

        if (!image) {
            return
        }

        return <div className={"bio-hero__image flex-grow-0"}>
            <ContentfulImage
                image={image}
                className={'image-fluid'}
                width={1494}
                height={1890}
                focalPoint={'face'}
            />
        </div>
    }

    renderContent() {
        const {topContent, heading, accentColor, tagline, content} = this.props

        return <div className={"bio-hero__content__container display-flex flex-column flex-grow-1"}>
            <div className={"bio-hero__top flex-grow-1 display-none display-md-block"}>
                <Html content={topContent}/>
            </div>
            <div className={"bio-hero__content flex-grow-0"}>
                <div className={"position-relative z-1"}>
                    <h1 style={{color: accentColor}}>{heading}</h1>
                    {tagline ?
                        <div className={"bio-hero__tagline margin-bottom-5"}><Html content={tagline}/></div> : ''}
                    <Html content={content}/>
                </div>
                {this.renderFooter()}
                {this.renderAccents()}
            </div>
        </div>
    }

    renderFooter() {
        const {footer, pattern} = this.props

        if (!footer && !pattern) {
            return
        }

        return <div className={"bio-hero__footer"}>
            <PatternBar image={pattern} size={"tall"} className={"margin-bottom-3"}/>
            <div className={"bio-hero__footer__content"}>
                <Html content={footer}/>
            </div>
        </div>
    }

    /**
     * Render the accents.
     * @returns {[]}
     */
    renderAccents() {
        const {accentSmall, accentLarge} = this.props

        let accents = []

        if (accentSmall) {
            accents.push((
                <img
                    src={accentSmall.src}
                    className={'accent accent--small'}
                    key={'accent-small'}
                    width={51}
                    height={51}
                    alt={""}
                />
            ))
        }

        if (accentLarge) {
            accents.push((
                <img
                    src={accentLarge.src}
                    className={'accent accent--large'}
                    key={'accent-large'}
                    width={170}
                    height={170}
                    alt={""}
                />
            ))
        }

        return accents
    }
}

BioHero.defaultProps = {
    accentColor: '#111e47',
    backgroundColor: '#f5f3f2'
};

BioHero.propTypes = {
    image: PropTypes.object,
    topContent: PropTypes.string,
    heading: PropTypes.string.isRequired,
    tagline: PropTypes.string,
    content: PropTypes.string,
    pattern: PropTypes.object,
    footer: PropTypes.string,
    accent1: PropTypes.object,
    accent2: PropTypes.object,
    accentColor: PropTypes.string,
    backgroundColor: PropTypes.string
};
