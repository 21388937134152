import React from 'react';
import Button from './Button'

export default class Buttons extends React.Component {
  renderButtons() {
    const { buttons, size, color } = this.props;
    if (!buttons) {
      return ""
    }

    return buttons.filter(({label}) => {
      return !!label
    }).map((button, key) => {
      return <Button
        color={color ? color : button.color}
        size={size ? size : button.size }
        label={button.label}
        link={button.link}
        icon={button.icon}
        iconVisibility={button.iconVisibility}
        key={key}
      />
    })
  }

  render() {
    return (
      <div className="buttons">
        {this.renderButtons()}
      </div>
    )
  }
}

Buttons.defaultProps = {
  buttons: []
}