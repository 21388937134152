import React from 'react'
import classNames from "classnames"
import ErrorBoundary from "../ErrorBoundary";
import NewsroomFilterTags from "./NewsroomFilterTags";
import NewsroomSearchResults from "./NewsroomSearchResults";
import {isFiltering, useSearch} from "../../newsroomHooks";
import NewsroomPostGrid from "./NewsroomPostGrid";

const NewsroomReportResults = (
    {
        results: initialResults = [],
        search: initialSearch = false
    }
) => {
    const {search} = useSearch(initialResults, initialSearch)
    const filtering = isFiltering(['country', 'topic', 'q'])

    if (!filtering) {
        return <NewsroomSearchResults
            results={initialResults}
            search={initialSearch}
            layout={'grid'}
            cardTypes={['Report']}
        />
    }

    return (<ErrorBoundary>
        <div className={classNames('newsroom__results')}>
            <h2 className={"search__heading"}>
                {search.nbHits} reports
            </h2>
            <NewsroomFilterTags params={["country", "topic"]}/>
            <NewsroomSearchResults
                results={initialResults}
                search={initialSearch}
                layout={'grid'}
                cardTypes={['Report']}
            />
        </div>
    </ErrorBoundary>)
}

export default NewsroomReportResults