import React from 'react';

/**
 * @deprecated
 */
export default class PatternHero extends React.Component {
  renderContent() {
    return { __html: this.props.content };
  }

  render() {
    const { backgroundImageUrl } = this.props;

    // Inline style because URL is coming from Contentful.
    const style = {
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }

    return (
      <section className="pattern-hero flex flex-justify-center flex-align-center padding-7" style={style}>
        <div className="text-center" dangerouslySetInnerHTML={this.renderContent()} />
      </section>
    );
  }
}


