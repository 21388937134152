import React from 'react';
import Tabs from './Tabs'
import Tab from './Tab'
import Card from "./Card";

/**
 * Card Profile Tabs
 * /////////////////
 *
 * A tabbed listing of profile Cards.
 *
 * Props:
 * Cards: An array of card objects.
 */
export default class CardProfileTabs extends React.Component {
    getTabsTitles() {
        const { cards } = this.props;
        return cards.reduce(function(tabs, card) {
            if (!card.department || !card.department[0]) return tabs
            if (!tabs.includes(card.department[0])) {
                tabs.push(card.department[0])
            }
            return tabs
        }, [])
    }

    getCardsWithDepartment(department) {
        const { cards } = this.props;
        return cards.filter(card => {
            if (!card.department || !card.department[0]) return false
            return card.department[0] === department
        })
    }

    renderCards(cards) {
        let items = cards.map((card, index) => {
            return (
                <div className="column-full column-md-half column-lg-third space display-flex" key={index}>
                    <Card
                        heading={card.name}
                        headingPlacement={'footer-both'}
                        image={card.image}
                        content={this.getBioContent(card)}
                        contentPlacement={'hover'}
                        footerContent={`<span class="tag text-tiny">${card.subheader}</span>`}
                        footerContentPlacement={'footer-both'}
                        color={"warm-gray"}
                        colorBar={"gold"}
                        colorBarSize={"bar"}
                     />
                </div>
            )
        })
        return (
            <div className='row flex-justify-center'>
                { items }
            </div>
        )
    }

    getBioContent(card) {
        if (!card.twitterHandle) {
            return card.bio
        }

        return `${card.bio} <a href="https://www.twitter.com/${card.twitterHandle}" target="_blank" class="button-twitter" rel="noopener noreferrer">${card.twitterHandle}</a>`
    }

    renderTabs() {
        const { cards } = this.props;

        let tabs = [
            <Tab title="All" ref={React.createRef()} order={0} key='all'>
                {this.renderCards(cards)}
            </Tab>
        ]

        this.getTabsTitles().forEach((title, index) => {
            tabs.push(
                <Tab title={title} ref={React.createRef()} key={index}>
                    {
                        this.renderCards(
                            this.getCardsWithDepartment(title)
                        )
                    }
                </Tab>
            )
        })

        return tabs
    }

    render() {

        return (
            <Tabs>
                { this.renderTabs() }
            </Tabs>
        );
    }
}