import React from "react";
import CountUp from './CountUp'
import classNames from "classnames";
import Reveal from "./Reveal";

const Bar = ({label, value, decimals, prefix, suffix, max, children, delay = 0}) => {
    return <div className={"bar"}
                style={{"--bar-height": `${(value / max) * 100}%`}}
    >
        <div className={"bar__progress-wrapper"}>
            <div className={"bar__progress"}>
                <div className={"bar__progress-content"}>
                    <div className={"bar__value"}>
                        <CountUp
                            delay={delay}
                            decimals={decimals}
                            prefix={prefix}
                            suffix={suffix}
                            start={value}
                            end={value}
                            animate={false}
                        />
                    </div>
                    <Reveal delay={delay} distance={0}>
                        {children}
                    </Reveal>
                </div>
            </div>
        </div>
        <div className={"bar__label"}>
            {label}
        </div>
    </div>
}
const Chart = ({title, labels, stats, color}) => {
    return <div className={classNames("chart", `chart--${color}`)}>
        <div className={"chart__x-label"}>
            {labels.x}
        </div>
        <div className={"chart__bars"}>
            {stats.map((stat, index) => <Bar
                delay={300 + index * 500}
                key={index} {...stat}
                max={Math.max(...stats.map(stat => stat.value))}
            >
                {stats.length === index + 1 && (<div className={"chart__y-label"}>
                    {labels.y}
                </div>)}
            </Bar>)}
        </div>
    </div>
}

export default Chart