import React, {useEffect, useState} from "react"
import {Swiper} from "swiper/react"
import {throttle} from "throttle-debounce";

const CustomSwiper = ({children, onSwiper = null, ...props}) => {
    const [swiper, setSwiper] = useState()

    useEffect(() => {
        if (!swiper) {
            return
        }
        const updateSwiper = () => swiper.update()

        const handleResize = throttle(5, updateSwiper)
        updateSwiper()
        setTimeout(updateSwiper, 1000)
        setTimeout(updateSwiper, 2000)
        setTimeout(updateSwiper, 5000)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [swiper])

    return (
        <Swiper {...props} onSwiper={(instance) => {
            setSwiper(instance)
            if (onSwiper) {
                onSwiper(instance)
            }
        }}>
            {children}
        </Swiper>
    )
}


export default CustomSwiper