import React from 'react';
import LazySizes from './LazySizes'
import ExecutionEnvironment from "exenv";
import classnames from 'classnames'
import ReactDOM from "react-dom";

let scrollReveal = null

if (ExecutionEnvironment.canUseDOM) {
    let ScrollReveal = require("scrollreveal").default;


    scrollReveal = ScrollReveal({
        origin: 'top',
        duration: 500,
        distance: '200px',
        easing: 'ease',
        viewFactor: 0.5,
        cleanup: true
    })
}

/**
 * Picture
 * ///////
 *
 * An image component that generates a picture element using LazySizes
 *
 * Props
 * -----
 * src: The preload image url
 * dataSrc: The final image url
 * srcset: The preload image srcset
 * dataSrcSet: The final image srcset
 * className: A CSS class to add to the image.
 * onLoad: A callback to be called when the image is loaded.
 * lazy: Should the image be lazy loaded?
 * height: The height of the image
 * width: The width of the image
 * position: default: center
 *
 */
export default class RPicture extends React.Component {
    constructor(props) {
        super(props);

        this.image = React.createRef();
    }

    /**
     * The picture dom node
     *
     * @returns {*|*|*}
     */
    get element() {
        return ReactDOM.findDOMNode(this.image.current)
    }

    /**
     * Should the image be revealed?
     *
     */
    get shouldReveal() {
        const {reveal} = this.props

        return scrollReveal && reveal
    }

    /**
     * get the classnames for the image.
     * @returns {*}
     */
    get classnames() {
        const {className, position, width, height} = this.props

        let classes = {
            'image': true,
            'height-auto': true,
            'image--landscape': width && height ? width > height : false,
            'image--portrait': width && height ? height > width : false
        }

        if (className) {
            classes[className] = true
        }

        if (position) {
            classes['object-position-' + position] = true
        }

        return classnames(classes)
    }

    /**
     * When the component mounts, start reveal
     */
    componentDidMount() {
        const {reveal} = this.props

        if (this.shouldReveal) {
            setTimeout(() => {
                scrollReveal.reveal(
                    this.element,
                    (typeof reveal === 'object') ? reveal : {
                        delay: 0,
                        origin: 'bottom',
                        duration: 1000,
                        distance: '100px'
                    }
                )
            }, 100)

        }
    }

    /**
     * Render the picture element.
     *
     * @returns {*}
     */
    render() {
        const {children, className, reveal, ...other} = this.props

        return <LazySizes
            className={this.classnames}
            iamgeRef={this.image}
            {...other}
        >

            {children}

        </LazySizes>
    }
}

RPicture.defaultProps = {
    onLoad: function () {
    },
    position: 'center',
    reveal: false
}