import React from 'react'
import NewsroomPostCard from "./NewsroomPostCard";
import CardTypes from './PostCards/index'

const NewsroomPostGrid = ({posts,  cardTypes= Object.keys(CardTypes)}) => {
    return (<div className={"row"}>
        {
            posts.map((post) => <div className={"col column-md-third column-lg-quarter margin-bottom-8"} key={`post-grid-${post.id}`}>
                    <NewsroomPostCard
                        cardTypes={cardTypes}
                        {...post}
                    />
            </div>)
        }
    </div>)
}

export default NewsroomPostGrid