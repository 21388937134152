import React, {useState, useCallback, useEffect, Fragment, useRef} from 'react'
import {useInView} from "react-intersection-observer";
import {imagePath} from "../../helpers"
import classNames from 'classnames'
import {useSubmissionParam, useSearch} from "../../newsroomHooks"
import PubSub from 'pubsub-js'
import {NEWSROOM_SEARCH_SUCCESS} from "../../constants"
import {useInitialized} from "../../hooks";
import Loader from "../Loader";
import NewsroomFilters from './NewsroomFilters'
import {throttle} from 'throttle-debounce'
/**
 * The newsroom search bar
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NewsroomSearchBar = (props) => {
    const {inProgress, fetch} = useSearch()
    const {query: defaultQuery = {}} = props
    const [query, setQuery] = useSubmissionParam('query');
    const [isOpen, setIsOpen] = useState(false)
    const [, setPage] = useSubmissionParam('page');
    const input = useRef()
    const initialized = useInitialized()
    const ref = useRef()

    //Close after search success
    useEffect(() => {
        const successToken = PubSub.subscribe( NEWSROOM_SEARCH_SUCCESS, () => {
            setIsOpen(false)
        } )

        return () => {
            PubSub.unsubscribe(successToken);
        };
    }, [initialized])

    //Submit when enter is pressed
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setQuery(input.current.value)
            fetch()
        }
    }

    //Reset the search
    const clear = () => {
        setQuery('')
        input.current.value = ''
        setPage(null)
        fetch()
    }

    const close = () => setIsOpen(false)

    return (
        <div className={classNames("search-bar__wrapper")} ref={ref}>
            <div className={"container"}>
                <div className={classNames("newsroom__search-bar", {"newsroom__search-bar--open": isOpen})}>
                    <div className={"search__bar"}>
                        <fieldset className={"search__control"}>
                            <img src={imagePath('ui/ui-search.svg')}
                                 alt="Search"
                                 className="search__icon"
                                 width={18}
                                 height={18}/>
                            <input ref={input}
                                   placeholder={"Search Newsroom"}
                                   type="text"
                                   defaultValue={query}
                                   onKeyDown={handleKeyDown}
                                   className={"search__field"}
                            />
                            {(query && !inProgress) ? <img src={imagePath('ui/ui-close.svg')}
                                                           alt="Close"
                                                           className="search__close-icon"
                                                           width={10}
                                                           height={10}
                                                           onClick={clear}/> : ''}
                            <Loader active={inProgress}/>
                        </fieldset>
                        <div className={"search__filters-toggle"}
                             onClick={() => setIsOpen(!isOpen)}>
                            <a className={"button--link display-none display-lg-block"}>
                                Filter
                            </a>
                            <div className="search__filters-icon">
                                <img src={imagePath('ui/ui-filter.svg')}
                                     alt="Toggle Filters"
                                     className="search__filters-icon"
                                     width={29}
                                     height={20}
                                />
                            </div>
                        </div>
                    </div>

                    <NewsroomFilters {...props} setIsOpen={setIsOpen} isOpen={isOpen} close={close}/>
                </div>
            </div>
        </div>

    )
}

export default NewsroomSearchBar
