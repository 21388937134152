import React, {useCallback} from 'react'
import {useSearch, useLastParam} from "../../newsroomHooks";

const NewsroomTopics = ({topics, link = false}) => {
    const {fetch} = useSearch()
    const [topic, setTopic] = useLastParam('topic')
    const [, setPage] = useLastParam('page')

    const navigateToTopic = (value) => {
        window.location.href = '/newsroom?topic=' + value
    }

    const addTopic = useCallback((value) => {
        let newTopic = [...topic]

        if (newTopic.indexOf(value) !== -1) {
            //Already searching by topic, don't do anything
            return
        } else {
            newTopic.push(value)
        }
        setPage(0, false)
        setTopic(newTopic, false)
        fetch({
            scrollIntoView: true
        })
    }, [topic])

    return (
        <div className={"post__topics"}>
            { topics && topics.length ? topics.map(value =>  <a className={"post-card__topic pill pill--warm-gray"} key={`topic-${value}`} onClick={() => {
                link ? navigateToTopic(value) : addTopic(value)
            }}>
                {value}
            </a>) : ''}
        </div>
    )
}

export default NewsroomTopics