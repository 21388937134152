import React from 'react';
import Menu from './Menu'
import classnames from 'classnames'
import Link from "./Link";
import {debounce} from "throttle-debounce"

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.search = React.createRef();
        this.header = React.createRef();
        this.state = {userAtTopOfWindow: true};
        this.handleScroll = debounce(50, this.handleScroll.bind(this));
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, {passive: true});
        document.documentElement.style.setProperty('--header-height', `${this.header.current.clientHeight}px`);
    }

    // When the user scrolls, hide the dropdown menus and only leave
    // logo and donate button visible.
    handleScroll() {
        const {userAtTopOfWindow} = this.state;

        if (userAtTopOfWindow && window.pageYOffset != 0) {
            this.setState({userAtTopOfWindow: false})
        } else if (!userAtTopOfWindow && window.pageYOffset == 0) {
            this.setState({userAtTopOfWindow: true})
        }
    }

    renderButton() {
        const {button} = this.props;
        if (!button) {
            return ''
        }
        return <div className={classnames("header__donate header__nav-menu__item", 'flex', 'flex-stretch')}>
            <Link {...button.items[0]}
                  className={classnames('flex', 'header__nav-menu__link', 'flex-align-items-center')}
                  icon={false}
            />
        </div>
    }

    render() {
        const {logoURL, menu, currentUrl, mobileMenuImage, mobileMenuLink, mobileMenuContent} = this.props;

        return (
            <div className={classnames('header-container', {'header-border-bottom': false})} data-wg-notranslate>
                <header
                    ref={this.header}
                    className="container flex flex-row flex-justify-space-between flex-align-center position-relative flex-stretch">
                    <div className="header__brand lex-grow-1 flex-grow-lg-0 flex flex-align-center">
                        <a href="/">
                            <img className="header__logo" src={logoURL} width="205" height="49" alt="Malala Fund"/>
                        </a>
                    </div>
                    
                    <Menu items={menu}
                          currentUrl={currentUrl}
                          mobileMenuImage={mobileMenuImage}
                          mobileMenuLink={mobileMenuLink}
                          mobileMenuContent={mobileMenuContent}>
                        {this.renderButton()}
                    </Menu>

                </header>
            </div>
        );
    }
}
