import React from "react";
import PropTypes from "prop-types";
import SwiperCards from "./SwiperCards";
import Card from "./Card";
import uniqueId from "uniqid"
import classnames from 'classnames'

export default class Cards extends React.Component {
    render()
    {
        const {layout, cards, justification} = this.props

        if (layout === 'carousel') {
            return <SwiperCards cards={cards} />
        }

        return <div className={"card-grid cards container"}>
            <div className={`row flex-justify-${justification}`}>
                {this.renderCards()}
            </div>
        </div>
    }

    renderCards()
    {
        let {cards, width} = this.props

        return cards.map(card => <div className={classnames('card__column', `column-full`,`column-lg-${card.gridWidth ? card.gridWidth : width}`, 'flex')} key={uniqueId()}>
            <Card {...card} />
        </div>)
    }

}

Cards.defaultProps = {
    width: 'third',
    justification: 'center'
}

Cards.propTypes = {
    layout: PropTypes.string, //grid|carousel,
    width: PropTypes.string, //full|half|third|quarter
    cards: PropTypes.array, //Array or card objects
    justification: PropTypes.string
};