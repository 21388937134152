import React from "react";
import axios from "axios";
import qs from "querystring";
import classnames from "classnames";

const trilogyForm = (el) => {
  const plugin = new TrilogyForm(el);
};

export default trilogyForm;

/**
 * Trilogy form component. Takes the HTML for a trilogy form, and converts it to submit via the AJAX api.
 */
class TrilogyForm {
  el = null;
  errorMessage = "Oops, something went wrong. Please try again.";
  validationMessage =
    "Something went wrong. Please review your submission and try again.";
  successMessage = "Thank you! We have received your submission.";
  message = "";
  error = "";
  sessionReferrer = null;

  constructor(el) {
    this.form = el;
    this.getSessionReferrer();
    this.form.addEventListener("submit", this.handleSubmit.bind(this));
  }

  /**
   * Get the session referrer from GA
   */
  getSessionReferrer() {
    if ("undefined" !== typeof ga) {
      ga((tracker) => (this.sessionReferrer = tracker.get("referrer")));
    } else {
      setTimeout(() => this.getSessionReferrer.bind(this), 1000);
    }
  }

  /**
   * A map of fields and defaults expected by Trilogy.
   * @returns {({name: string, value: string}|{name: string, value: number}|{name: string}|{name: string, value: string}|{name: string})[]}
   */
  get dataMap() {
    return [
      {
        name: "Actionurl",
        value: window.location.href,
      },
      {
        name: "NativeActionTakenId",
      },
      {
        name: "ActivityCode",
        value: window.location.origin + window.location.pathname,
      },
      {
        name: "LocationCode",
      },
      {
        name: "ReferrerSourceCode",
      },
      {
        name: "ReferrerUrl",
        value:
          document.referrer !== window.location.href ? document.referrer : null,
      },
      {
        name: "SessionReferrerUrl",
        value: this.sessionReferrer,
      },
      {
        name: "FirstName",
      },
      {
        name: "FirstName",
      },
      {
        name: "LastName",
      },
      {
        name: "EmailAddress",
      },
      {
        name: "EmailOptIn",
        value: true,
      },
      {
        name: "PostalCode",
      },
      {
        name: "FormType",
      },
      {
        name: "VerificationToken",
        value: "d4b892b1-8bb3-48f5-8b3b-ccf76c55223b",
      },
      {
        name: "SuccessUrl",
      },
    ];
  }

  get formData() {
    return new FormData(this.form);
  }

  get alert() {
    return this.form.querySelector(".alert");
  }

  /**
   * Build the submission from the formData, putting any extra fields in the AdditionalData object.
   * @returns {{}|*}
   */
  get submission() {
    if (!this.form) return {};

    const formData = new FormData(this.form);

    let payload = this.dataMap.reduce((payload, { name, value }) => {
      if (!value) {
        value = null;
      }
      if (formData.has(name)) {
        value = formData.get(name);
      }
      payload[name] = value;
      return payload;
    }, {});

    let additionalData = [...formData].reduce(
      (additionalData, [key, value]) => {
        const primaryField = this.dataMap.some((item) => {
          return item.name === key;
        });
        if (!primaryField) {
          additionalData[key] = value;
        }
        return additionalData;
      },
      {}
    );

    payload["AdditionalData"] = additionalData;

    return payload;
  }

  /**
   * Get the success URL from the form params.
   */
  get successUrl() {
    return this.formData.get("SuccessUrl");
  }

  /**
   * Submit via ajax
   * @param e
   */
  handleSubmit(e) {
    e.preventDefault();
    if (this.alert) {
      this.alert.remove();
    }
    this.error = "";
    this.message = "";

    this.render();
    axios
      .post(this.form.action, qs.stringify(this.submission), {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(this.handleSuccess.bind(this))
      .catch(this.handleFailure.bind(this));
  }

  /**
   * The form submission was successful. Redirect or display a message.
   * @param response
   */
  handleSuccess(response) {
    let { data } = response;
    if (data.result === "Success") {
      if (this.successUrl) {
        window.location.href = this.successUrl;
        return;
      }

      this.message = this.successMessage;
    }

    this.error = this.validationMessage;

    this.render();
  }

  /**
   * Error submitting.
   * @param response
   * @param request
   * @param message
   * @param config
   */
  handleFailure({ response, request, message, config }) {
    if (response) {
      console.log(response);
      if (response.result) {
        this.error = response.result;
        return;
      }
    } else if (request) {
      console.log(request);
    }
    this.error = this.errorMessage;

    this.render();
  }

  /**
   * Render the alert message.
   * @returns {*}
   */
  renderAlert() {
    let { error, message } = this;
    let type = "";
    let text = "";
    if (message) {
      type = "success";
      text = message;
    } else if (error) {
      type = "error";
      text = error;
    } else {
      return;
    }

    const alert = document.createElement("div");
    alert.className = classnames("alert", `alert--${type}`);
    alert.innerHTML = text;

    return alert;
  }

  /**
   * Render the form.
   * @returns {*}
   */
  render() {
    const alert = this.renderAlert();
    if (alert) {
      this.form.append(alert);
    }
  }
}
