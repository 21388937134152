import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import uniqid from "uniqid";
import {imagePath} from "../helpers";
import Html from "./Html";
import {useHash} from "../hooks";

const Drawer = ({
                    className = "",
                    headerClassName = "",
                    headerContentClassName = "",
                    contentClassName = "",
                    contentInnerClassName = "",
                    key: keyProp,
                    anchor: anchorProp,
                    header,
                    content,
                    expandedIcon = imagePath("ui/ui-circle-minus.svg"),
                    collapsedIcon = imagePath("ui/ui-circle-plus.svg"),
                    expanded: expandedProp = false,
                    children
                }) => {
    const [expanded, setExpanded] = useState(expandedProp);
    const [key] = useState(keyProp ? keyProp : uniqid());
    const [anchor] = useState(anchorProp ? anchorProp : key)
    const [hash, setHash] = useHash(expanded ? anchor : false);
    const [clickKey, setClickKey] = useState(false, uniqid());

    useEffect(() => {
        const currentHash = hash
        let anchorHash = !anchor.includes("#") ? `#${anchor}` : anchor;
        setExpanded(currentHash === anchorHash)
    }, [hash, anchor, clickKey,]);

    const open = () => {
        setHash(anchor);
    }

    const close = () => {
        setHash(false)
    }

    const toggle = useCallback(() => {
        expanded ? close() : open();
        setClickKey(uniqid());
    }, [expanded, open, close]);

    return (
        <div className={classNames("drawer margin-bottom-1", className, {
            "drawer--expanded": expanded,
        })}
             id={`drawer--${key}`}
        >
            <div
                className={classNames("drawer__header flex flex-row flex-justify-space-between flex-align-center", headerClassName)}
                role={"button"}
                aria-expanded={expanded}
                aria-controls={`drawer-content--${key}`}
                onClick={toggle}
            >
                <div className={classNames("drawer__header-content font-size-extra-large", headerContentClassName)}>
                    {header}
                </div>

                <img
                    src={expanded ? expandedIcon : collapsedIcon}
                    className={"drawer__icon"}
                    alt={expanded ? "Collapse" : "Expand"}
                />
            </div>

            <div className={classNames("drawer__content", contentClassName)}>
                <div className={classNames("drawer__content-inner", contentInnerClassName)}
                     id={`drawer-content--${key}`}>
                    <Html content={content}/>
                    {children}
                </div>
            </div>

        </div>
    )
}

export default Drawer;