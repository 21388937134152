import React from 'react';

export default class NavItem extends React.Component {
    getClassName() {
        const { active, className } = this.props;
        return `nav-item ${className ? className : ''} ${(active) ? ' active' : ''}`
    }

    render() {
        const { href, children} = this.props;

        return (
            <a className={this.getClassName()} href={href} onClick={this.props.onClick}>
                {children}
            </a>
        );
    }
}