import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import NoSSR from './NoSSR'
import React from 'react'

const ShareIcons = function ({
                                 size = 24,
                                 round = true,
                                 iconFillColor = '#111e47',
                                 bgFillColor = 'white',
                                 mobileIconFillColor = '#111e47',
                                 mobileBgFillColor = '#F5F3F2',
                                 mobileSize = 27,
                             }) {
    return (
        <NoSSR>
            <div className={"share-icons display-none display-lg-block"}>
                <FacebookShareButton url={window.location.href.split('?')[0]}>
                    <FacebookIcon
                        size={size}
                        round={round}
                        iconFillColor={iconFillColor}
                        bgStyle={{fill: bgFillColor}}
                    />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href.split('?')[0]}>
                    <TwitterIcon
                        size={size}
                        round={round}
                        iconFillColor={iconFillColor}
                        bgStyle={{fill: bgFillColor}}
                    />
                </TwitterShareButton>
            </div>
            <div className={"share-icons display-lg-none"}>
                <FacebookShareButton url={window.location.href.split('?')[0]}>
                    <FacebookIcon
                        size={mobileSize}
                        round={round}
                        iconFillColor={mobileIconFillColor}
                        bgStyle={{fill: mobileBgFillColor}}
                    />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href.split('?')[0]}>
                    <TwitterIcon
                        size={mobileSize}
                        round={round}
                        iconFillColor={mobileIconFillColor}
                        bgStyle={{fill: mobileBgFillColor}}
                    />
                </TwitterShareButton>
            </div>
        </NoSSR>

    )
}

export default ShareIcons

