import React from 'react'
import Image from "./Image";
import Parallax from "./Parallax";
import {accentPath} from '../helpers'

export default class ParallaxStaffAccents extends React.Component {
    css = `
            .parallax__staff .accent {
                position: absolute;
                z-index: 1;
                top: initial;
                left: initial;
                transition: transform 30ms 0s linear;
                will-change: transform;
            }
            .parallax__staff .accent-1 {
                left: 23vw;
                top: 82%;
                transform: translateY(var(--translate-y));
                width: 54px;
                height: 54px;
            }
            .parallax__staff .accent-2 {
                left: 20vw;
                top: 100%;
                transform: translateY(calc(var(--translate-y)*1.5)) rotate(135deg);
                width: 49px;
                height: 49px;
            }
            
           @media (max-width: 1478px) {
            .parallax__staff .accent-1 {
                left: 17vw;
            }
            .parallax__staff .accent-2 {
                left: 13vw;
            }
           }
           
           @media (max-width: 1164px) {
            .parallax__staff .accent-1 {
                left: 6vw;
            }
            .parallax__staff .accent-2 {
                left: 0vw;
            }
           }
           
           @media (max-width: 991px) {
            .parallax__staff .accent-1 {
                width: 3vw;
                height: 3vw;
                left: 17vw;
            }
            .parallax__staff .accent-2 {
                width: 3vw;
                height: 3vw;
                left: 13vw;
            }
           }
           
        @media (max-width: 860px) {
            .parallax__staff .accent-1 {
                width: 5vw;
                height: 5vw;
                 left: 6vw;
            }
            .parallax__staff .accent-2 {
                width: 5vw;
                height: 5vw;
                left: 0vw;
            }
           }
        `

    render() {
        return (
            <Parallax config={
                {
                    "from": "top-bottom",
                    "to": "bottom-top",
                    "props": {
                        "--translate-y": {
                            "from": "0vh",
                            "to": "-20vh",
                        }
                    }
                }
            } className={`parallax__staff position-fit`}>
                <Image
                    alt={""}
                    src={accentPath('accent-10-white')}
                    className={'accent accent-1'}
                    key={'accent-1'}
                />
                <Image
                    alt={""}
                    src={accentPath('accent-2-white')}
                    className={'accent accent-2'}
                    key={'accent-2'}
                />
                <style type="text/css" dangerouslySetInnerHTML={{__html: this.css}} key={'style'}/>
            </Parallax>
        )
    }
}
