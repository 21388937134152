import React from 'react'
import Image from "./Image";
import Parallax from "./Parallax";
import {accentPath} from '../helpers'

export default class ParallaxWhiteAccents1 extends React.Component {
    css = `
            .parallax__accents__1__color .accent {
                position: absolute;
                z-index: 1;
                top: initial;
                left: initial;
                transition: transform 30ms 0s linear;
                will-change: transform;
                overflow: hidden;
            }
            .parallax__accents__1__color .accent-1 {
                left: 15vw;
                top: 45%;
                transform: translateY(var(--accent-1-color)) rotate(45deg);
                width: 4.5vw;
                height: 4.5vw;
            }
            .parallax__accents__1__color .accent-2 {
                left: 84vw;
                top: 116%;
                transform: translateY(calc(var(--accent-1-color)*1.5));
                width: 2.5vw;
                height: 2.5vw;
            }
            .parallax__accents__1__color .accent-3 {
                left: 88vw;
                top: 93%;
                transform: translateY(var(--accent-1-color));
                width: 2.1vw;
                height: 2.1vw;
            }
            
            @media (max-width: 1300px) {
                .parallax__accents__1__color .accent-1 {
                      left: 8vw;
                }
            }
            
            @media (max-width: 1100px) {
                .parallax__accents__1__color .accent-1 {
                      left: 4vw;
                }
            }
            
            @media (max-width: 750px) {
                .parallax__accents__1__color .accent-1 {
                      left: -1vw;
                }
            }
            
            
            @media (max-width: 658px) {
                 .parallax__accents__1__color .accent-1 {
                    width: 17vw;
                    height: 17vw;
                    display: block;
                    left: -8vw;
                    top: 39%;
                    max-width: 60px;
                    max-height: 60px;
                }
                
                .parallax__accents__1__color .accent-2 {
                    display: block;
                    left: 79vw;
                    top: 120%;
                    width: 9vw;
                    height: 9vw;
                }
                
                .parallax__accents__1__color .accent-3 {
                    left: 23vw;
                    width: 8vw;
                    height: 8vw;
                    top: 32%;
                }
            }
        `

    render() {
        return (
            <Parallax config={
                {
                    "from": "top-bottom",
                    "to": "bottom-top",
                    "props": {
                        "--accent-1-color": {
                            "from": "0vh",
                            "to": "-20vh",
                        }
                    }
                }
            } className={`parallax__accents__1__color position-fit`}>
                <Image
                    src={accentPath('accent-4')}
                    className={'accent accent-1'}
                    key={'accent-1'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-3')}
                    className={'accent accent-2'}
                    key={'accent-2'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-5')}
                    className={'accent accent-3'}
                    key={'accent-3'}
                    alt={""}
                />
                <style type="text/css" dangerouslySetInnerHTML={{__html: this.css}} key={'style'}/>
            </Parallax>
        )
    }
}