import React from 'react';

/**
 * @deprecated
 */
export default class SearchResult extends React.Component {
    createTitle() {
        const {title} = this.props;

        return {__html: title};
    }

    createText() {
        const {text} = this.props;

        return {__html: text};
    }

    render() {
        const { linkUrl } = this.props;

        return (
            <article className="search-result margin-bottom-5">
                <a href={linkUrl}><h3 className="margin-bottom-2 font-size-6" dangerouslySetInnerHTML={this.createTitle()} /></a>
                <p>
                    <span dangerouslySetInnerHTML={this.createText()} /> <a href={linkUrl}><b>Read more</b></a>
                </p>
            </article>
        );
    }
}
