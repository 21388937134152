import React, {Fragment, useRef} from 'react'
import {useSwipeable} from "react-swipeable";
import {throttle} from "throttle-debounce";
import NewsroomFilterSelect from "./NewsroomFilterSelect";
import {imagePath, isOverflow} from "../../helpers";
import {useSearch, useSubmissionParam} from "../../newsroomHooks";

/**
 * Desktop filter view
 *
 * @param countries
 * @param types
 * @param topics
 * @param onClear
 * @param onSubmit
 * @param isOpen
 * @constructor
 */
const DesktopNewsroomFilters = ({countries, types, topics, onClear, onSubmit, isOpen}) => {
    if (!isOpen) {
        return ''
    }

    return (
        <div className={"search__filters display-none display-lg-block"}>
            <div className={"filters__list"}>
                <NewsroomFilterSelect options={types}
                                      name={"type"}
                                      label={'Content Type'}/>
                <NewsroomFilterSelect options={countries}
                                      name={"country"}
                                      label={'Country'}/>
                <NewsroomFilterSelect options={topics}
                                      name={"topic"}
                                      label={'Topic'}/>
                <div className={"filters__control"}>
                    <a className={"button--link"}
                       onClick={onClear}>
                        Clear All
                    </a>
                    <a className={"button button--malala"}
                       onClick={onSubmit}>
                        Apply
                    </a>
                </div>
            </div>
        </div>
    )
}

/**
 * Mobile filter view
 *
 * @param countries
 * @param types
 * @param topics
 * @param setIsOpen
 * @param onClear
 * @param onSubmit
 * @param label
 * @param close
 * @returns {JSX.Element}
 * @constructor
 */
const MobileNewsroomFilters = ({
       countries,
       types,
       topics,
       setIsOpen,
       onClear,
       onSubmit,
       close,
       label = "Filter News",
        isOpen
   }) => {
    const searchFiltersRef = useRef()
    const swipeHandlers = useSwipeable({
        onSwipedDown: () => {
            if (isOverflow(document.querySelector('.search__filters--mobile'))) {
                return
            }
            close()
            return false;
        }
    }, [searchFiltersRef]);

    return (
        <Fragment>
            {isOpen ? (
                <div className={"filters__backdrop"} onClick={close} />
            ) : ''}
            <div className={"search__filters search__filters--mobile search__control display-lg-none"} {...swipeHandlers}>
                <div className={"filters__bar"}>
                    <div className={"filters__heading"}>
                        {label}
                    </div>
                    <div className={"filters__close"}>
                        <img src={imagePath('ui/ui-close.svg')}
                             alt="Close"
                             width={12}
                             height={12}
                             onClick={() => {
                                 setIsOpen(false)
                             }}/>
                    </div>
                </div>
                <div className={"filters__list"}>
                    <div>
                        <NewsroomFilterSelect options={types}
                                              name={"type"}
                                              label={'Content Type'}/>
                        <NewsroomFilterSelect options={topics}
                                              name={"topic"}
                                              label={'Topic'}/>
                    </div>

                    <NewsroomFilterSelect options={countries}
                                          name={"country"}
                                          label={'Country'}/>

                </div>
                <div className={"filters__control"}>
                    <a className={"button--link text-navy font-weight-black"}
                       onClick={onClear}>
                        Clear All
                    </a>
                    <a className={"button button--malala button--wide"}
                       onClick={onSubmit}>
                        Apply
                    </a>
                </div>
            </div>
        </Fragment>

    )
}

/**
 * Filter drawer
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NewsroomFilters = (props) => {
    const [, setType] = useSubmissionParam('type', true);
    const [, setCountry] = useSubmissionParam('country', true);
    const [, setTopic] = useSubmissionParam('topic');
    const [, setPage] = useSubmissionParam('page')
    const {fetch} = useSearch()
    const {query = {}} = props

    //Handle the filter submit
    const onSubmit = () => {
        setPage(0, false) //First set the page to 0
        fetch({
            scrollIntoView: true
        }) //Run the search
    }

    //Clear each of the filter params
    const clearFilters = () => {
        setType(query.type ?? [])
        setCountry(query.country ?? [])
        setTopic(query.topic ?? [])
    }

    return (
        <Fragment>
            <DesktopNewsroomFilters {...props}
                                    onClear={clearFilters}
                                    onSubmit={onSubmit}
            />
            <MobileNewsroomFilters {...props}
                                   onClear={clearFilters}
                                   onSubmit={onSubmit}
            />
        </Fragment>
    )
}

export default NewsroomFilters