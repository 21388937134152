import React, {useCallback, useState} from 'react'
import LanguageSelect from "./LanguageSelect"

const LanguageSelectLink = ({label = "Select", url = null}) => {
    const [current, setCurrent] = useState()

    const handleClick = useCallback(() => {
        if (!current) {
            return
        }
        const base = url ? url : window.location
        const urlSearchParams = new URLSearchParams("?lang=" + current);
        window.location.href = base + "?" + urlSearchParams.toString()
    }, [url, current])

    const handleChange = useCallback((option) => {
        setCurrent(option ? option.value : null)
    })

    return <div className={"language-select-link flex"}>
        <div className={"margin-bottom-2"}>
            <LanguageSelect onChange={handleChange}/>
        </div>
        <div className={"button"}
             onClick={handleClick}>
            {label}
        </div>
    </div>
}

export default LanguageSelectLink