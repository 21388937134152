import React from 'react'
import Card from "../../Card"
import classNames from 'classnames'
import NewsroomPostMeta from "../NewsroomPostMeta"
import NewsroomTopics from "../NewsroomTopics"

const UpdateCard = (post) => {
    const {title, thumbnail, thumbnailFocus, author, topics, slug, link, linkTopics = false} = post

    return <Card image={thumbnail}
                 imageFocus={thumbnailFocus}
                 imageWidth={385}
                 imageHeight={267}
                 hrefPlacement={"image"}
                 href={link}
                 className={classNames('post__card post__card--update bg-transparent')}
    >

        <NewsroomPostMeta {...post} />

        <a href={"/newsroom/" + slug} title={"View " + title} className={"post__link"}>
            <h3 className={"post-card__heading"}>
                {title}
            </h3>
        </a>

        {author ? <div className={"post__author-name"}>
            {author.name}
        </div> : ''}

        <NewsroomTopics topics={topics} link={linkTopics}/>
    </Card>
}

export default UpdateCard