import React from 'react';
import RPicture from './Picture'

/**
 * Image
 * /////
 *
 * An image component that generates a picture element with support for retina and mobile images.
 *
 * Props
 * -----
 * srcPreview: The image to load while the image is loading,
 * src: The path to the image
 * src2x: The path to the retina image
 * srcPreviewMobile: The mobile image to load while the image is loading,
 * srcMobile: The path to the mobile image,
 * src2xMobile: The path to the retina mobile image
 * className: A CSS class to add to the image.
 * onLoad: A callback to be called when the image is loaded.
 * lazy: Should the image be lazy loaded?
 * height: The height of the image (used with lazy load).'
 * style: Style object
 *
 */
export default class RImage extends React.Component {

    get preview() {
        const {srcPreview} = this.props

        if (srcPreview) {
            return srcPreview
        }

        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
    }

    /**
     * The final srcset, or null if it's not lazy.
     * @returns {Array}
     */
    get dataSrcSet() {
        if (!this.isLazy) {
            return null;
        }

        return this.makeSrcSet()
    }

    /**
     * The preview srcset, or the actual srcset if it's not lazy
     * @returns {Array}
     */
    get srcSet() {
        const {srcPreview} = this.props

        if (!this.isLazy) {
            return this.makeSrcSet()
        }

        const srcSet = []

        srcSet.push(`${srcPreview} 1x`)

        return srcSet.join(', ')
    }

    get mobileSrcSet() {
        const {srcMobile, src2xMobile} = this.props

        if (!srcMobile) return null;

        const srcSet = []

        srcSet.push(`${srcMobile} 1x`)

        if (src2xMobile) {
            srcSet.push(`${srcMobile} 1x`)
        }

        return srcSet.join(', ')
    }

    /**
     * Build up the final srcset
     * @returns {Array}
     */
    makeSrcSet() {
        const {src, src2x} = this.props

        const srcSet = []

        srcSet.push(`${src} 1x`)

        if (src2x) {
            srcSet.push(`${src2x} 2x`)
        }

        return srcSet.join(', ')
    }


    /**
     * Render the image.
     * @returns {*}
     */
    render() {
        const {src, className, onLoad, height, width, focalPoint, reveal, style, alt = ""} = this.props

        if (reveal) {
            //Avoid the reveal competing with the lazy load

            return <RPicture
                src={src}
                className={className}
                onLoad={onLoad}
                height={height}
                width={width}
                focalPoint={focalPoint}
                reveal={reveal}
                alt={alt}
            >
                <source
                    srcSet={this.mobileSrcSet}
                    media="--mobile"/>
                <source
                    srcSet={this.dataSrcSet}
                />
            </RPicture>
        }

        return <RPicture
            src={this.preview}
            dataSrc={src}
            className={className}
            onLoad={onLoad}
            height={height}
            width={width}
            focalPoint={focalPoint}
            reveal={reveal}
            style={style}
            alt={alt}
        >
            <source
                data-srcset={this.mobileSrcSet}
                media="--mobile"/>
            <source
                data-srcset={this.dataSrcSet}
            />
        </RPicture>
    }
}

RImage.defaultProps = {
    style: {}
}