import React from 'react';

/**
 * For use in the tabs component
 */
export default class TabNavigation extends React.Component {
    getClassName() {
        const { className } = this.props;
        return `tabs ${className ? className : ''}`
    }

    render() {
        const { children } = this.props;

        return (
            <nav className={ this.getClassName() }>
                {children}
            </nav>
        );
    }
}
