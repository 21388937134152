import React, {useEffect, useState, useCallback} from "react"
import NewsroomSearch from './NewsroomSearch'
import PubSub from 'pubsub-js'
import {
    NEWSROOM_SEARCH_UPDATED,
    NEWSROOM_SEARCH
} from "./constants"
import {useInitialized} from "./hooks"
import ExecutionEnvironment from 'exenv'
import URI from 'urijs'

export const useSearch = () => {
    const [results, setResults] = useState(NewsroomSearch.results)
    const [inProgress, setInProgress] = useState(false)
    const [search, setSearch] = useState(NewsroomSearch.search)
    const initialized = useInitialized();
    const [submission, setSubmission] = useState(NewsroomSearch.submission)
    const [lastSubmission, setLastSubmission] = useState(NewsroomSearch.lastSubmission)
    const [lastUrl, setLastUrl] = useState(NewsroomSearch.lastUrl)

    useEffect(() => {
        const handle = (event, data) => {
            setResults(data.results)
            setInProgress(data.inProgress)
            setSearch(Object.assign({}, data.search))
            setSubmission(Object.assign({}, data.submission))
            setLastUrl(data.lastUrl)
            setLastSubmission(Object.assign({}, data.lastSubmission))
        }

        const token = PubSub.subscribe( NEWSROOM_SEARCH_UPDATED, handle )

        return () => {
            PubSub.unsubscribe(token)
        };
    }, [initialized])

    return {
        results,
        inProgress,
        search,
        fetch: NewsroomSearch.fetch.bind(NewsroomSearch),
        submission,
        setSubmissionParam: NewsroomSearch.setParam.bind(NewsroomSearch),
        lastSubmission,
        lastUrl
    }
}

export const useSubmissionParam = (name, suppressEvent = false) => {
    const {submission, setSubmissionParam} = useSearch()

    const updateParam = (value) => {
        setSubmissionParam(name, value, suppressEvent)
    }

    return [
        submission[name] ?? null,
        updateParam
    ]
}

export const useLastParam = (name, suppressEvent = false) => {
    const {lastSubmission, setSubmissionParam} = useSearch()

    const updateParam = (value) => {
        setSubmissionParam(name, value, suppressEvent)
    }

    return [
        lastSubmission[name] ?? null,
        updateParam
    ]
}

export const isFiltering = (params = ['country', 'topic', 'type', 'q']) => {
    const urlIsFiltering = () => {
        const uri = URI(lastUrl)
        return params.some(param => uri.hasQuery(param))
    }

    const {lastUrl} = useSearch(true)
    const [isFiltering, setIsFiltering] = useState(urlIsFiltering(lastUrl))

    useEffect(() => {
        setIsFiltering(urlIsFiltering(lastUrl))
    }, [lastUrl])

    return isFiltering
}
