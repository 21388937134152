import React from 'react';
import {html} from '../helpers'

export default class MalalaSignature extends React.Component {
    svg = `<svg x="0px"
             y="0px"
             viewBox="0 0 254.2 128.6"
             xml:space="preserve">
            <defs>
                <clipPath id="clipSignature">
                    <path class="signature"
                          d="M245.8,72.5h-1.4c-1,0-1.9,0-2.7,0c-3.6-0.3-6.3-1.7-8.1-4.2c-3.9-5.6-7.6-12.5-5.4-21.2
            c0.7-2.8,0.4-4.9-0.8-6.1s-3.6-1.4-6.6-0.5c-1.7,0.6-3.2,1.4-4.6,2.4c-2,1.4-3.9,2.8-5.8,4.5c-5.9,5.2-12.6,11.7-17.5,19.7
            l-0.4,0.6c-1.3,2-3,4.7-0.6,7s5.1,0.5,7.2-0.9l0.2-0.2c2.2-1.5,4.5-3,6.8-4.4l0.7-0.5c-4.2,5.4-9.2,10.1-14.9,13.8
            c-4.5,2.9-10,5.9-16.7,5.9h-0.3c-3.4-0.1-4.8-0.8-5.7-3.2c-1.2-3.1-0.7-4.8,1.7-6.7c7.9-6.2,14.9-13.3,20.9-21.4
            c10.3-13.8,17.7-25.6,23.2-37.2c0.6-1.4,0.9-2.8,1.1-4.3c0.1-0.4,0.1-0.8,0.2-1.2c0.4-2.1,0-4.6-2.3-5.3c-1.9-0.5-3.9-0.1-5.6,1
            c-5.3,4.4-10.3,9-15,14c-7,7.7-13.3,17-19.4,28.5c-4.4,8.3-8.2,15.8-9.8,24.4c-0.1,0.7-0.5,2.5-1.1,2.9c-3.4,1.9-6.9,3.6-10.5,5
            c-0.9,0.3-1.9,0-2.7-0.6c-0.7-0.5-1.2-1.3-1.2-2.2c0.1-1.1,0.1-2.2,0.1-3.2c0.1-4.6,0.2-8.9,3.5-12.6c3-3.4,5.9-7,8.8-10.5l2.4-3
            c1.2-1.4,2.7-3.3,1.3-6.1c-0.2-0.7-0.2-1.4-0.1-2.1c0.1-0.4,0.1-0.8,0.1-1.2c0-0.5,0.1-1.1,0.1-1.6c0.1-1.2,0.1-2.4,0-3.6
            c0-1.2-0.6-2.2-1.6-2.9c-0.9-0.5-2-0.5-3-0.1c-2.5,0.8-4.8,2.1-6.8,3.8c-11.8,11.5-20.5,21.5-27.4,31.5l-0.9,1.2
            c-4.3,6.2-8.7,12.5-9.8,20.5c-0.3,1.1-1.1,2-2.2,2.6c-2.8,1.3-5.7,2.3-8.7,3.1c-2.7,0.8-4.4-0.2-5.4-3c-1.6-4.7,0-8.8,4.3-11.1
            c8.4-4.5,14.7-11.2,19.8-17.1c6.2-7.3,11-15.6,15.1-22.8s8.3-15.3,13.2-25.1c1-2.3,1.4-4.7,1.4-7.2c0.2-1.3-0.3-2.6-1.2-3.5
            c-0.6-0.4-1.7-0.8-3.6-0.2c-2.5,0.9-4.7,2.2-6.6,4c-13.2,12.6-23.3,27.4-32.2,41.5c-5.8,9.2-9.9,19-13,27.5c-0.8,2.5-3.4,4-6,3.3
            c-1.9-0.4-2.9-2.4-2.7-5.4v-0.3c0-1.4,0.2-2.8,0.7-4.1c2.6-6,4.9-10.8,7.1-15.3c1.3-2.7,2.8-5.2,4.5-8.1c0.8-1.4,1.7-2.9,2.6-4.5
            l0.4-0.7h-0.8c-1.5,0.1-2.5-0.1-3.1-0.7c-0.5-0.8-0.8-1.7-0.6-2.6c0-1.2,0-2.6-1.1-3.5l-0.1-0.1h-0.1c-3.9-0.5-6.7,1.9-9.1,4.5
            l-2.2,2.4C80.4,51.5,74.8,57.6,69.7,64c-6.1,7.6-12.1,15.9-14.1,26.3c-0.4,2.1-1.6,3.3-4.4,4c-0.1-0.4-0.2-0.8-0.3-1.2
            c-0.3-1-0.5-2-0.5-3c0.3-2.3,1-4.6,1.9-6.8C57.4,70.5,64.1,58.8,70,49l0.9-1.5c6.4-10.5,13-21.3,17.4-33c0.2-0.5,0.4-1,0.6-1.6
            c1.4-3.4,2.9-7.3,1.4-12.2L90.1,0l-0.5,0.5c-0.7,0.7-1.3,1.3-1.9,1.9c-1.2,1.2-2.2,2.2-3.2,3.3c-7.1,8.3-12.8,17.7-18.4,26.8
            c-2.7,4.4-5.5,9-8.5,13.4c-6.3,9.4-13,19.3-19.9,29.4c-0.7,0.8-1.4,1.6-2.3,2.2l-0.6,0.4c0-1.2,0.3-2.4,0.8-3.6
            c1.3-2.9,2.7-5.7,4-8.6c2.6-5.6,5.3-11.4,7.8-17.2c4.8-11.5,7.9-23.6,9.1-36c0.2-2.4,0-5-3.2-7.1L53,5.5l-0.2,0.2
            c-0.7,0.7-1.4,1.3-2.1,1.9c-1.6,1.4-3.1,2.8-4.5,4.4c-5.8,6.9-10.8,14.4-14.9,22.3c-0.5,1.2-1.4,2.2-2.5,2.9c0-1.1,0.2-2.1,0.6-3.1
            c0.6-1.9,1.2-3.8-0.2-6.1l-0.2-0.4l-0.4,0.3c-0.3,0.2-0.7,0.5-1,0.7c-0.8,0.3-1.4,0.9-1.9,1.5c-1,2.2-1.8,4.5-2.4,6.8
            c-0.3,1-0.5,2.1-0.6,3.1c-0.2,1.5-0.5,3-1.1,4.4c-1.8,4-3.5,8.2-5.2,12.1C11.8,67.2,7.2,78.2,1.6,88.5C0.2,91-0.9,93.8,1,96.8
            L1.1,97h0.3c2.5,0,3.5-1.6,4.2-3.2c2.1-5.2,4.4-11,7-16.6c9.6-20.4,20.1-42,34.3-61.4l0.6-0.8c1.2-2,2.9-3.7,5-4.9
            c-0.3,2.1-0.6,4.1-0.8,6.1c-0.4,4.7-1.3,9.3-2.6,13.9c-3.2,9.7-7.3,19.3-11.2,28.6l-0.9,2.1c-2,4.7-4.2,9.3-6.4,13.8
            c-1.5,3.3-3.2,6.6-4.7,10c-0.5,1.3-0.8,2.6-1,4c-0.1,0.7-0.2,1.4-0.4,2.2l-0.1,0.6h0.6c5-0.4,7.5-3,9.4-5.6l2.6-3.6
            c6.1-8.4,12.3-17.1,18.1-26C59,50,63,43.7,66.7,37.5c3-4.9,6.1-10,9.2-14.9c0.4-0.6,0.7-1.1,1-1.7c1.7-2.8,3.5-5.7,6.4-7.7
            c-1.8,5.6-4.1,11-6.9,16.2c-2.4,4.5-5.2,8.9-7.8,13.2c-2.6,4.3-5.2,8.6-7.7,13C57,63.1,52.8,71.4,48,81.7c-1.1,2.6-1.8,5.4-2.1,8.2
            l-0.2,1.4c-0.3,2,0.5,4.1,2.1,5.3c1.8,1.5,4.3,1.9,6.4,1.1c1.3-0.5,2.7-0.9,4.1-1.3c1.4-0.3,2.8-0.8,4.2-1.3
            c2.4-0.9,4.7-2.2,6.7-3.9c2.7-2.4,5.2-5.1,7.6-7.6c1.2-1.2,2.4-2.5,3.6-3.7c0.6-0.6,1.2-1.1,1.9-1.7l0.5-0.5
            c1.8,9.3,2.1,9.6,11.6,10.7v3.5c0,4.1,1.2,7.2,3.6,8.8c1.3,0.9,2.9,1.3,4.5,1.3c1.6,0,3.2-0.3,4.7-0.9c5.9-2.1,11.6-4.7,16.7-6.9
            c1.9-0.9,3.6-2.1,5.1-3.5c2.6-2.4,5.2-5,7.7-7.5c1.6-1.6,3.2-3.2,4.8-4.8c0.5-0.5,1.1-0.9,1.7-1.3l0.5-0.4v0.6
            c0,0.8-0.1,1.4-0.1,1.9c0,3.1,0.9,5.6,2.6,7.2c1.9,1.6,4.4,2.2,6.8,1.7c1.9-0.3,3.7-0.9,5.4-1.8c0.6-0.3,1.3-0.6,1.9-0.8
            c0.8-0.3,1.6-0.7,2.5-1.2l0.8-0.4l0.4,0.7c0.3,0.6,0.5,1,0.8,1.5c1.9,4.4,6.6,6.9,11.3,6c5.2-0.5,10.2-2.3,14.6-5.1
            c7.7-5,14.6-11.1,20.5-18c2.7-3.2,5.6-6.3,8.7-9.6c1.2-1.3,2.5-2.7,3.7-4.1c0.2,0.7,0.3,1.3,0.4,1.9c0.3,1.4,0.6,2.8,1,4.2
            c3.3,9.9,9.1,14.5,18.1,14.5h0.2c0.8,0.1,1.6-0.2,2.2-0.7c0.5-0.7,0.7-1.6,0.5-2.5v-0.4L245.8,72.5z M223.4,43.4
            c0.1,0,0.3,0,0.4,0.1c0.7,0.7,1.1,1.6,1.1,2.6c-0.4,1.4-1.1,2.7-2.1,3.7c-6.6,6.9-14.5,13.3-25.1,20c-0.3,0.1-0.5,0.1-0.8,0.1h-0.4
            l1.1-1.6c1.1-1.6,2.1-2.9,3-4.3c5.8-8,13.5-14.2,21-20.1C222.2,43.6,222.8,43.4,223.4,43.4L223.4,43.4z M170.5,74
            c-0.4-12.3,24.4-48,41.2-62.4c1.8,1.4,2.1,3,0.8,5.4c-2.4,4.5-5.2,9.9-8.4,15.3s-6.9,11.6-11,17.3c-6,8.4-12.8,17.2-21.9,24
            C171,73.7,170.8,73.8,170.5,74z M161,44.4c-0.7,1.7-1.5,3.4-2.6,4.9l-1.1,1.6c-8.5,12.1-17.3,24.7-28.4,35c-2.2,2-4.8,3.5-7.6,4.4
            c0-0.2,0.1-0.5,0.1-0.7c0.1-0.8,0.3-1.6,0.6-2.4c2.7-6.5,6.3-12.5,10.8-17.9c7-8.1,14.2-16.2,21.2-24l5.6-6.3
            c0.3-0.2,0.6-0.4,1-0.5l0.6-0.2c1.4,2.2,0.7,3.9,0,5.7L161,44.4z M102.2,80.4c1.7-15,34.7-61.3,47.4-69.2c0.6,4-1,7.2-2.5,10.3
            l-0.2,0.5c-3.6,7.3-7.9,14.9-13,23.3l-1.7,2.8c-3.9,6.4-7.9,13.1-13.3,18.6c-5.1,5.2-9.8,9.8-15.6,13.2
            C103,80.1,102.6,80.3,102.2,80.4L102.2,80.4z M94.8,42.9c-2.5,10.4-23.4,38.2-32.9,44c0.1-0.4,0.2-0.7,0.2-1
            c0.2-1.2,0.6-2.3,1.1-3.4c4.5-7.6,10-16.3,17.6-23.9c3-3.1,5.8-6.4,8.5-9.8c0.9-1.2,1.9-2.3,2.8-3.5c0.6-0.7,1.1-1.5,1.5-2.4
            c0.1-0.2,0.2-0.4,0.3-0.6L94.8,42.9z M84.5,67.8c-2.3,5-5.8,9.7-11,14.5l-0.2-0.1L84.5,67.8z"/>
                    </clipPath>
                    
                    <clipPath id="clipUnderline">
                           <path class="underline" d="M33.4,128.2c1.6-1.6,3.6-2.6,5.8-2.9c3.9-0.6,7.9-1.2,11.9-1.3c7.4-0.3,14.7-0.4,22.1-0.5
			c5.8-0.1,11.6-0.7,17.4-1.3c19.5-1.9,38.6-6.1,57.6-10.9c10.3-2.6,20.7-4.5,31.2-5.7c4.6-0.5,9.3-0.9,14-1.1
			c5.9-0.2,11.8-0.4,17.7-0.3c8.4,0.2,16.9,1,25.2,2.4c6.4,1.1,12,3.9,17.9,7.4c-0.6,0.1-0.8,0.1-1,0.1s-0.6,0-0.9,0
			c-4.2,0-8.4,0.2-12.6,0.1s-8.5-0.8-12.8-1.1c-4-0.3-8-0.7-11.9-0.8c-8.4-0.1-16.9-0.1-25.3,0c-7.3,0.2-14.6,0.9-21.8,2
			c-11.8,1.6-23.3,4.5-35,6.9c-9.4,2.1-19,3.7-28.6,4.9c-6.9,0.7-13.9,1.2-20.8,1.9c-5,0.3-10,0.4-15,0.1
			c-7.9-0.2-15.8-0.1-23.7-0.1c-2.2,0-4.4,0.3-6.7,0.4C36.7,128.6,35.3,128.4,33.4,128.2z"/>
                    </clipPath>
    
            </defs>
            <g clip-path="url(#clipSignature)"
               stroke-width="5">
                <path class="signature-mask"
                      fill="none"
                      stroke="#fff"
                      stroke-miterlimit="5"
                      d="M30.8,21.7l-3,10.9L6.7,75.5L0.8,97.6l33.8-66.5l12.8-18.6l5.9-5.2h2l-1,8.4l-6.5,25.1l-8.4,20.4l-8.6,18
		c0,0-6,11.7-5.8,11.6c0.2-0.2,8.5-6.3,8.5-6.3l4.2-6.8l18.1-25.3L90.1,0l-2.3,11.2l-13,26.3l-8.6,14.2L62.4,58L50.5,81.6l-2.9,9
		l2.3,5.7l7.1-2l12.8-10.8l5-7.4l3.5-4.8l5.8-6.8l12.2-21.4l-0.4-3l-1.2-1.4l-5.5,6.4l-16,18.4l-6,7.5l-5.5,9.4l-3.9,8.8l-0.1,3.6
		l4,1.7l9.8-5.6l12-14.7l3.1-4.9l15.4-26.1L84.5,79.5l1.9,5.1l2.6,1.5l9.4-1.3l7.7-3.4l3.6-2.7l6.2-7.8l8.4-9.4l17.4-25l9.7-23
		l-1.5-3.7L147,9.7l-7.7,7.3l-9.5,11l-18.1,25.2L103.6,70l-2.8,4.5l0.2,2.6l-2.6,3.3l-2.1,13.8l3.2,5l7.1,1l7.5-3.5l4.4-1.5l1.1-0.6
		l6.5-3.5l9.1-9.3L146,69.4l15.6-22.8l1.9-8l-1.5-2.9l-4.8,2.6l-6.5,6.8l-16.8,18.8l-13,18.9l-1,2.2l0.8,3.9l-4-2l-0.4,4.9l5.4,3.4
		l43.5-48.8l-20.4,32.4l2.9,0.9l-2.7,3.5l3,2.5l7.7,0.1l3.3-1.6c0,0,7.1-3.8,7.2-3.8l3.4-2.6l1.7-1.1l2.5-2.7l4.6-4.5l25.1-30.8
		l8-17.3l3.2-5.9l-1.2-6.8l-5.7,2.9c-10.6,11.4-20.4,22.7-28.6,35.8c-3.9,6.4-8.9,15.2-10.3,22.5c-0.5,2.5-0.8,1.7-1.6,4.1
		c-1.2,2.1-1,6-0.7,10l0.1,3.2l5.9,3.2l12.4-1.5l13.9-9.3l5.9-4.2l5.4-6.8L220.8,55l0.3-1.4l-17.3,15L193.5,74l-2.6-0.6l4.4-5.6
		l1.7-3.2L215.9,45l6.4-4l4.4,0.1v4.7l-1.1,9.8l1.5,6l6.4,10.3l12.9,2.8"
                />
            </g>
            
            <g clip-path="url(#clipUnderline)"
               stroke-width="8">
                <path class="underline-mask"
                      fill="none"
                      stroke="#fff"
                      stroke-miterlimit="8"
                      d="M26.6,127.5 38.4,127.5 44.8,125.9 61.4,125.9 86.1,125.3 122.4,120.1 138.1,117 175.9,108.8
	204.7,108.1 231.2,110.2 254.9,113.6"
                />
             </g>

          </svg>`

    render() {
        return (
            <div className={'signature--malala'} dangerouslySetInnerHTML={html(this.svg)}>
            </div>
        );
    }

    renderSvg() {
        const {svg} = this.state

        return svg
    }
}
