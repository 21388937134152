import React from 'react';
import PropTypes from "prop-types";
import ContentfulImage from './ContentfulImage'
import NoSsr from './NoSSR'
import classnames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";

/**
 * Image Carousel
 * //////////////
 *
 * Props:
 * ------
 *
 * images (array): Array of Contentful Image Objects
 * height (string): Set all the images to a specific height
 */
export default class ImageCarousel extends React.Component {
    swiper

    componentDidMount() {
        setTimeout(() => this.refresh(), 100)
        setTimeout(() => this.refresh(), 500)
        setTimeout(() => this.refresh(), 1000)
        setTimeout(() => this.refresh(), 3000)
    }

    render() {
        return <NoSsr>
            <div className={"container"}>
                <Swiper className={classnames(
                    'image-carousel',
                )}
                        spaceBetween={24}
                        slidesPerView={2}
                        slidesPerGroup={1}
                        navigation
                        loop={true}
                        watchSlidesVisibility
                        watchSlidesProgress
                        observer="true"
                        simulateTouch={false}
                        onSwiper={swiper => this.swiper = swiper}
                >
                    {this.renderSlides()}
                </Swiper>
            </div>
        </NoSsr>
    }

    calculateHeightAttribute() {
        const {width, height} = this.props

        return `${(height / width) * 100}%`
    }

    renderSlides() {
        const {images} = this.props

        return images.map((image, i) => {
            const isEntry = !!image.image
            return <SwiperSlide key={`image_${i}`}>
                <div className={"image-carousel__image__wrapper"}
                     style={{"--image-height": this.calculateHeightAttribute()}}>
                    {isEntry ? this.renderImageEntry(image) : this.renderImage(image)}
                </div>
            </SwiperSlide>
        })
    }

    renderImageEntry(imageEntry) {
        return <a href={imageEntry.link} title={imageEntry.title}>
            {this.renderImage(imageEntry.image)}
        </a>
    }

    renderImage(image) {
        const {height, width} = this.props

        return (
            <ContentfulImage
                image={image}
                className={"image-carousel__image"}
                height={height}
                width={width}
            />
        )
    }

    refresh() {
        if (this.swiper) {
            this.swiper.update()
        }
    }
}

ImageCarousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    height: PropTypes.number
};