import React from "react";
import NoSSR from './NoSSR'
import PropTypes from "prop-types";
import Video from "./Video";
import ExecutionEnvironment from "exenv";

export default class BackgroundVideo extends React.Component {

    /**
     * NetworkInformation
     * @returns {*}
     */
    get connection() {
        if (!ExecutionEnvironment.canUseDOM) {
            return null
        }

        if (typeof navigator !== 'undefined') {
            return null
        }

        return navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    }

    /**
     * If the effective connection is 2g or 3g speeds, we don't want to render the video, and we should use the fallback instead.
     * @returns {boolean|*}
     */
    get hasWeakConnection() {
        if (!this.connection || !this.connection.effectiveType) {
            //Effective type not supported in this browser
            return false
        }

        return this.connection.effectiveType.includes('2g')
            || this.connection.effectiveType.includes('3g')
    }

    /**
     * Render the children asa a fallback.
     * @returns {React.ReactNode}
     */
    renderFallback() {
        const {children,} = this.props

        return children
    }

    /**
     * Render the video.
     * @returns {React.ReactNode|*}
     */
    renderVideo() {
        const {url} = this.props

        //Is the connection too weak for video?
        if (this.hasWeakConnection) {
            return this.renderFallback()
        }

        return <figure className="background-video__container overflow-hidden position-fit z--1">
            <video width="320" height="240"
                   className="background-video position-fit object-fit-cover object-position-center" autoPlay loop muted
                   playsInline>
                <source src={url} type="video/mp4"/>
                {this.renderFallback()}
            </video>
        </figure>
    }

    render() {
        return <NoSSR>
            {this.renderVideo()}
        </NoSSR>
    }
}

Video.propTypes = {
    url: PropTypes.string.isRequired, //HTML5 video URL
};