import React from 'react'
import Image from "./Image";
import Parallax from "./Parallax";
import {accentPath} from '../helpers'

export default class ParallaxWhiteAccents3 extends React.Component {
    css = `
            .parallax__accents__3__white .accent {
                position: absolute;
                z-index: 1;
                top: initial;
                left: initial;
                transition: transform 30ms 0s linear;
                will-change: transform;
            }
            .parallax__accents__3__white .accent-1 {
                left: 10vw;
                top: 60%;
                transform: translateY(var(--accent-3-white)) rotate(135deg);
                width: 2vw;
                height: 2vw;
            }
            .parallax__accents__3__white .accent-2 {
                left: 12vw;
                transform: translateY(calc(var(--accent-3-white)*1.5)) rotate(135deg);
                width: 6vw;
                height: 6vw;
            }
            .parallax__accents__3__white .accent-3 {
                left: 90vw;
                top: 47%;
                transform: translateY(var(--accent-3-white));
                width: 3vw;
                height: 3vw;
            }
            
            
            @media (max-width: 1650px) {
                 .parallax__accents__3__white .accent-1 {
                    left: 8vw;
                }
                .parallax__accents__3__white .accent-2 {
                    left: 10vw;
                }
            }
            
             @media (max-width: 1525px) {
                 .parallax__accents__3__white .accent-1 {
                    left: 6vw;
                }
                .parallax__accents__3__white .accent-2 {
                    left: 8vw;
                }
            }
            
            @media (max-width: 1450px) {
                 .parallax__accents__3__white .accent-1 {
                    left: 4vw;
                }
                .parallax__accents__3__white .accent-2 {
                    left: 6vw;
                }
                .parallax__accents__3__white .accent-3 {
                    left: 95vw;
                }
            }
            
            @media (max-width: 1370px) {
                 .parallax__accents__3__white .accent-1 {
                    left: 2vw;
                }
                .parallax__accents__3__white .accent-2 {
                    left: 4vw;
                }
            }
            
            @media (max-width: 1300px) {
                 .parallax__accents__3__white .accent-1 {
                    left: 1vw;
                }
                .parallax__accents__3__white .accent-2 {
                    left: 2vw;
                }
            }
            
            @media (max-width: 1000px) {
                 .parallax__accents__3__white .accent-2 {
                    display: none;
                }
            }
            
            @media (max-width: 1000px) {
                 .parallax__accents__3__white .accent-1 {
                    display: none;
                }
            }

         
            @media (max-width: 658px) {
                 .parallax__accents__3__white .accent-1 {
                    display: block;
                    top: 53%;
                    width: 5vw;
                    height: 5vw;
                }
                
                .parallax__accents__3__white .accent-2 {
                    display: block;
                    width: 11vw;
                    height: 11vw;
                    top: 75%;
                    left: 14vw;
                }
                
                .parallax__accents__3__white .accent-3 {
                    width: 5vw;
                    height: 5vw;
                }
            }
        `

    render() {
        return (
            <Parallax config={
                {
                    "from": "top-bottom",
                    "to": "bottom-top",
                    "props": {
                        "--accent-3-white": {
                            "from": "0vh",
                            "to": "-20vh",
                        }
                    }
                }
            } className={`parallax__accents__3__white position-fit`}>
                <Image
                    src={accentPath('accent-5-white')}
                    className={'accent accent-1'}
                    key={'accent-1'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-3-white')}
                    className={'accent accent-2'}
                    key={'accent-2'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-4-white')}
                    className={'accent accent-3'}
                    key={'accent-3'}
                    alt={""}
                />
                <style type="text/css" dangerouslySetInnerHTML={{__html: this.css}} key={'style'}/>
            </Parallax>
        )
    }
}
