import Plyr from "plyr"

export const plyr = (el) => {
    new Plyr(el, {
        youtube: {
            noCookie: true,
        }
    });
}

export default plyr