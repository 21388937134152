import React from "react";
import Modal from "./Modal";
import classnames from "classnames";
import ContentfulImage from "./ContentfulImage";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Buttons from "./Buttons";
import kebabCase from "just-kebab-case";
import { debounce } from "throttle-debounce";

/**
 * Popup
 * /////
 *
 * An image based modal that opens at page load.
 *
 * Props
 * -----
 * image: The desktop image
 * mobileImage: The mobile image
 * className: The css class(es) to apply to the container.
 * linkUrl: The URL the image should link to.
 * start: The date the W3C date the modal should show from
 * end: The date the W3C date the modal should stop showing
 * id: A unique ID for the modal to base the cookie key off of.
 */
export default class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cookieName: `popup-${props.id}`,
      loaded: props.image ? false : true,
      display: false,
      scrolled: false,
    };

    //bind this to handlescroll to avoid reference issues
    this.handleScroll = debounce(50, this.handleScroll.bind(this));

    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  handleScroll() {
    this.setState({ scrolled: true });
    window.removeEventListener("scroll", this.handleScroll);
  }

  /**
   * When the component mounts, check to see if we should show the popup. If so, set the cookie.
   */
  componentDidMount() {
    if (this.shouldDisplay()) {
      this.setState({ display: true });
      this.setCookie();
    }
  }

  /**
   * Should we display the popup?
   * @returns {boolean}
   */
  shouldDisplay() {
    const { start, end } = this.props;
    const now = new Date();
    const url = new URL(window.location.href);

    if (url.searchParams.get("modal")) {
      return true;
    }

    //Is it before the start date?
    if (start) {
      const startDate = new Date(Date.parse(start));
      if (now < startDate) return false;
    }

    //Is it after the end date?
    if (end) {
      const endDate = new Date(Date.parse(end));
      if (now >= endDate) return false;
    }

    //Does the cookie exist
    if (this.cookieExists()) {
      return false;
    }

    return true;
  }

  /**
   * Does the tracking cookie exist?
   * @returns {boolean}
   */
  cookieExists() {
    const { cookieName } = this.state;
    return Cookies.get(cookieName) !== undefined;
  }

  /**
   * Set a cookie to let us know that the user has already seen the popup.
   */
  setCookie() {
    const { cookieName, useCookie } = this.state;

    if (!useCookie) {
      return;
    }

    Cookies.set(cookieName, true, { expires: 360 });
  }

  /**
   * Build up the class name.
   * @returns {*}
   */
  getClassName() {
    const { className, patternBar } = this.props;

    let classes = {
      popup: true,
    };

    if (className) {
      classes[className] = true;
    }

    classes["has-pattern-bar"] = patternBar !== null;

    return classnames(classes);
  }

  isActive() {
    const { showOn } = this.props;
    const { scrolled } = this.state;

    if (showOn === "Load") {
      return true;
    }

    if (showOn === "Scroll") {
      return scrolled;
    }

    return false;
  }

  /**
   * When the image has loaded, add a flag to the state so we know to show the close button.
   */
  handleImageLoad() {
    this.setState({ loaded: true });
  }

  render() {
    const {
      id,
      image,
      mobileImage,
      backgroundImage,
      linkUrl,
      content,
      buttons,
      patternBar,
      position,
      size,
    } = this.props;
    const { loaded, display } = this.state;

    if (!display) {
      return false;
    }

    const closeColor = !image ? "black" : "white";

    return (
      <Modal
        className={this.getClassName()}
        active={this.isActive()}
        close={loaded}
        closeColor={closeColor}
        size={size ? kebabCase(size) : null}
        id={id}
        position={position ? kebabCase(position) : null}>
        <div>
          {backgroundImage ? (
            <ContentfulImage
              image={backgroundImage}
              className="modal__background-image"
              lazy={false}
              onLoad={this.handleImageLoad.bind(this)}
            />
          ) : (
            ""
          )}
          <a href={linkUrl}>
            {image ? (
              <ContentfulImage
                image={image}
                mobileImage={mobileImage}
                className="modal__image display-block img-fluid width-100"
                lazy={false}
                onLoad={this.handleImageLoad.bind(this)}
              />
            ) : (
              ""
            )}
          </a>
          {content ? (
            <div
              className="modal__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            ""
          )}

          {buttons ? (
            <div className="modal__buttons">
              <Buttons buttons={buttons} />
            </div>
          ) : (
            ""
          )}
        </div>
        {patternBar ? (
          <div
            className="modal__pattern-bar"
            style={{ backgroundImage: `url(${patternBar.src})` }}
          />
        ) : (
          ""
        )}
      </Modal>
    );
  }
}

Popup.defaultProps = {
  useCookie: true,
};

Popup.propTypes = {
  id: PropTypes.string.isRequired,
};
