import React from "react";
import Search from "./Search";
import {imagePath} from "../helpers";
import classnames from "classnames";

export default class HeaderSearch extends React.Component {
    state = {
        opened: false,
        focus: false,
        loaded: false,
    };

    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    compponentDidMount() {
        setTimeout(() => this.setState({loaded: true}), 1000);
    }

    render() {
        const {opened, loaded} = this.state;

        return (
            <div
                className={classnames(
                    "header__search no-hover flex flex-row flex-stretch",
                    {"header__search--opened": opened},
                    {"header__search--loaded": loaded}
                )}>
                <div className="header__search__form-container"
                     aria-hidden={!opened}
                >
                    <Search
                        onFocus={this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        ref={this.form}
                    />
                </div>
                <div className="header__search__icon flex flex-align-items-center flex-justify-items-center">
                    <img
                        role="button"
                        src={this.getIcon()}
                        onClick={this.toggle.bind(this)}
                        alt="Toggle search"
                        aria-label={"Toggle Search"}
                        className="icon display-none display-lg-inline-block"
                        width={21}
                        height={21}
                    />
                    <a
                        onClick={this.search.bind(this)}
                        className={"display-lg-none header__search__button"}>
                        <img
                            src={imagePath("ui/ui-search.svg")}
                            alt="Search"
                            className="icon"
                            width={27}
                            height={27}
                        />
                    </a>
                </div>
            </div>
        );
    }

    open() {
        this.setState({opened: true});
    }

    close() {
        this.setState({opened: false});
    }

    hasFocus() {
        return this.state.focus;
    }

    handleFocus() {
        this.setState({focus: true});
    }

    handleBlur() {
        this.setState({focus: false});
    }

    toggle() {
        if (this.state.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    search(e) {
        this.form.current.search(e);
    }

    getIcon() {
        return this.state.opened
            ? imagePath("ui/ui-close.svg")
            : imagePath("ui/ui-search.svg");
    }
}
