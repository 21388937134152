import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames'

/**
 * Pattern Bar
 * ///////////
 *
 * size: (string) short|tall Default: short
 * image: (object) Contentful Image Object
 */
export default class PatternBar extends React.Component {

    render() {
        const {image, size, className} = this.props;

        if (!image) {
            return ''
        }

        return (
            <div className={classnames('pattern-bar', `pattern-bar--${image.title}`, `pattern-bar--${size}`, className)}
                 style={{
                     backgroundImage: `url(${image.src})`,
                     backgroundRepeat: 'repeat'
                 }}
            ></div>
        );
    }
}

PatternBar.defaultProps = {
    size: 'short'
};

PatternBar.propTypes = {
    image: PropTypes.object.isRequired,
    size: PropTypes.string
};

