import React, {useState} from 'react'
import classnames from "classnames";
import {chunk} from "../helpers";
import uniqid from 'uniqid'

const StaggerGridItem = ({child, idx}) => {
    let odd = idx % 2 !== 0

    return (
        <div className={classnames(
            'stagger-grid__item',
            "column-half",
            {'column-lg-third': odd},
            {'column-lg-quarter': !odd}
        )}>
            {child}
        </div>
    )
}

const StaggerGridItemMobile = ({child}) => {
    return (
        <div className={classnames(
            'stagger-grid__item',
            'margin-bottom-4',
            'width-full'
        )}>
            {child}
        </div>
    )
}

const StaggerGridRow = ({idx, children}) => {
    let odd = idx % 2 !== 0
    let singleItem = children.length === 1

    return <div className={classnames(
        "stagger-grid__row row margin-bottom-8",
        singleItem ? 'flex-justify-center' : '',
        (!singleItem) && odd ? "flex-justify-end" : '',
        (!singleItem) && !odd ? 'flex-justify-start' : ''
    )}>
        {children}
    </div>
}

const StaggerGrid = ({children}) => {
    let childIdx = 0
    return <div className={classnames("stagger-grid")}>
        <div
            className={classnames("display-none", "display-lg-block", "stagger_grid_items" )}>
            {chunk(children, 3).map((row, idx) => {
                return <StaggerGridRow idx={idx}
                                       key={`stagger-grid-row-${idx}`}>
                    {row.map(child => {
                        childIdx++
                        return <StaggerGridItem idx={childIdx}
                                                child={child}
                                                key={child.key}
                                                />
                    })}
                </StaggerGridRow>
            })}
        </div>
        <div className={"container"}>
            <div className={"stagger-grid__row row flex-justify-center display-lg-none"}>
                {children.map((child, idx) => <StaggerGridItemMobile child={child} key={child.key}/>)}
            </div>
        </div>
    </div>
}

export default StaggerGrid