import React, {useEffect, useState, useRef} from "react";
import PDFJS from "@bundled-es-modules/pdfjs-dist/build/pdf";
import {Swiper} from "swiper";
import classNames from "classnames";
import {format} from "date-fns";
import {DATE_FORMAT_DOT} from "../constants";
import {imagePath} from "../helpers";
import ExecutionEnvironment from "exenv";

PDFJS.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

/**
 * A carousel that transforms PDF pages to canvas to use as slides.
 * @param url
 * @param link
 * @param download
 * @param title
 */
const PdfCarousel = ({url, link, download, title = ""}) => {
    const [numPages, setNumPages] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [pages, setPages] = useState([]);
    const [loaded, setloaded] = useState(false);
    const previews = useRef([]);
    const swiperRef = useRef();

    useEffect(() => {
        if (!loaded || !previews.current.length || !pages.length) {
            return;
        }

        const swiper = new Swiper(swiperRef.current, {
            loop: false,
            simulateTouch: true,
            shortSwipes: true,
            slidesPerView: "auto",
            slidesPerGroup: 1,
            spaceBetween: 5,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            observer: true,
            observeParents: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                768: {
                    shortSwipes: false,
                },
            },
        });

        const handleChange = () => {
            setActiveSlideIndex(swiper.activeIndex);
            setTotalSlides(swiper.slides.length);
        };

        swiper.on("activeIndexChange", handleChange);
        swiper.on("afterInit", handleChange);
        swiper.on("update", handleChange);

        return () => {
            swiper.off("afterInit", handleChange);
            swiper.off("activeIndexChange", handleChange);
            swiper.off("update", handleChange);
            swiper.destroy();
        };
    }, [swiperRef, previews, loaded, pages.length]);

    if (link && link.url && !link.label) {
        link.label = "More Info";
    }

    useEffect(() => {
        const parsePdf = async () => {
            if (!ExecutionEnvironment.canUseDOM) {
                return;
            }

            const loadingTask = await PDFJS.getDocument(url);
            const pdf = await loadingTask.promise;
            const numPages = pdf.numPages;
            setNumPages(numPages);
            let promises = Array.from({length: numPages}, (_, i) => i + 1).map(
                (pageNum) => pdf.getPage(pageNum)
            );
            const rendered = await Promise.all(promises);
            setPages(rendered);
            setloaded(true);
        };

        parsePdf();
    }, [url]);

    useEffect(() => {
        if (!loaded || !previews.current.length || !pages.length) {
            return;
        }

        const renderPage = async (page, idx) => {
            const canvas = previews.current[idx];
            const canvasContext = canvas.getContext("2d");
            const pageViewport = page.getViewport({
                scale: 1,
                dontFlip: true,
            });
            const ratio = pageViewport.width / pageViewport.height;

            canvas.width = (window.screen.availWidth * window.devicePixelRatio) / 2;
            canvasContext.width = canvas.width;
            canvas.height = (canvas.width / ratio) | 0;
            canvasContext.height = canvas.height;
            const viewport = pageViewport.clone({
                scale: canvas.width / pageViewport.width,
                rotation: 0,
            });
            return page.render({
                canvasContext,
                viewport,
            });
        };

        const renderPages = async () => {
            const renderPromises = pages.map(renderPage);
            await Promise.all(renderPromises);

            if (swiperRef.current.swiper) {
                swiperRef.current.swiper.update();
            }
        };

        renderPages();
    }, [previews, loaded, pages.length, swiperRef]);

    const save = async () => {
        if (navigator.canShare) {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            const file = new File([buffer], url.split("/").pop(), {
                type: "application/pdf",
            });
            const files = [file];

            if (navigator.canShare({files})) {
                navigator.share({
                    files,
                    title,
                    url: window.location.href,
                });
            } else {
                window.open(download, "_blank");
            }
        } else {
            window.open(download, "_blank");
        }
    };
    if (pages.length <= 0) {
        return "";
    }

    return (
        <div className={"pdf-carousel swiper-outer"}>
            <div className={"container relative"}>
                <div className="swiper" ref={swiperRef}>
                    <div className="swiper-wrapper">
                        {pages.map((page, idx) => (
                            <div className={"swiper-slide"} key={"pdf-canvas-" + idx}>
                                <canvas ref={(el) => (previews.current[idx] = el)}/>
                            </div>
                        ))}
                    </div>
                    <div className={"pdf-carousel__toolbar"}>
                        <div className={"display-flex flex-grow-1"}>
                            <div className="swiper-pagination display-none display-lg-block"/>
                        </div>
                        <div className={"display-flex flex-grow-0"}>
                            <a
                                className={"button--link no-wrap"}
                                href={url}
                                target={"_blank"}>
                                View PDF
                            </a>
                            <div
                                className={"button--link display-none display-lg-block no-wrap"}
                                onClick={save}>
                                Save
                            </div>
                            {link ? (
                                <a
                                    className={
                                        "button--link display-none display-lg-block no-wrap"
                                    }
                                    href={link.url}
                                    target={"_blank"}
                                    title={link.label}>
                                    {link.label}{" "}
                                    <img
                                        alt={""}
                                        className={"pdf-carousel__link-icon"}
                                        src={imagePath("ui/ui-external-link.svg")}
                                        width={12}
                                    />
                                </a>
                            ) : null}
                            <strong className={"pdf-carousel__page-count display-lg-none"}>
                                <span className={"count__current"}>{activeSlideIndex + 1}</span>
                                /<span className={"count__total"}>{totalSlides}</span>
                            </strong>
                        </div>
                    </div>
                    <div className="swiper-button-prev" aria-label={"Previous"}/>
                    <div className="swiper-button-next" aria-label={"Next"}/>
                </div>
            </div>
        </div>
    );
};

export default PdfCarousel;
