import React, {useEffect, useRef, useState} from 'react';
import RichText from "./RichText";
import ContentfulImage from "./ContentfulImage";
import classNames from "classnames";
import {Scrollama, Step} from "react-scrollama";
import {Swiper, SwiperSlide} from "swiper/react";
import ErrorBoundary from "./ErrorBoundary";

const StickyQuotes = ({quotes, heading, backgroundColor}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const swiperRef = useRef()
    const stepsRef = useRef()

    const onStepEnter = ({data}) => {
        setCurrentStepIndex(data);
    };

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(currentStepIndex);
        }
    }, [currentStepIndex, swiperRef]);

    return <ErrorBoundary>
        <section className={classNames('sticky-quotes relative', `sticky-quotes--${backgroundColor}`)}>
            <div className={"sticky-quotes__sticky"}>
                <Swiper
                    ref={swiperRef}
                    className="sticky-quotes__carousel"
                    initialSlide={currentStepIndex}
                    slidesPerGroup={1}
                    loop={false}
                    watchSlidesVisibility
                    watchSlidesProgress
                    observer="true"
                    simulateTouch={true}
                    pagination={{
                        clickable: true,
                    }}
                    slidesPerView={1}>
                    {quotes.map(({quote, cite, image, mobileImage}, stepIndex) => <SwiperSlide
                        key={stepIndex}
                        className={classNames("sticky-quotes__slide", `sticky-quotes__slide--${stepIndex}`)}>
                        {stepIndex === 0 ? <heading className={"sticky-quotes__heading relative"}>
                            <div className={"container"}>
                                <h2>
                                    {heading}
                                </h2>
                            </div>
                        </heading> : ""}

                        <div className={"sticky-quotes__container container"}>
                            <div className={"sticky-quotes__content"}>
                                <div className={"sticky-quotes__accent"}/>
                                <div className={"sticky-quotes__quote_wrap"}>
                                    <quote className={"sticky-quotes__quote"}>
                                        <RichText content={quote}/>
                                    </quote>
                                    <cite className={"sticky-quotes__cite"}>
                                        <RichText content={cite}/>
                                    </cite>
                                </div>
                            </div>
                        </div>
                        <div className="quotes__image">
                            <ContentfulImage image={image} mobile-image={mobileImage}/>
                        </div>
                    </SwiperSlide>)}
                </Swiper>
                <div className={"sticky-quotes__footer"}>
                    <div className={"container"}/>
                </div>
            </div>
            <div className={classNames("sticky-quotes__steps")} ref={stepsRef}>
                <Scrollama
                    onStepEnter={onStepEnter}
                    offset={1}
                >
                    {quotes.map((_, stepIndex) => <Step data={stepIndex} key={stepIndex}>
                        <div
                            className={classNames("sticky-quotes__step", `sticky-quotes__step--${stepIndex}`)}
                        />
                    </Step>)}

                </Scrollama>
            </div>
        </section>
    </ErrorBoundary>
}

export default StickyQuotes