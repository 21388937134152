import React from 'react';
import axios from 'axios'
import qs from 'querystring'
import classnames from 'classnames'
import trilogyForm from '../domHooks/trilogyForm'

/**
 * Trilogy form component. Takes the HTML for a trilogy form, and converts it to submit via the AJAX api.
 */
export default class TrilogyForm extends React.Component {
    errorMessage = "Oops, something went wrong. Please try again."
    validationMessage = "Something went wrong. Please review your submission and try again."
    successMessage = "Thank you! We have received your submission."

    constructor(props) {
        super(props);
        const {children} = props;
        this.form = React.createRef();
        React.Children.only(children)  //Make sure there is only one child (the form)
    }

    componentDidMount() {
        trilogyForm(this.form.current)
    }

    /**
     * Render the form.
     * @returns {*}
     */
    render() {
        let {children} = this.props
        children = React.Children.map(children, child =>
            React.cloneElement(child, {
                ref: this.form,
            })
        );
        return <React.Fragment>
            {children}
        </React.Fragment>
    }
}