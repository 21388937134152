import React from 'react';

/**
 * Represents a simple header component with a logo and an optional link.
 *
 * @param {string} logoURL - The URL of the logo image.
 * @param {string} [logoLink="/"] - The URL to be navigated when the logo is clicked (optional).
 * @returns {React.Element} The rendered header component.
 */
const HeaderSimple = ({logoURL, logoLink = "/"}) => {
    const Image = <img className="header__logo" src={logoURL} width="205" height="49" alt="Malala Fund"/>

    return <header className="header header--simple absolute-top-left width-full padding-y-4 z-1000">
        <div className={"container"}>
            {
                logoLink ? <a href={logoLink} title={"Malala Fund"}>{Image}</a> : Image
            }
        </div>
    </header>
}

export default HeaderSimple
