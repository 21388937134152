import React from 'react';
import RPicture from "./Picture";

/**
 * Contentful Image
 * ////////////////
 *
 * A abstracted wrapper for the Image component for easy use with images from Contentful.
 * If the image has an 2x at the end of it's file name, a non retina src will be fetched from the,
 * Contentful image API.
 *
 * Props
 * -----
 * image: The image object
 * mobileImage: The mobile image object
 * className: A CSS class to add to the image.
 * onLoad: A callback to be called when the image is loaded.
 * lazy: Should the image be lazy loaded?
 * focalPoint: top|left set the object fit.
 *
 */
export default class ContentfulImage extends React.Component {

    breakpoints = [360, 500, 600, 700, 804, 992, 1040, 1600, 1040, 1200, 1600, 1840, 2000, 2220, 2840, 3000]

    /**
     * The width of the image. If it can be dynamically resized, cut it in half.
     * @returns {*}
     */
    get width() {
        let {image, width, sizeByFilename} = this.props
        if (width) {
            return width
        }
        if (sizeByFilename && image.title.includes('@2x')) {
            return Math.floor(image.width / 2)
        }
        if (sizeByFilename && image.title.includes('@3x')) {
            return Math.floor(image.width / 3)
        }
        return image.width
    }

    /**
     * The image height
     * @returns {*}
     */
    get height() {
        let {image, height, sizeByFilename} = this.props
        if (height) {
            return height
        }
        if (sizeByFilename && image.title.includes('@2x')) {
            return Math.floor(image.height / 2)
        }
        if (sizeByFilename && image.title.includes('@3x')) {
            return Math.floor(image.height / 3)
        }
        return image.height
    }

    /**
     * The width of the image.
     * @returns {*}
     */
    get widthMobile() {
        const {mobileImage, widthMobile} = this.props
        if (!mobileImage) {
            return widthMobile
        }
        if (widthMobile) {
            return widthMobile
        }
        return mobileImage.width
    }

    /**
     * The mobile image height.
     *
     * @returns {*}
     */
    get heightMobile() {
        let {mobileImage, heightMobile} = this.props
        if (!mobileImage) {
            return heightMobile
        }
        if (heightMobile) {
            return heightMobile
        }
        return mobileImage.height
    }


    /**
     * The width of the image.
     * @returns {*}
     */
    get widthTablet() {
        const {tabletImage, widthTablet} = this.props

        if (!tabletImage) {
            return widthTablet
        }

        if (widthTablet) {
            return widthTablet
        }
        return tabletImage.width
    }

    /**
     * The mobile image height.
     *
     * @returns {*}
     */
    get heightTablet() {
        let {tabletImage, heightTablet} = this.props

        if (!tabletImage) {
            return heightTablet
        }

        if (heightTablet) {
            return heightTablet
        }
        return tabletImage.height
    }

    /**
     * The src, with width and height transforms added
     *
     * @returns {*}
     */
    get dataSrc() {
        const {image, focalPoint} = this.props


        return `${image.src}?w=${this.width}&h=${this.height}&fit=fill&f=${focalPoint}`
    }

    /**
     * The src, with width and height transforms added
     *
     * @returns {*}
     */
    get dataSrcSet() {
        let {image, focalPoint} = this.props

        return this.makeSrcSet(this.breakpoints, image.src, this.width, this.height, 'fill', '', focalPoint)
    }

    /**
     * The src, with width and height transforms added
     *
     * @returns {*}
     */
    get webPSrcSet() {
        let {image, focalPoint} = this.props

        return this.makeSrcSet(this.breakpoints, image.src, this.width, this.height, 'fill', 'webp', focalPoint)
    }

    /**
     * Build up the final srcset
     * @returns {Array}
     */
    get tabletSrcSet() {
        let {tabletImage, focalPoint} = this.props

        if (!tabletImage) {
            return null
        }

        return this.makeSrcSet(this.breakpoints, tabletImage.src, this.widthTablet, this.heightTablet, 'fill', '', focalPoint)
    }

    /**
     * Build up the final srcset
     * @returns {Array}
     */
    get tabletWebPSrcSet() {
        let {tabletImage, focalPoint} = this.props


        if (!tabletImage) {
            return null
        }

        return this.makeSrcSet(this.breakpoints, tabletImage.src, this.widthTablet, this.heightTablet, 'fill', 'webp', focalPoint)
    }


    /**
     * Build up the final srcset
     * @returns {Array}
     */
    get mobileSrcSet() {
        let {mobileImage, focalPoint} = this.props


        if (!mobileImage) {
            return null
        }

        return this.makeSrcSet(this.breakpoints, mobileImage.src, this.widthMobile, this.heightMobile, 'fill', '', focalPoint)
    }

    /**
     * Build up the final srcset
     * @returns {Array}
     */
    get mobileWebPSrcSet() {
        let {mobileImage, focalPoint} = this.props

        if (!mobileImage) {
            return null
        }

        return this.makeSrcSet(this.breakpoints, mobileImage.src, this.widthMobile, this.heightMobile, 'fill', 'webp', focalPoint)
    }

    /**
     * The preview image SRC
     * A smaller version of the regular image
     *
     * @returns {string}
     */
    get preview() {
        let {image, lazy, focalPoint} = this.props

        if (lazy) {
            return `data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`
        }

        const width = Math.floor(this.width / 6)
        const height = Math.floor(this.height / 6)

        return `${image.src}?w=${width}&h=${height}&fit=fill&f=${focalPoint}`
    }

    get type() {
        let {image} = this.props

        if (!image) {
            return ''
        }

        return image.type
    }

    get mobileType() {
        let {mobileImage} = this.props

        return mobileImage ? mobileImage.type : this.type
    }

    get needsSrcSet() {
        let {image} = this.props

        return !(this.type.includes('svg') || image.width < 320)
    }

    makeSrcSet(breakpoints, src, width, height, fit = 'fill', format = '', focalPoint = 'faces') {
        return breakpoints.reduce((carry, breakpoint, i) => {
            if (!i && width >= 4000 && width >= breakpoint) {
                carry.push(`${src}?fm=${format}&fit=${fit}&f=${focalPoint}&w=4000&h=${this.calculateHeight(width, height, 4000)} 4000w`)
            } else if (!i && width >= breakpoint) {
                carry.push(`${src}?fm=${format}&fit=${fit}&f=${focalPoint}&w=${width}&h=${height} ${width}w`)
            } else if (width >= breakpoint) {
                carry.push(`${src}?w=${breakpoint}&h=${this.calculateHeight(width, height, breakpoint)}&fit=${fit}&fm=${format}&f=${focalPoint} ${breakpoint}w`)
            }
            return carry
        }, []).join(', ')
    }

    calculateHeight(oldWidth, oldHeight, newWidth) {
        let aspectRatio = oldWidth / oldHeight
        return Math.floor(newWidth / aspectRatio)
    }

    /**
     *
     */

    /**
     * Proxy to the image component.
     *
     * @returns {*}
     */
    render() {
        let {image, className, onLoad, focalPoint, reveal, style, position, lazy, width, height} = this.props;

        //return <div></div>

        if (!image) {
            return ''
        }

        if (!this.needsSrcSet) {
            return <RPicture
                src={this.dataSrc}
                className={className}
                onLoad={onLoad}
                focalPoint={focalPoint}
                reveal={reveal}
                alt={image.description}
                style={style}
                position={position}
                width={this.width}
                height={this.height}
            />
        }

        if (reveal) {
            //Avoid the reveal competing with the lazy load
            return <RPicture
                src={this.dataSrc}
                className={className}
                onLoad={onLoad}
                reveal={reveal}
                alt={image.description}
                style={style}
                position={position}
                width={this.width}
                height={this.height}
            >
                <source
                    srcSet={this.mobileWebPSrcSet}
                    media="--mobile"
                    type="image/webp"
                />
                <source
                    srcSet={this.mobileSrcSet}
                    media="--mobile"
                    type={this.mobileType}
                />
                <source
                    srcSet={this.tabletWebPSrcSet}
                    media="--tablet"
                    type="image/webp"
                />
                <source
                    srcSet={this.tabletSrcSet}
                    media="--tablet"
                    type={this.type}
                />
                <source
                    srcSet={this.webPSrcSet}
                    type="image/webp"
                />
                <source
                    srcSet={this.dataSrcSet}
                    type={this.type}
                />
            </RPicture>
        }

        return <RPicture
            src={lazy ? this.preview : this.dataSrc}
            dataSrc={lazy ? this.dataSrc : null}
            className={className}
            onLoad={onLoad}
            reveal={reveal}
            lazy={lazy}
            alt={image.description}
            style={style}
            position={position}
            width={this.width}
            height={this.height}
        >
            <source
                data-srcset={lazy ? this.mobileWebPSrcSet : null}
                srcSet={lazy ? null : this.mobileWebPSrcSet}
                media="(max-width: 658px)"
                type="image/webp"
            />
            <source
                data-srcset={lazy ? this.mobileSrcSet : null}
                srcSet={lazy ? null : this.mobileSrcSet}
                media="(max-width: 658px)"
                type={this.mobileType}
            />
            <source
                srcSet={this.tabletWebPSrcSet}
                media="(max-width: 991px)"
                type="image/webp"
            />
            <source
                srcSet={this.tabletSrcSet}
                media="(max-width: 991px)"
                type={this.type}
            />
            <source
                data-srcset={lazy ? this.webPSrcSet : null}
                srcSet={lazy ? null : this.webPSrcSet}
                type="image/webp"
            />
            <source
                data-srcset={lazy ? this.dataSrcSet : null}
                srcSet={lazy ? null : this.dataSrcSet}
                type={this.type}
            />

        </RPicture>
    }
}

ContentfulImage.defaultProps = {
    lazy: true,
    focalPoint: 'faces',
    position: 'center',
    sizeByFilename: false,
    style: {}
}
