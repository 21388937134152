import React from 'react';
import {isExternalUrl} from '../helpers'

/**
 * Link
 * ------
 *
 * Props:
 * label: (string) pass a value instead of children.
 * href: (string) url or path
 * icon: (string|bool) path to an icon image, false to omit the icon, defaults to arrow for external links
 * className
 * title
 * target
 * children
 */
export default class Link extends React.Component {

    constructor(props) {
        const {link, label, blank, href, target, title, icon, className} = props;

        super(props);

        let state = {href, target, title, icon, className, label}

        //Add support for Contentful links
        if (link) {
            state['href'] = link
        }

        if (label) {
            state['title'] = label
        }

        if (blank) {
            state['target'] = "_blank"
        }

        this.state = state
    }

    /**
     * Is the link to an outside site?
     * @returns {*|boolean}
     */
    get isExternal() {
        const {href} = this.state;

        return isExternalUrl(href)
    }

    get content() {
        let {label} = this.state;
        let {children} = this.props

        let content = children
        if (label && !content) {
            content = label
        }

        return content
    }

    /**
     * Render the link
     * @returns {*}
     */
    render() {
        let {children} = this.props
        let {href, className, title, target} = this.state;

        const ariaLabel = target === '_blank' || this.isExternal
            ? title + ' (opens in a new tab)'
            : title

        return (
            <a href={href}
               className={className}
               title={title}
               aria-label={ariaLabel}
               target={target === '_blank' || this.isExternal ? '_blank' : null}>
                {this.content}
                {this.renderIcon()}
            </a>
        )
    }

    /**
     * Render the link icon
     */
    renderIcon() {
        let {icon} = this.state;

        if (!icon && icon !== false && this.isExternal) {
            return <i className="fak fa-arrow-long-right" style={{marginLeft: ".6em", fontSize: '.85em'}}
                      suppressHydrationWarning/>
        }

        if (!icon) {
            return
        }

        return <img
            alt={""}
            src={icon}
            width={18}
            style={{marginLeft: "5px"}}
            className={"display-inline"}
        />
    }
}