import React from 'react';
import NoSSR from './NoSSR'
import ExecutionEnvironment from 'exenv'
import SSRDummy from './SsrDummy'
import {imagePath} from "../helpers";

const ReactSelect = ExecutionEnvironment.canUseDOM ? require('react-select').default : SSRDummy
const components = ExecutionEnvironment.canUseDOM ? require('react-select').components : []

export default class Select extends React.Component {
    render() {
        const props = Object.assign({...this.props}, {
            classNamePrefix: "react-select",
            isClearable: false,
            components: {
                ClearIndicator: props => {
                    const {
                        getStyles,
                        innerProps: {ref, ...restInnerProps},
                    } = props;

                    return (
                        <div
                            {...restInnerProps}
                            ref={ref}
                            style={getStyles('clearIndicator', props)}
                        >
                            <img src={imagePath('ui/ui-close.svg')} width={12} height={12} alt={"Close"}/>
                        </div>
                    );
                },
                DropdownIndicator: (props) => {
                    return (
                        <components.DropdownIndicator {...props}>
                            <img src={imagePath('ui/ui-arrow-down.svg')} width={12} height={12} alt={""}
                                 className={"margin-right-2"}/>
                        </components.DropdownIndicator>
                    );
                }
            },
            theme: (theme) => {
                return {
                    ...theme,
                    colors: {
                        danger: "#e46132",
                        dangerLight: "#f39473",
                        neutral0: "#fff",
                        neutral5: "#f5f3f2",
                        neutral10: "#ECE8E7",
                        neutral20: "#D4CDCA",
                        neutral30: "#C0B3B0",
                        neutral40: "#817C7A",
                        neutral50: "#817C7A",
                        neutral60: "#817C7A",
                        neutral70: "#3c3e3f",
                        neutral80: "#3c3e3f",
                        neutral90: "#05090b",
                        primary: "#233f96",
                        primary25: "#E9EBF4",
                        primary50: "#a7b2d5",
                        primary75: "#6579b6"
                    }
                }
            }
        })

        return <NoSSR>
            <ReactSelect {...props}/>
        </NoSSR>
    }

    renderClearIndicator() {

    }
}