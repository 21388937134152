import React, {useEffect, useRef} from "react";
import ExecutionEnvironment from "exenv";

let scrollReveal = null

if (ExecutionEnvironment.canUseDOM) {
    let ScrollReveal = require("scrollreveal").default;
    scrollReveal = ScrollReveal()
}

const Reveal = ({children, ...options}) => {
    let firstChildElement = React.Children.toArray(children)[0]
    const el = useRef(null)

    useEffect(() => {
        if (!el.current || !scrollReveal) {
            return
        }

        scrollReveal.reveal(el.current, options)
    }, [options, el, scrollReveal])

    return <div className={"reveal"} ref={el}>{children}</div>
}

export default Reveal