import React from 'react'
import CountUpJs from 'react-countup'
import ExecutionEnvironment from "exenv";

let scrollReveal = null

if (ExecutionEnvironment.canUseDOM) {
    let ScrollReveal = require("scrollreveal").default;


    scrollReveal = ScrollReveal()
}


export default class CountUp extends React.Component {

    constructor() {
        super()

        this.placeholder = React.createRef()
        this.state = {
            revealed: false,
            started: false,
            ended: false
        }
    }

    /**
     * Start the reveal after the component loads.
     */
    componentDidMount() {
        const {delay} = this.props
        scrollReveal.reveal(
            this.placeholder.current,
            {
                delay,
                afterReveal: () => this.handleReveal()
            }
        )
    }

    render() {
        const {animate = true, ...props} = this.props
        const {revealed, started, ended} = this.state

        if (!animate || !revealed) {
            return this.renderPlaceholder()
        }

        return <CountUpJs {...props}  />
    }

    renderPlaceholder() {
        const {start, suffix = "", prefix = "", decimals = 0} = this.props

        return <span ref={this.placeholder}>
            {prefix}{start.toFixed(decimals)}{suffix}
        </span>
    }

    handleReveal() {
        this.setState({revealed: true})
    }
}

CountUp.defaultProps = {
    start: 0,
    delay: 0,
}