import React from "react";
import NoSSR from "./NoSSR";
import {html} from "../helpers";
import ExecutionEnvironment from "exenv";
import {decode} from "html-entities";

let scrollReveal = null;

const htmlHasGiveLively = (html) => {
    return html.includes("givelively.org");
};

/**
 * Handles injecting the Give Lively embed in an HTML string.
 *
 * embed: The embed code
 */
export default class GiveLively extends React.Component {
    constructor(props) {
        super(props);
        this.widgetRef = React.createRef();
    }

    /**
     * getter for the formatted content.
     * @returns {string}
     */
    get embed() {
        let {embed} = this.props;

        if (!embed) {
            return "";
        }

        return embed;
    }

    /**
     * Does the content have a GiveLively script?
     */
    get hasEmbed() {
        return htmlHasGiveLively(this.embed);
    }

    /**
     * Inject the script after component mounts to the DOM.
     */
    componentDidMount() {
        if (this.hasEmbed) {
            setTimeout(() => {
                this.inject();
            }, 1);
        }
    }

    render() {
        if (typeof this.embed === "function") {
            return (
                <div className={this.className()} ref={this.widgetRef}>
                    {this.embed()}
                </div>
            );
        }
        return (
            <div
                className={this.className()}
                ref={this.widgetRef}
                dangerouslySetInnerHTML={html(this.embed)}
            />
        );
    }

    render() {
        return (
            <NoSSR>
                <div ref={this.widgetRef} dangerouslySetInnerHTML={html(this.embed)}/>
            </NoSSR>
        );
    }

    /**
     * Rip the give lively URL query from the content, clean it, and inject the script.
     */
    inject() {
        if (!ExecutionEnvironment.canUseDOM) {
            return;
        }

        const defaultSrc =
            "https://secure.givelively.org/widgets/simple_donation/malala-fund.js?show_suggested_amount_buttons=true&show_in_honor_of=${giveLively.show_in_honor_of}&address_required=false&has_required_custom_question=false&suggested_donation_amounts[]=25&suggested_donation_amounts[]=50&suggested_donation_amounts[]=100&suggested_donation_amounts[]=250";
        const url =
            "https://secure.givelively.org/widgets/simple_donation/malala-fund.js";
        const scripExt = ".js";
        const quote = "&#39;";

        //Rip the query from the content
        let query = this.embed.substring(
            this.embed.indexOf(scripExt) + scripExt.length
        );
        query = query.substring(0, query.indexOf(quote));

        //The markdown field can inject HTML tags, so we need to remove them.
        query = query.replace(/(<([^>]+)>)/gi, "_");
        const src = decode(decodeURI(`${url}${query}`));

        //Inject the script
        const gl = document.createElement("script");
        gl.src = query ? src : defaultSrc;
        this.widgetRef.current.appendChild(gl);
    }
}

GiveLively.defaultProps = {
    embed: "",
};

export {GiveLively, htmlHasGiveLively};
