import React from 'react';
import RPicture from "./Picture";
import axios from "axios";
import ReactDOM from "react-dom";
import Html from './Html'
import {imagePath} from "../helpers"
import classnames from 'classnames'
import Translate from './Translate'

const EXPAND_MODE = 'expand';
const DRAWER_MODE = 'drawer';

/**
 * Country Partners
 * ////////////////
 */
export default class CountryPartners extends React.Component {
    countToken = "[partners:count]"
    countClass = "country-partners__count"

    state = {
        partners: [],
        max: 9,
        fetchedPartners: [],
        expanded: false
    };

    /**
     * @returns {Element | Text}
     */
    get el() {
        return ReactDOM.findDOMNode(this)
    }

    /**
     * @returns {HTMLElement}
     */
    get section() {
        return this.el.closest('section')
    }

    /**
     * @returns {number}
     */
    get numPartners() {
        let {partners} = this.state
        return partners.length
    }

    /**
     * @returns {Element}
     */
    get contentContainer() {
        return this.section.querySelector('.country-partners__content')
    }

    /**
     * @returns {HTMLParagraphElement|null}
     */
    get countContainer() {
        if (!this.contentContainer) {
            return null;
        }

        return Array.from(this.contentContainer.querySelectorAll('p')).find((p) => {
            return p.innerHTML.includes(this.countToken) || p.innerHTML.includes(this.countClass)
        })
    }

    /**
     * @returns {Element|null}
     */
    get countEl() {
        if (!this.countContainer) {
            return null;
        }

        return this.countContainer.querySelector('.' + this.countClass)
    }

    /**
     * @returns {number}
     */
    get count() {
        const {partners} = this.state
        return partners.length
    }

    get visiblePartners() {
        const {partners, max} = this.state
        return partners.slice(0, max)
    }

    /**
     * The component has mounted
     */
    componentDidMount() {
        this.displayCount()
        this.fetchPartners()
    }

    /**
     * Render the partners
     * @returns {JSX.Element}
     */
    render() {
        const {expanded} = this.state
        return <div className={"country-partners__wrap translate"} key={"country-partners"}>
            <div className={classnames({
                "country-partners grid margin-top-4": true,
                "country-partners--expanded": expanded
            })}>
                {this.visiblePartners.map(this.renderPartner.bind(this))}
            </div>
            <div className={"flex flex-justify-center margin-top-6"}>
                {this.renderToggle()}
            </div>
        </div>
    }

    renderPartner(partner, idx) {
        const {expanded} = this.state
        const {country, title, description, url} = partner
        const {mode} = this.props
        const isLinkable = url && expanded || mode === DRAWER_MODE

        return <div className={"country-partners__partner bg-white border-top-teal flex"} key={`country-partner${idx}`}>
            <div className={"country-partners__partner-content"}>
                <div className={"country-partners__partner-country font-weight-thin margin-bottom-0"}>
                    {country.title}
                </div>

                {url && expanded || mode === DRAWER_MODE ? this.renderPartnerLink(partner) : this.renderPartnerTitle(partner)}

                {expanded && mode === EXPAND_MODE ?
                    <div className={"font-size-secondary display-none display-md-block translate"}>
                        <Html content={description}/>
                    </div> : ''}
            </div>

            {isLinkable && mode === DRAWER_MODE ? this.renderPartnerIcon(partner) : ''}
        </div>
    }

    renderPartnerIcon({url, title}) {
        const {mode} = this.props
        if (mode !== DRAWER_MODE || !url) {
            return <></>;
        }

        return <a href={url} title={title} className={"country-partners__partner-icon"}>
            <img
                alt={"Right"}
                src={imagePath('ui/ui-arrow-right.svg')}
                width={8}/>
        </a>
    }

    /**
     * Render the partner title
     * @param partner
     * @returns {JSX.Element}
     */
    renderPartnerTitle(partner) {
        const {expanded} = this.state
        const {mode} = this.props
        const {title, url} = partner
        return <h3 className={"h6 country-partners__partner-title text-uppercase font-weight-black"}
                   onClick={this.handleTitleClick.bind(this)}>
            {title} {mode === EXPAND_MODE && url && expanded ?
            <img src={imagePath('ui/ui-long-arrow.svg')} width={"18"} height={"7"} alt={"Arrow"}
                 className={"margin-left-1"}/> : ''}
        </h3>
    }

    handleTitleClick(e) {
        const {mode} = this.props
        if (mode === DRAWER_MODE) {
            return;
        }
        this.handleOpen(e)
    }

    /**
     * Render the partner link
     * @param partner
     * @returns {JSX.Element}
     */
    renderPartnerLink(partner) {
        const {title, url} = partner
        return <a href={url ? url : false} title={`Visit ${title}`} target={"_blank"}>
            {this.renderPartnerTitle(partner)}
        </a>
    }

    renderToggle() {
        return <div onClick={this.handleToggle.bind(this)}
                    className={"cursor-pointer font-size-small tag text-teal text-uppercase text-center font-weight-black translate"}
                    key={"country-partner-toggle"}>
            {this.renderToggleContent()}
        </div>
    }

    renderToggleContent() {
        const {expanded, max, partners} = this.state
        const {mode} = this.props
        let expandLabel = "Expand"
        let collapseLabel = "Collapse"

        if (mode === DRAWER_MODE) {
            expandLabel = "Show all"
            collapseLabel = "Show less"
        }

        if (expanded) {
            return <div>
                <div>
                    <Translate text={collapseLabel} key={'collapse-button'}/>
                </div>
                <div className={"font-size-callout font-weight-semibold line-height-1"}>
                    &mdash;
                </div>
            </div>
        } else if (partners.length > max) {
            return <div>
                <div>
                    <Translate text={expandLabel} key={'expand-button'}/>
                </div>
                <div className={"font-size-callout font-weight-normal line-height-1"}>
                    +
                </div>
            </div>
        }
    }

    /**
     * Fetch the partners from the server
     * @returns {Promise<void>}
     */
    async fetchPartners() {
        const {max} = this.state
        const {mode} = this.props
        try {
            let {data} = await axios.get('/countries/local-partners')
            let partners = data.partners

            //Sort by country and title
            partners.sort((a, b) => {
                if (a.country.title < b.country.title) {
                    return -1
                } else if (a.country.title > b.country.title) {
                    return 1
                } else {
                    return a.title < b.title ? -1 : 1
                }
            })
            this.setState({
                partners,
                max: mode === EXPAND_MODE ? partners.length : max,
            })

            this.displayCount()
            this.contentContainer.classList.remove('visibility-hidden')
        } catch (ex) {
            console.log(ex)
            this.onError()
        }
    }

    /**
     * If the partners fail to load, don't show the section at all
     */
    onError() {
        if (this.section) {
            this.section.classList.add('display-none');
        }
    }

    /**
     * Display the partner count
     */
    displayCount() {
        if (!this.countContainer) {
            return
        }

        if (!this.countEl) {
            let html = this.countContainer.innerHTML
            html = html.replace(this.countToken, `<span className='${this.countClass}'></span>`)
            this.countContainer.innerHTML = html.trim()
        }

        if (!this.countEl) {
            return
        }

        this.countEl.innerText = this.count
    }

    /**
     * Handle the open event
     * @param e
     */
    handleOpen(e) {
        let {expanded} = this.state

        if (!expanded) {
            e.preventDefault()
            this.open()
            if (!this.countContainer) {
                return;
            }
            this.countContainer.scrollIntoView()
        }

    }

    /**
     * Open all the partners
     */
    open() {
        this.setState({expanded: true})
    }

    /**
     * Handle the toggle event
     * @param e
     */
    handleToggle(e) {
        e.preventDefault()
        this.toggle()
        if (!this.countContainer) {
            return;
        }
        this.countContainer.scrollIntoView()
    }

    /**
     * Toggle the partners open or closed
     */
    toggle() {
        const {mode, max: initialMax} = this.props
        let {expanded, max, partners} = this.state
        if (mode === DRAWER_MODE) {
            max = expanded ? initialMax : partners.length
        }
        this.setState({expanded: !expanded, max})
    }
}

CountryPartners.defaultProps = {
    mode: DRAWER_MODE,
    max: 9
}

