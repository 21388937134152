import React from 'react';
import ContentfulImage from './ContentfulImage'
import HTML from './Html'
import Accents from "./Accents";
import ReactResizeDetector from 'react-resize-detector';
import ExecutionEnvironment from 'exenv'
import {calculateResize} from "../helpers";
import ReactDOM from "react-dom";
import classnames from 'classnames'

export default class HeroImage extends React.Component {

    constructor() {
        super();

        this.state = {
            height: '594px',
            mobileHeight: '400px'
        }
    }

    renderContent() {
        const {leftAligned, isWhite, mobileIsWhite, textClass, heroText, reveal} = this.props

        const textStyle = {
            float: leftAligned ? 'left' : 'right',
        }

        let textClassName = 'text display-md-block ';
        if (isWhite) {
            textClassName += 'text-md-white '
        } else {
            textClassName += 'text-md-malala '
        }
        if (mobileIsWhite) {
            textClassName += 'text-white '
        } else {
            textClassName += 'text-malala '
        }
        textClassName += textClass;

        return <ReactResizeDetector refreshMode={'debounce'} refreshRate={500} handleHeight handleWidth onResize={this.handleResize.bind(this)}>
            <div className="container hero-image__content-body padding-y-6">
                <div style={textStyle} className={textClassName}>
                    <HTML content={heroText}
                          reveal={reveal}/>
                </div>
            </div>
        </ReactResizeDetector>
    }

    renderAccents() {
        const {accents} = this.props

        if (!accents) return

        return <div className="hero__accents">
            <Accents slug={accents}/>
        </div>
    }

    render() {
        let {image, mobileImage, className, lazy, focalPoint, textShadow, mobileTextShadow, gradientOverlay, mobileGradientOverlay, verticalAlignment, verticalAlignmentMobile} = this.props;

        const {height, mobileHeight} = this.state

        return <div className={classnames(
            'hero',
            'hero-image',
            className,
            {'hero-image--text-shadow': textShadow},
            {'hero-image--mobile-text-shadow': mobileTextShadow},
            {'hero-image--gradient-overlay': gradientOverlay},
            {'hero-image--mobile-gradient-overlay': mobileGradientOverlay},
            `hero-image--align-${verticalAlignment}`,
            `hero-image--mobile-align-${verticalAlignmentMobile}`
        )} style={{"--hero-height": height, "--hero-height-mobile": mobileHeight}}>
            <ContentfulImage
                image={image}
                mobileImage={mobileImage}
                lazy={lazy}
                focalPoint={focalPoint}
            />
            <div className={`wrapper flex-row flex-align-center flex`}>
                {this.renderAccents()}
                {this.renderContent()}
            </div>
        </div>
    }

    handleResize() {
        let {image, mobileImage, scale} = this.props;

        if (!scale || !ExecutionEnvironment.canUseDOM) {
            return
        }

        const rootEl = ReactDOM.findDOMNode(this)
        const newSize = calculateResize(image.width, image.height, rootEl.offsetWidth, window.innerHeight)
        let mobileSize = newSize
        if (mobileImage) {
            mobileSize = calculateResize(mobileImage.width, mobileImage.height, rootEl.offsetWidth, window.innerHeight)
        }

        this.setState({
            height: Math.floor(newSize.height) + 'px',
            mobileHeight: Math.floor(mobileSize.height) + 'px'
        })
    }
}
