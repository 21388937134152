import PropTypes from "prop-types";
import React from "react";
import Image from "./Image";
import ContentfulImage from "./ContentfulImage";
import classnames from "classnames";
import Html from "./Html";
import Buttons from "./Buttons";
import {imagePath, documentPosition} from "../helpers";
import PatternBar from "./PatternBar";
import ColorBar from "./ColorBar";
import Heading from "./Heading";
import ReactResizeDetector from "react-resize-detector";
import ReactDOM from "react-dom";
import kebabCase from "just-kebab-case";

const HOVER = "hover";
const FOOTER = "footer";
const BODY = "body";
const HOVER_FOOTER = "hover-footer";
const BOTH = "both";
const FOOTER_BOTH = "footer-both";
const CARD = "card";
const IMAGE = "image";

/**
 * Card
 * ////
 *
 * Props
 * -----
 * buttonPlacement: (string) body|footer|hover|hover-footer|footer-both
 * buttons: (object) Arry of Button component props.
 * className: (string) The css class(es) to apply to the container.
 * children: (array) Used if there is no content.
 * color: (string) white|warm-gray. Default: white
 * content: (html) Main card content.
 * contentPlacement: (string) body|hover|both. Default: body
 * contentWidth: (string) full|half
 * footerContent: (html) Content to go in the footer.
 * footerContentPlacement: footer|footer-both
 * heading: (html) Card heading.
 * headingPlacement: (string) body|footer|footer-both. Default: body
 * href: (string) URL the card should link to
 * hrefPlacement (string) image|footer|hover-footer|footer-both|card
 * icon: (string) Qualified path to the icon. Default: ui-arrow.svg
 * iconPlacement (string) body|hover|both|footer|hover-footer|footer-both
 * imageFocus (string) center, top, right, left, bottom, top_right, top_left, bottom_right, bottom_left, face, faces
 * image: (string|object) Qualified image path or Contentful Image object.
 * imageWidth: (int) The width of the image
 * imageHeight: (int) The height of the image
 * imagePlacement: (string) body|hover. Default: body
 * pattern: (string) Contentful Image object.
 * colorBar: (string) color name
 * colorBarSize: bar|short|tall. Default: short
 * patternSize: (string) bar|short|tall. Default: short
 * title: (string) Used in image alt tags.
 * fontSize: (string)
 * hoverStyle: (string) card|popout
 * size: (string) regular|large|small
 */

export default class Card extends React.Component {
    hoverFooter;
    hover;
    body;
    state = {
        hoverBottomPosition: 0,
        hoverHeight: "0px",
    };

    constructor(props) {
        super(props);
        this.hoverFooter = React.createRef();
        this.hover = React.createRef();
        this.body = React.createRef();
    }

    /**
     * Does the card have a hover state
     * @returns {boolean}
     */
    get hasHover() {
        return this.renderHoverContent().length > 0;
    }

    /**
     * Does the card have a footer
     * @returns {boolean}
     */
    get hasFooter() {
        return this.renderFooterContent().length > 0;
    }

    /**
     * Does the hover state have a footer
     * @returns {boolean}
     */
    get hasHoverFooter() {
        return this.renderHoverFooterContent().length > 0;
    }

    /**
     * Get the pattern size
     */
    get patternSize() {
        const {size, patternSize} = this.props;

        if (patternSize) {
            return patternSize;
        }

        if (size === "regular") {
            return "short";
        }

        if (size === "large") {
            return "tall";
        }
    }

    /**
     * Get the pattern size
     */
    get colorBarSize() {
        const {size, colorBarSize} = this.props;

        if (colorBarSize) {
            return colorBarSize;
        }

        if (size === "regular") {
            return "short";
        }

        if (size === "large") {
            return "tall";
        }
    }

    componentDidMount() {
        const {hoverStyle, colorBar, patternBar} = this.props;

        if (hoverStyle === "popout") {
            setTimeout(100, this.calculateHoverFooterPosition.bind(this));
        }
        this.calculateHoverHeight();
    }

    /**
     * Main render function
     * @returns {*}
     */
    render() {
        return (
            <ReactResizeDetector
                refreshMode={"debounce"}
                refreshRate={500}
                handleHeight
                handleWidth
                onResize={this.handleResize.bind(this)}>
                {this.renderCard()}
            </ReactResizeDetector>
        );
    }

    renderCard() {
        const {
            className,
            href,
            hrefPlacement,
            title,
            color,
            size,
            hoverStyle,
            colorBar,
            pattern,
            colorBarSize,
            patternSize,
            cardRef,
        } = this.props;
        const {hoverBottomPosition, hoverHeight} = this.state;

        let attrs = {
            className: classnames(
                "card",
                "flex-column",
                className,
                `card--${kebabCase(color)}`,
                `card--${size}`,
                `card--hover-${hoverStyle}`,
                "width-100",
                href ? "card--has-link" : null,
                colorBar ? `card--bar-` + colorBarSize : null,
                pattern ? `card--bar-` + patternSize : null
            ),
            style: {
                "--hover-bottom": hoverBottomPosition,
                "--hover-height": hoverHeight,
            },
            ref: cardRef,
        };

        if (hrefPlacement === CARD && href) {
            return (
                <a href={href} title={title} {...attrs}>
                    {this.renderStructure()}
                </a>
            );
        } else {
            return <div {...attrs}>{this.renderStructure()}</div>;
        }
    }

    /**
     * Render the basic card structure.
     * @returns {(*|*[])[]}
     */
    renderStructure() {
        return [this.renderBody(), this.renderHover(), this.renderBar()];
    }

    /**
     * Render the non-hover part of the card.
     */
    renderBody() {
        const {image, imagePlacement, icon, iconPlacement} = this.props;

        let bodyContent = this.renderBodyContent();

        if (icon && iconPlacement === BODY) {
            return (
                <div
                    className={classnames(
                        "card__body display-flex flex-column flex-grow-1"
                    )}
                    key="body">
                    {image && imagePlacement === BODY ? this.renderImage() : ""}
                    <div
                        className={classnames(
                            "card__body__content flex-grow-1 flex-no-wrap"
                        )}>
                        <div className="flex-grow-1">{bodyContent}</div>
                        <div className="flex flex-grow-0 flex-align-center flex-justify-center padding-right-1">
                            {this.renderIcon()}
                        </div>
                    </div>
                    {this.renderFooter()}
                </div>
            );
        }

        return (
            <div
                className={classnames(
                    "card__body display-flex flex-column flex-grow-1"
                )}
                key="body">
                {image && imagePlacement === BODY ? this.renderImage() : ""}
                {bodyContent.length ? (
                    <div className={classnames("card__body__content flex-grow-1")}>
                        <div className={classnames("card__body__wrapper")}>
                            {bodyContent}
                        </div>
                    </div>
                ) : null}

                {this.renderFooter()}
            </div>
        );
    }

    /**
     * Render the hover part of the card.
     * @returns {[]}
     */
    renderHover() {
        if (!this.hasHover) {
            return;
        }

        return (
            <div className={classnames("card__hover")} key="hover" ref={this.hover}>
                {this.renderHoverContent()}
            </div>
        );
    }

    /**
     * Render the footer part of the card.
     * @returns {[]}
     */
    renderFooter() {
        const {href, hrefPlacement, footerContent, footerContentPlacement} =
            this.props;

        if (!this.hasFooter) {
            return;
        }

        let classNames = classnames("card__footer");

        if (href && (hrefPlacement === FOOTER || hrefPlacement === FOOTER_BOTH)) {
            return (
                <a href={href} className={classNames} key="footer">
                    {this.renderFooterStructure()}
                </a>
            );
        }

        return (
            <div className={classNames} key="footer">
                {this.renderFooterStructure()}
            </div>
        );
    }

    /**
     * Render the footer body.
     * @returns {[]}
     */
    renderFooterStructure() {
        const {icon, iconPlacement} = this.props;

        if (
            icon &&
            iconPlacement &&
            (iconPlacement === FOOTER || iconPlacement === FOOTER_BOTH)
        ) {
            return (
                <div className={"card__icon-wrapper"}>
                    <div>{this.renderFooterContent()}</div>
                    {this.renderIcon()}
                </div>
            );
        }

        return this.renderFooterContent();
    }

    /**
     * Render the hover footer part of the card.
     * @returns {[]}
     */
    renderHoverFooter() {
        const {href, hrefPlacement} = this.props;

        if (!this.hasHoverFooter) {
            return;
        }

        let classNames = classnames("card__footer");

        if (
            href &&
            (hrefPlacement === HOVER_FOOTER || hrefPlacement === FOOTER_BOTH)
        ) {
            return (
                <a
                    href={href}
                    className={classNames}
                    key="footer"
                    ref={this.hoverFooter}>
                    {this.renderHoverFooterStructure()}
                </a>
            );
        }

        return (
            <div className={classNames} key="footer" ref={this.hoverFooter}>
                {this.renderHoverFooterStructure()}
            </div>
        );
    }

    /**
     * Render the hover footer body.
     * @returns {[]}
     */
    renderHoverFooterStructure() {
        const {icon, iconPlacement} = this.props;

        if (
            icon &&
            iconPlacement &&
            (iconPlacement === HOVER_FOOTER || iconPlacement === FOOTER_BOTH)
        ) {
            return (
                <div className={"card__icon-wrapper"}>
                    <div>{this.renderHoverFooterContent()}</div>
                    {this.renderIcon()}
                </div>
            );
        }

        return this.renderHoverFooterContent();
    }

    /**
     * Check to see which content belongs in the card body and return it.
     * @returns {[]}
     */
    renderBodyContent() {
        const {contentPlacement, buttonPlacement, headingPlacement} = this.props;
        const elements = [];

        if (headingPlacement === BODY || headingPlacement === BOTH) {
            elements.push(this.renderHeading());
        }

        if (contentPlacement === BODY || contentPlacement === BOTH) {
            elements.push(this.renderContent());
        }

        if (buttonPlacement === BODY || buttonPlacement === BOTH) {
            elements.push(this.renderButtons());
        }

        return elements.filter((item) => !!item);
    }

    /**
     * Check to see which content belongs in the card hover state and return it.
     * @returns {[]}
     */
    renderHoverContent() {
        const {
            contentPlacement,
            imagePlacement,
            buttonPlacement,
            headingPlacement,
        } = this.props;
        const elements = [];

        if (imagePlacement === HOVER || imagePlacement === BOTH) {
            elements.push(this.renderImage());
        }

        if (headingPlacement === HOVER || headingPlacement === BOTH) {
            elements.push(this.renderHeading());
        }

        if (contentPlacement === HOVER || contentPlacement === BOTH) {
            elements.push(this.renderContent());
        }

        if (buttonPlacement === HOVER || buttonPlacement === BOTH) {
            elements.push(this.renderButtons());
        }

        if (this.hasHoverFooter) {
            elements.push(this.renderHoverFooter());
        }

        return elements;
    }

    /**
     * Check to see which content belongs in the card footer and return it.
     * @returns {[]}
     */
    renderFooterContent() {
        const {
            buttons,
            heading,
            buttonPlacement,
            headingPlacement,
            footerContent,
            footerContentPlacement,
        } = this.props;

        const elements = [];

        if (
            (heading && headingPlacement === FOOTER) ||
            headingPlacement === FOOTER_BOTH
        ) {
            elements.push(this.renderHeading());
        }

        if (
            (footerContent && footerContentPlacement === FOOTER) ||
            footerContentPlacement === FOOTER_BOTH
        ) {
            elements.push(
                <Html
                    content={footerContent}
                    className={"footer_content"}
                    key={"footer-content"}
                />
            );
        }

        if (
            (buttons && buttonPlacement === FOOTER) ||
            buttonPlacement === FOOTER_BOTH
        ) {
            elements.push(this.renderButtons());
        }

        return elements;
    }

    /**
     * Check to see which content belongs in the card hover state footer and return it.
     * @returns {[]}
     */
    renderHoverFooterContent() {
        const {
            heading,
            buttons,
            buttonPlacement,
            headingPlacement,
            footerContent,
            footerContentPlacement,
        } = this.props;
        const elements = [];

        if (heading && headingPlacement === FOOTER_BOTH) {
            elements.push(this.renderHeading());
        }

        if (
            (footerContent && footerContentPlacement === HOVER_FOOTER) ||
            footerContentPlacement === FOOTER_BOTH
        ) {
            elements.push(
                <Html
                    content={footerContent}
                    className={"footer_content"}
                    key={"footer-content"}
                />
            );
        }

        if (
            buttons &&
            (buttonPlacement === HOVER_FOOTER || buttonPlacement === FOOTER_BOTH)
        ) {
            elements.push(this.renderButtons());
        }

        return elements;
    }

    /**
     * Render the card image
     * @returns {*}
     */
    renderImage(withLink = true) {
        let {
            image,
            imageWidth,
            imageHeight,
            imageStyle,
            imageFocus,
            imageStretch,
            href,
            hrefPlacement,
            title,
        } = this.props;

        if (!image) {
            return;
        }

        if (withLink && hrefPlacement === "image" && href) {
            return (
                <a href={href} title={title}>
                    {this.renderImage(false)}
                </a>
            );
        }

        const imageClassNames = classnames(
            "card__image",
            imageStyle === "default" ? "card__image--default img-fluid" : null,
            imageStyle === "cover" ? "card__image--cover img-cover" : null,
            imageStyle === "full" ? "card__image--full" : null,
            imageStretch ? "card__image-stretch" : null
        );

        const wrapperClassNames = classnames(
            "card__image__wrapper",
            imageStretch ? "card__image__wrapper--stretch" : null
        );

        const style = {"--image-height": imageHeight + "px"};

        if (typeof image === "string") {
            return (
                <div className={wrapperClassNames} style={style}>
                    <Image
                        src={image}
                        className={imageClassNames}
                        width={imageStyle !== "full" ? imageWidth : null}
                        height={imageStyle !== "full" ? imageHeight : null}
                        key="image"
                        focalPoint={imageFocus}
                        lazy={true}
                    />
                </div>
            );
        }

        return (
            <div className={wrapperClassNames} style={style}>
                <ContentfulImage
                    image={image}
                    className={imageClassNames}
                    width={imageStyle !== "full" ? imageWidth : null}
                    height={imageStyle !== "full" ? imageHeight : null}
                    key="image"
                    focalPoint={imageFocus}
                    lazy={true}
                />
            </div>
        );
    }

    /**
     * Render the card heading
     * @returns {*}
     */
    renderHeading() {
        let {heading, subheader, headingLevel, headingColor} = this.props;

        if (!heading) {
            return;
        }

        if (typeof heading === "function") {
            return (
                <div className="card__heading" key="heading">
                    {heading()}
                </div>
            );
        }

        return (
            <div className="card__heading" key="heading">
                {subheader ? (
                    <div className="label text-small margin-bottom-4 display-block">
                        <Html content={subheader.replace("|", "&nbsp;|&nbsp;")}/>
                    </div>
                ) : (
                    ""
                )}
                <Heading type={headingLevel} className={`text-${headingColor}`}>
                    <Html content={heading}/>
                </Heading>
            </div>
        );
    }

    /**
     * Render the card content
     * @returns {React.ReactNode|*}
     */
    renderContent() {
        const {
            content,
            children,
            fontSize,
            mobileFontSize,
            desktopFontSize,
            contentWidth,
        } = this.props;

        let classNames = classnames(
            "card__content",
            "flex-grow-1",
            `font-size-${mobileFontSize}`,
            `font-size-lg-${fontSize}`,
            `font-size-xxl-${desktopFontSize}`,
            contentWidth === "half" ? "width-md-50 padding-right-6" : null
        );

        if (content) {
            return (
                <div className={classNames} key="content">
                    <Html content={content}/>
                </div>
            );
        }

        if (children) {
            return (
                <div className={classNames} key="content">
                    {children}
                </div>
            );
        }
    }

    /**
     * Render the card buttons
     * @returns {*}
     */
    renderButtons() {
        const {buttons} = this.props;

        if (!buttons) {
            return;
        }

        return <Buttons buttons={buttons} key="buttons"/>;
    }

    /**
     * Render the card icons.
     * @returns {*}
     */
    renderIcon() {
        const {icon, title} = this.props;

        if (!icon) {
            return;
        }

        let titleAttr = title ? `More about ${title}` : "More info";

        return (
            <img
                src={icon}
                alt={titleAttr}
                width={31}
                height={31}
                className={"card__icon"}
                key="icon"
            />
        );
    }

    /**
     * Render the pattern
     */
    renderBar() {
        const {pattern, colorBar} = this.props;

        if (pattern) {
            return this.renderPatternBar();
        }

        if (colorBar) {
            return this.renderColorBar();
        }
    }

    /**
     * Render the pattern
     */
    renderColorBar() {
        const {colorBar} = this.props;

        if (!colorBar) {
            return;
        }

        return <ColorBar color={colorBar} key={"color"} size={this.colorBarSize}/>;
    }

    /**
     * Render the pattern
     */
    renderPatternBar() {
        const {pattern} = this.props;

        if (!pattern) {
            return;
        }

        return (
            <PatternBar image={pattern} key={"pattern"} size={this.patternSize}/>
        );
    }

    /**
     * METHODS
     * -------
     */

    /**
     * Do anything that needs to happen when the card resizes.
     * Note that for performance reasons, this event is only called on cards with a "popout" hoverStyle.
     */
    handleResize() {
        this.calculateHoverFooterPosition();
        this.calculateHoverHeight();
    }

    /**
     * Set a CSS var that will be used to correctly position hovers that have a "popout" hoverStyle.
     */
    calculateHoverFooterPosition() {
        const {hoverStyle} = this.props;

        if (hoverStyle !== "popout") {
            return;
        }

        let hoverBottomPosition = 0;

        let hoverFooter = ReactDOM.findDOMNode(this.hoverFooter.current);
        if (!hoverFooter) {
            return;
        }

        let hoverCardContent =
            hoverFooter.getElementsByClassName("footer_content")[0];
        if (!hoverCardContent) {
            return;
        }
        hoverBottomPosition =
            documentPosition(hoverCardContent).top -
            documentPosition(hoverCardContent).bottom -
            9;

        this.setState({hoverBottomPosition: hoverBottomPosition + "px"});
    }

    /**
     * Set a CSS var that will handle the case where the hover content is longer than than the body content.
     */
    calculateHoverHeight() {
        const {hoverStyle} = this.props;

        if (hoverStyle === "popout") {
            return;
        }

        if (!this.hasHover) {
            return;
        }

        let hoverHeight = 0;

        if (this.hover.current) {
            const hover = ReactDOM.findDOMNode(this.hover.current);
            const hoverRect = hover.getBoundingClientRect();
            const hoverContent = hover.querySelector(".card__content");
            const hoverContentRect = hoverContent.getBoundingClientRect();

            hoverHeight =
                hoverContent.scrollHeight + (hoverContentRect.top - hoverRect.top) * 2;
        }

        this.setState({hoverHeight: hoverHeight + "px"});
    }
}

Card.defaultProps = {
    contentPlacement: BODY,
    imagePlacement: BODY,
    buttonPlacement: FOOTER,
    headingPlacement: BODY,
    hrefPlacement: CARD,
    footerContentPlacement: FOOTER,
    headingLevel: "div",
    headingColor: "default",
    icon: imagePath("ui/ui-arrow.svg"),
    color: "white",
    fontSize: "base",
    desktopFontSize: "base",
    mobileFontSize: "base",
    size: "regular",
    hoverStyle: "card",
    patternSize: "short",
    imageWidth: 384,
    imageHeight: 315,
    imageStyle: "default",
    imageStretch: true,
};

Card.propTypes = {
    contentPlacement: PropTypes.string,
    imagePlacement: PropTypes.string,
    buttonPlacement: PropTypes.string,
    headingPlacement: PropTypes.string,
    headingLevel: PropTypes.string,
    headingColor: PropTypes.string,
    hrefPlacement: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    mobileFontSize: PropTypes.string,
    patternSize: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    imageStyle: PropTypes.string,
    hoverStyle: PropTypes.string,
    footerContentPosition: PropTypes.string,
    imageStretch: PropTypes.bool,
};
