import React from "react";
import NoSSR from "./NoSSR";
import {html, ssrSafe} from "../helpers";
import classnames from "classnames";
import ExecutionEnvironment from "exenv";
import {GiveLively, htmlHasGiveLively} from "./GiveLively";

let scrollReveal = null;

if (ExecutionEnvironment.canUseDOM) {
    let ScrollReveal = require("scrollreveal").default;

    scrollReveal = ScrollReveal({
        origin: "top",
        duration: 500,
        distance: "200px",
        easing: "ease",
        viewFactor: 0.5,
    });
}

/**
 * Renders an HTML string. Checks for any tags that might cause problems with SSR.
 * If deemed unsafe, it only loads on the client side.
 *
 * Props
 * -----
 * Content: The HTML string
 * ClassName: CSS cLass(es)
 * Responsive: (default: true) should the images and videos be responsive?
 */
export default class Html extends React.Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    /**
     * Should the element use scroll reveal?
     * @returns {*}
     */
    get shouldReveal() {
        const {reveal} = this.props;

        return scrollReveal && reveal;
    }

    /**
     * Should the element be rendered on the server-side?
     * @returns {*|boolean}
     */
    get shouldPrerender() {
        let {content} = this.props;
        return ssrSafe(content) && !this.shouldReveal;
    }

    /**
     * getter for the formatted content.
     * @returns {string}
     */
    get content() {
        let {content} = this.props;

        if (!content) {
            return "";
        }

        return content;
    }

    /**
     * Start the reveal after the component loads.
     */
    componentDidMount() {
        const {reveal} = this.props;

        if (this.shouldReveal) {
            setTimeout(() => {
                scrollReveal.reveal(
                    this.contentRef.current,
                    typeof reveal === "object" ? reveal : {}
                );
            }, 100);
        }
    }

    /**
     * Get the class name and add any custom classes based on content.
     * @returns {string}
     */
    className() {
        const {className, responsive, reveal} = this.props;
        const classes = {};
        if (className) {
            classes[className] = true;
        }
        if (responsive) {
            classes["imgs-fluid"] = true;
        }
        if (reveal) {
            classes["visibility-hidden"] = true;
        }
        return classnames(classes);
    }

    preRender() {
        if (typeof this.content === "function") {
            return (
                <div className={this.className()} ref={this.contentRef}>
                    {this.content()}
                </div>
            );
        }
        if (htmlHasGiveLively(this.content)) {
            return (
                <div className={this.className()} ref={this.contentRef}>
                    <GiveLively embed={this.content}/>
                </div>
            );
        }
        return (
            <div
                className={this.className()}
                ref={this.contentRef}
                dangerouslySetInnerHTML={html(this.content)}
            />
        );
    }

    postRender() {
        return <NoSSR>{this.preRender()}</NoSSR>;
    }

    /**
     * Render the content either on the server-side or client only depending on content.
     * @returns {*}
     */
    render() {
        return this.shouldPrerender ? this.preRender() : this.postRender();
    }
}

Html.defaultProps = {
    responsive: true,
    reveal: false,
};
