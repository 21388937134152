import Card from "../Card";
import React from "react";

const NewsroomPostMeta = ({date, type}) => {
    return  <div className={"post__meta"}>
        <div className={"post-card__date"}>
            {date}
        </div>
        {
            type && type.length ? <div className={"post-card__type"}>
                {'\u00A0'} | {'\u00A0'}{type[0]}
            </div> : null
        }
    </div>
}

export default NewsroomPostMeta