import React, {useState, useEffect} from 'react'
import classNames from "classnames"
import {useLastParam, useSearch, useSubmissionParam} from "../../newsroomHooks";

const NewsroomOrderBar = () => {
    const [order, setOrder] = useLastParam('order');
    const [, setPage] = useLastParam('page');
    const {fetch} = useSearch()

    const toggles = [
        {
            label: 'Most Recent',
            value: 'desc'
        },
        {
            label: 'Oldest',
            value: 'asc'
        },
        {
            label: 'Relevance',
            value: 'rel'
        }
    ]

    const updateOrder = (value) => {
        setPage(false, true) //Suppress the event so we don't rerender twice
        setOrder(value)
        fetch()
    }

    return ( <div className={"newsroom__order-bar display-none display-md-flex"}>
        <label className={"order-bar__label"}>
            Ordered By:
        </label>
        <ul className={"order-bar__toggle"}>
            {
                toggles.map(({label, value}) =>
                    <li className={classNames("toggle__item search__control", {'toggle__item--active': order === value})}
                        key={`toggle-${value}`}>
                        <span onClick={() => updateOrder(value)}>{label}</span>
                    </li>
                )
            }
        </ul>
    </div>)
}

export default NewsroomOrderBar