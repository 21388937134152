import React from 'react';
import ParallaxWhiteAccents1 from './ParallaxWhiteAccents1'
import ParallaxWhiteAccents2 from './ParallaxWhiteAccents2'
import ParallaxWhiteAccents3 from './ParallaxWhiteAccents3'
import ParallaxColorAccents1 from './ParallaxColorAccents1'
import ParallaxStaffAccents from "./ParallaxStaffAccents";
import Parallax from "./Parallax";
import ContentfulImage from "./ContentfulImage";
import classnames from 'classnames'

export default class Accents extends React.Component {
    components = {
        'white-accents-1': ParallaxWhiteAccents1,
        'white-accents-2': ParallaxWhiteAccents2,
        'white-accents-3': ParallaxWhiteAccents3,
        'color-accents-1': ParallaxColorAccents1,
        'staff-accents': ParallaxStaffAccents
    };

    constructor(props) {
        super(props);

        this.state = {
            hasParallaxImages: props.accents.some(accent => !!accent.parallax)
        }
    }


    render() {
        const { slug, className } = this.props;
        const { hasParallaxImages } = this.state

        //Render a custom accent component if we have one.
        if (slug) {
            const AccentsComponent = this.components[slug];
            if (!AccentsComponent) return ''
            return <AccentsComponent />
        }

        //Render the accents from an object.
        if (hasParallaxImages) {
            return  <Parallax className={classnames(`accent-images parallax parallax--accents position-fit`, className)} config={
            {
                "from": "top-bottom",
                "to": "bottom-top",
                direct: true,
                "props": {
                    "--parallax-translate-y": {
                        "from": "0",
                        "to": "-10vh"
                    }
                }
            }
        }>
                {this.renderImages()}
            </Parallax>
        }

        return <div className={classnames(`accent-images position-fit`, className)}>
            {this.renderImages()}
        </div>
    }

    renderImages() {
        const { accents } = this.props;

        return <div className={"container position-relative"}>
                {
                    accents.map((accent, id) => {
                        return  <ContentfulImage
                            image={accent.image}
                            className={classnames(
                                'accent',
                                accent.parallax !== false ? 'accent--parallax' : '',
                                accent.desktop ? 'accent--desktop' : '',
                                accent.tablet ? 'accent--tablet' : '',
                                accent.mobile ? 'accent--mobile' : ''
                            )}
                            key={'accent-' + id}
                            style={{
                                "--accent-width": accent.width,
                                "--accent-height": accent.height ? accent.height : 'auto',
                                "--accent-top": accent.top ? accent.top : 'unset',
                                "--accent-right": accent.right ? accent.right : 'unset',
                                "--accent-bottom": accent.bottom ? accent.bottom : 'unset',
                                "--accent-left": accent.left ? accent.left : 'unset',
                                "--accent-speed": accent.speed ? accent.speed : 1,
                                "--accent-z": accent.z ? accent.z : 1
                            }}
                        />
                    })
                }
            </div>
    }
}
