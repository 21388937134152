import React from 'react';
import PropTypes from "prop-types";
import Video from './Video'
import PatternBar from "./PatternBar";
import classnames from 'classnames'
import Flipbook from './Flipbook'
import Children from './Children'

/**
 * A section with content and a video.
 */
export default class VideoFlipbook extends React.Component {
    render() {
        let {content, reverse, column} = this.props

        if (!content) {
            reverse = true
            column = true
        }

        return <Flipbook
            className={classnames(
                "video-flipbook",
                {'container': column},
            )}
            columnA={() => this.renderContent()}
            columnAClassName={classnames(
                'video-flipbook__content',
                {'padding-right-gutter padding-right-lg-8': !reverse && !column},
                {'padding-left-gutter padding-left-lg-8': reverse && !column},
            )}
            columnAConstrained={true}
            columnB={() => this.renderVideo()}
            columnBConstrained={true}
            columnBClassName={classnames(
                "video-flipbook__video",
            )}
            reverse={reverse}
            column={column}
        />
    }

    renderContent()
    {
        let {content} = this.props


        return <div className={classnames(
            'video-flipbook__content__wrapper'
        )}>
            <Children
                content={content}
            />
        </div>

    }

    renderVideo()
    {
        let {videoUrl, pattern} = this.props

        return <div className={"video-flipbook__video__wrapper"}>
            <Video url={videoUrl} />
            {pattern ? <PatternBar image={pattern}/> : null}
        </div>
    }
}

VideoFlipbook.defaultProps = {
    reverse: false,
    column: false,
}

VideoFlipbook.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]), //Rich text document JSON string or object
    videoPlacement: PropTypes.string, //top, right, bottom, left
    pattern: PropTypes.object //Contentful Image Object
};