import {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import ExecutionEnvironment from 'exenv'
import {useLifecycles} from 'react-use';
import {off, on} from 'react-use/lib/misc/util';

let HistoryObserver = null
if (ExecutionEnvironment.canUseDOM) {
    HistoryObserver = require("browser-history-observer").HistoryObserver
}

/**
 * Wait until after the first tick
 * @param callback
 * @returns {boolean}
 */
export const useInitialized = (callback = () => {
}) => {
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if (!isInitialized) {
            setIsInitialized(true)
            callback()
        }
    })

    return isInitialized
}

/**
 * useTimer is a custom React hook that takes a delay parameter and returns a boolean indicating whether the delay has completed.
 *
 * @param {number} delay - The duration in milliseconds for the delay. Defaults to 0 if not specified.
 * @returns {boolean} - Returns true when the delay has completed, otherwise false.
 */
export const useTimer = (delay = 0) => {
    const [completed, setCompleted] = useState(false)

    const callback = () => {
        setCompleted(true)
    }

    // Set up the timeout.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (!delay) {
            callback()
            return
        }

        setTimeout(callback, delay)
    }, [delay])

    return completed
}

/**
 * Hook that returns the current window size and previous window size when resized.
 *
 * @returns {Object} - Object containing the window size and previous window size.
 * @property {number} width - The current window width.
 * @property {number} height - The current window height.
 * @property {Object} previous - Object containing the previous window size.
 * @property {number} previous.width - The previous window width.
 * @property {number} previous.height - The previous window height.
 */
export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({width: 0, height: 0, previous: {width: 0, height: 0}});

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            previous: {
                width: windowSize.width,
                height: windowSize.height
            }
        });
    };

    useLayoutEffect(() => {
        handleSize();

        window.addEventListener("resize", handleSize);

        return () => window.removeEventListener("resize", handleSize);
    }, []);

    return windowSize;
};

/**
 * Determines whether the current execution is in a server-side rendering (SSR) environment or not.
 *
 * @returns {boolean} Returns `true` if the current execution is in a SSR environment, and `false` otherwise.
 */
export const useSsr = () => {
    // we start assuming it's SSR, updating the state in useEffect
    // will trigger a re-render
    const [isSSR, setSSR] = useState(true);

    useEffect(() => {
        // as soon as useEffect runs, we know it's not SSR
        setSSR(false);
    }, []);

    return isSSR;
};

/**
 * read and write url hash, response to url hash change
 */
export const useHash = (defaultHash) => {
    const [hash, setHash] = useState(defaultHash);

    useInitialized(function () {
        if (window.location.hash) {
            setHash(window.location.hash);
        }
    });

    const onHashChange = useCallback(() => {
        setHash(window.location.hash);
    }, []);

    useLifecycles(
        () => {
            on(window, 'hashchange', onHashChange);
        },
        () => {
            off(window, 'hashchange', onHashChange);
        }
    );

    const _setHash = useCallback(
        (newHash) => {
            if (!newHash) {
                history.pushState("", document.title, window.location.pathname
                    + window.location.search);
                setHash("");
            } else {
                window.location.hash = newHash;
            }
        },
        [hash]
    );

    return [hash, _setHash];
};