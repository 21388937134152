import React from 'react'
import classNames from "classnames"
import ErrorBoundary from "../ErrorBoundary";
import NewsroomFilterTags from "./NewsroomFilterTags";
import NewsroomSearchResults from "./NewsroomSearchResults";
import {isFiltering, useSearch} from "../../newsroomHooks";
import CardTypes from "./PostCards";

const NewsroomResults = (
    {
        results: initialResults = [],
        search: initialSearch = false,
        cardTypes= Object.keys(CardTypes)
    }
) => {
    const {search, inProgress, results} = useSearch(initialResults, initialSearch)
    const searching = isFiltering(['country', 'topic', 'type', 'q'])
    const filtering = isFiltering(['country', 'topic', 'type'])


    if (!searching) {
        return ""
    }

    return (<ErrorBoundary>
        <div className={classNames('newsroom__results')}>
            <h2 className={"search__heading"}>
                {search.nbHits} news results{filtering ? ' for' : ''}:
            </h2>
            <NewsroomFilterTags />
            <NewsroomSearchResults results={initialResults} search={initialSearch} cardTypes={cardTypes}/>
        </div>
    </ErrorBoundary>)
}

export default NewsroomResults