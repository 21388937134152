import PropTypes from 'prop-types'
import React from 'react'
import Link from './Link'
import {imagePath} from "../helpers"
import classNames from 'classnames'
import kebabCase from 'just-kebab-case'

export default class Alert extends React.Component {
    state = {
        open: true
    }

    render() {
        let {link, mobileLink, title, className} = this.props

        const {open} = this.state

        if (!open) {
            return ""
        }

        if (!className) {
            className = title ? `alert--${kebabCase(title.toLowerCase())}` : null
        }

        return <div className={"alert__wrap"}>
            {mobileLink ? <Link className={classNames('alert', "display-lg-none", className)} link={mobileLink.link}
                                blank={mobileLink.blank} icon={false}>
                <div className={"container"}>
                    <div className={"alert__content"}>
                        <div className={"alert__label flex-grow-1"}>
                            {mobileLink.label} <i className="alert__title-icon fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                {this.renderIcon()}
            </Link> : ""}
            <Link className={classNames('alert', {'display-none display-lg-block': !!mobileLink}, className)}
                  link={link.link} blank={link.blank} icon={false}>
                <div className={"container"}>
                    <div className={"alert__content"}>
                        {title ? <div className={"alert__title flex-grow-0 no-wrap"}>{title}<i
                            className="alert__title-icon fas fa-chevron-right"></i></div> : ''}
                        <div className={"alert__label flex-grow-1"}>
                            {link.label}
                        </div>
                    </div>
                </div>
                {this.renderIcon()}
            </Link>
        </div>
    }

    renderLabel(link) {

        return
    }

    renderIcon() {
        return <div className={"alert__icon"} onClick={this.close.bind(this)}>
            <img className={"alert__image"}
                 src={imagePath('ui/ui-close-white.svg')}
                 width={12}
                 alt={"close"}
            />
        </div>
    }

    close(e) {
        e.preventDefault()

        this.setState({
            open: false
        })

        return false
    }
}

Alert.defaultProps = {
    link: { // Contentful link object
        label: '',
        link: '',
        blank: false
    },
    title: '',
};

Alert.propTypes = {
    link: PropTypes.object.isRequired
};
