import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames'
import {imagePath} from '../helpers'

/**
 * A preloader component
 */
export default class Loader extends React.Component {
    render() {
        const {active} = this.props

        return <img
            src={imagePath('accents/accent-5.svg')}
            className={classnames("animation--rotate", "preloader", {"preloader--active": active}, {"display-none": !active})}
            width={"50px"}
            height={"75px"}
            alt={"Loading..."}
        />
    }
}


Loader.defaultProps = {
    active: false
};

Loader.propTypes = {
    active: PropTypes.bool
}