import React from 'react'
import Image from "./Image";
import Parallax from "./Parallax";
import {accentPath} from '../helpers'

export default class ParallaxWhiteAccents1 extends React.Component {
    css = `
            .parallax__accents__1__white .accent {
                position: absolute;
                z-index: 1;
                top: initial;
                left: initial;
                transition: transform 30ms 0s linear;
                will-change: transform;
            }
            .parallax__accents__1__white .accent-1 {
                left: 5vw;
                top: 65%;
                transform: translateY(var(--accent-1-white));
                width: 3vw;
                height: 3vw;
            }
            .parallax__accents__1__white .accent-2 {
                left: 12vw;
                transform: translateY(calc(var(--accent-1-white)*1.5));
                width: 1.75vw;
                height: 1.75vw;
            }
            .parallax__accents__1__white .accent-3 {
                left: 52vw;
                top: 60%;
                transform: translateY(var(--accent-1-white)) rotate(135deg);
                width: 4vw;
                height: 4vw;
            }
            .parallax__accents__1__white .accent-4 {
                left: 85vw;
                top: 35%;
                transform: translateY(var(--accent-1-white));
                width: 42px;
                height: 42px;
            }
            
            @media (max-width: 1300px) {
                .parallax__accents__1__white .accent-1 {
                      left: 2vw;
                }
                
                .parallax__accents__1__white .accent-2 {
                   left: 4vw;
                }
            }
            
           @media (max-width: 1550px) {
                .parallax__accents__1__white .accent-1 {
                      display: none;
                }
            }
            
            @media (max-width: 1100px) {
                .parallax__accents__1__white .accent-2 {
                      display: none;
                }
            }
            
            @media (max-width: 1080px) {
                .parallax__accents__1__white .accent-1 {
                      left: 1vw;
                }
            }
            
            @media (max-width: 658px) {
                 .parallax__accents__1__white .accent-1 {
                    width: 10vw;
                    height: 10vw;
                    display: block;
                    left: 95vw;
                    top: 45%;
                }
                
                .parallax__accents__1__white .accent-2 {
                    display: block;
                    left: 90vw;
                    top: 45%;
                    width: 5vw;
                    height: 5vw;
                }
                
                .parallax__accents__1__white .accent-3 {
                    left: -5vw;
                    width: 10vw;
                    height: 10vw;
                    top: 85%;
                }
            }
        `

    render() {
        return (
            <Parallax config={
                {
                    "from": "top-bottom",
                    "to": "bottom-top",
                    "props": {
                        "--accent-1-white": {
                            "from": "0vh",
                            "to": "-20vh",
                        }
                    }
                }
            } className={`parallax__accents__1__white position-fit`}>
                <Image
                    src={accentPath('accent-9-white')}
                    className={'accent accent-1'}
                    key={'accent-1'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-10-white')}
                    className={'accent accent-2'}
                    key={'accent-2'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-3-white')}
                    className={'accent accent-3'}
                    key={'accent-3'}
                    alt={""}
                />
                <Image
                    src={accentPath('accent-6-white')}
                    className={'accent accent-4 hide display-lg-block'}
                    key={'accent-4'}
                    alt={""}
                />
                <style type="text/css" dangerouslySetInnerHTML={{__html: this.css}} key={'style'}/>
            </Parallax>
        )
    }
}